import {
  CreateUserRequestBody,
  DetailedUserProfile,
  EditUserRequestParams,
  UsersCompaniesResponse,
  UsersProfilesResponse,
} from '@models/usersManagement';
import { getAuthHeaders, getData, postData } from '@utils/data';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import { UsersProfilesRequestParams } from 'src/types/usersManagement';
import { UsersListRequestBody } from '@models/usersManagement';
import { urls } from './config';
import { HttpError } from '@common/http';

const appJsonContentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

export const fetchUserProfile = async (userId: string): Promise<DetailedUserProfile> => {
  const url = `${urls.usersList}/${userId}`;

  return getData<DetailedUserProfile>(url);
};

export const fetchUsersProfiles = async (params: UsersProfilesRequestParams): Promise<UsersProfilesResponse> => {
  const url = `${urls.usersList}?page=${params.activePage - 1}&size=${params.numberOfPageElements}`;

  const body: UsersListRequestBody = {
    filters: {
      userName: params.search || null,
      companies: params.companies.length > 0 ? params.companies : null,
    },
  };

  return postData<UsersProfilesResponse>(url, JSON.stringify(body), appJsonContentTypeHeader);
};

export const fetchUsersCompanies = async (): Promise<UsersCompaniesResponse> =>
  getData<UsersCompaniesResponse>(urls.usersCompanies);

export const createUser = async (requestBody: CreateUserRequestBody) => {
  const headers = getAuthHeaders();

  appJsonContentTypeHeader?.forEach((value: string, key: string) => {
    headers.append(key, value);
  });

  const response = await fetch(urls.createUser, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify(requestBody),
  });

  const responseBody = await response.json();

  if (response.ok) {
    return responseBody;
  }

  throw new HttpError(response.status, responseBody.msg ?? response.statusText);
};

export const editUser = async ({ body, uuid }: EditUserRequestParams) => {
  const headers = getAuthHeaders();

  appJsonContentTypeHeader?.forEach((value: string, key: string) => {
    headers.append(key, value);
  });

  const response = await fetch(`${urls.user}/${uuid}`, {
    method: 'PUT',
    mode: 'cors',
    headers,
    body: JSON.stringify(body),
  });

  const responseBody = await response.json();

  if (response.ok) {
    return responseBody;
  }

  throw new HttpError(response.status, responseBody.msg ?? response.statusText);
};
