import { HeaderCell, HeaderRow, TableTile, ValueCell, ValuesRow } from '@components/TableTile';
import { OrderType } from '@models/orders';
import { FC } from 'react';
import { OrderDetailsSummaryContainer, summaryPreviewColProps } from './styles';
import { HorizontalContainer } from '@components/styles';
import { ContentText, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui-fork';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

interface OrderSummaryPreviewProps {
  orderType: OrderType;
  contractNo: string;
  creationDate: string;
}

export const OrderSummaryPreview: FC<OrderSummaryPreviewProps> = ({ contractNo, creationDate, orderType }) => {
  const { t } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'services']);

  const orderTypeLabel = `${t('orderDetails:orderTypeFieldLabel')}:`;

  return (
    <OrderDetailsSummaryContainer data-testid="order-summary-preview">
      <HorizontalContainer>
        <ContentText fontSize={fontSizes.s} fontWeight={fontWeights.medium} mr={spacing.xxxxs}>
          {orderTypeLabel}
        </ContentText>
        <ContentText fontSize={fontSizes.s}>{t(`orderCreation:form.orderType.${orderType}`)}</ContentText>
      </HorizontalContainer>

      <TableTile data-testid="order-summary-preview-tile">
        <HeaderRow>
          <HeaderCell text={t('wasteDetails:contractNo')} gridColumnProps={summaryPreviewColProps} />
          <HeaderCell
            text={t('orderCreation:summaryPage.creationDetails.creationDate')}
            gridColumnProps={summaryPreviewColProps}
          />
        </HeaderRow>

        <ValuesRow>
          <ValueCell text={contractNo} gridColumnProps={summaryPreviewColProps} data-testid="contract-no-value-col" />

          <ValueCell text={creationDate} gridColumnProps={summaryPreviewColProps} data-testid="creation-date-value-col" />
        </ValuesRow>
      </TableTile>
    </OrderDetailsSummaryContainer>
  );
};
