import { CONTAINER_BORDER_RADIUS, VerticalContainer } from '@components/styles';
import { breakpoints, colors, IconButtonProps, Modal, PlaceholderProps, px2rem, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const Container = styled(VerticalContainer)`
  width: 100%;
  height: 100%;
`;
export const placeholderStyles: PlaceholderProps = {
  height: '24px',
  width: '90%',
  marginVertical: '8px',
};

export const StyledModal = styled(Modal)`
  --padding-vertical: 48px;
  max-width: 744px;
  width: 100%;
  box-sizing: border-box;
  max-height: ${`calc(100% - 2 * var(--padding-vertical))`};
  padding: ${spacing.xxs} 0px 0px;
  justify-content: start;
  border-radius: ${CONTAINER_BORDER_RADIUS};

  :focus-visible {
    box-shadow: none;
    outline: none;
  }

  > div {
    width: 100%;
    align-items: start;
    text-align: unset;
    padding: 0;
    box-shadow: unset;
  }

  > div:nth-child(2) {
    height: 100%;
    overflow-y: hidden;
  }

  @media ${`(max-width: ${breakpoints.xxl}${'px'})`} {
    --padding-horizontal: 80px;
    max-width: ${`calc(100% - 2 * var(--padding-horizontal))`};
  }

  @media ${`(max-width: ${breakpoints.l}${'px'})`} {
    --padding-horizontal: 78px;
    max-width: ${`calc(100% - 2 * var(--padding-horizontal))`};
  }
`;

export const Divider = styled.div`
  border-bottom: ${px2rem(2)} solid ${colors.cloudGrey};
`;

export const DividerNonBorder = styled.div`
  height: ${spacing.xxs};
`;

export const DividerWithPaddingTop = styled(Divider)`
  padding-top: ${spacing.xs};
`;

export const closeIconButtonStyles: Omit<IconButtonProps, 'icon'> = {
  status: 'plain',
  color: colors.inkGrey,
  top: '19px',
  right: spacing.xs,
  position: 'absolute',
};

export const DataContainer = styled(VerticalContainer)`
  padding: ${spacing.xs} ${spacing.s};
  position: relative;
  box-sizing: border-box;
  overflow: auto;
  gap: ${spacing.xs};
  flex: 1;
`;

export const DRAWER_WIDTH = '600px';

export const PanelContainer = styled(VerticalContainer)`
  height: 100%;
`;
