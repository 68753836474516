import { StyledDescriptionListItem } from '@components/styles';
import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui-fork';
import { OrderDetails } from '@models/orders';
import { translateOrderStatusCode } from '@utils/orders';
import { displayedValue, hasValue, NO_DATA_BADGE } from '@utils/dataOperations';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderStatusTileProps {
  orderStatus: OrderDetails['orderStatus'];
  creatorName: OrderDetails['generalInfo']['creatorName'];
  additionalComments: OrderDetails['generalInfo']['additionalComments'];
}

export const OrderStatusTile: FC<OrderStatusTileProps> = ({ orderStatus, creatorName, additionalComments }) => {
  const { t } = useTranslation<Namespace[]>(['orderDetails', 'common']);

  return (
    <Tile header={t('orderStatus')} data-testid={getTileTestId('order-status')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem label={t('orderDetails:status')} data-testid={getListItemTestId('status')}>
          {translateOrderStatusCode(orderStatus.description) || NO_DATA_BADGE}
        </StyledDescriptionListItem>
        <StyledDescriptionListItem label={t('orderDetails:lastUpdated')} data-testid={getListItemTestId('last-updated')}>
          {displayedValue(orderStatus.lastUpdated)}
        </StyledDescriptionListItem>

        {hasValue(creatorName) && (
          <StyledDescriptionListItem label={t('common:createdBy')} data-testid={getListItemTestId('created-by')}>
            {creatorName}
          </StyledDescriptionListItem>
        )}

        {hasValue(additionalComments) && (
          <StyledDescriptionListItem label={t('common:additionalComment')} data-testid={getListItemTestId('additional-comment')}>
            {additionalComments}
          </StyledDescriptionListItem>
        )}
      </DescriptionList>
    </Tile>
  );
};
