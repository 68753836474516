import { useUsersProfile } from '@data/hooks/useUserProfile';
import { Button, IconButton, IconCross, IconEdit, IconFile, IconInspection } from '@fortum/elemental-ui-fork';
import { FC, useCallback } from 'react';
import { ButtonsContainer, StyledErrorView, closeButtonStyles } from './styles';
import { MainSection } from './MainSection';
import { BusinessPartnersSection } from './BusinessPartnersSection';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { isUserInternal } from '@utils/user';
import { SideDrawer, PermissionsSection } from '@components/index';
import { UserProfile } from '@models/usersManagement';
import { Divider } from '@components/styles';
import { isUserCreationToggleOn } from '@utils/featureToggles';
import { useNavigate } from 'react-router-dom';
import { USER_CREATION_ROUTE_PATH } from '@config/routes';
import { closeIconButtonStyles, Container, DataContainer, DRAWER_WIDTH } from '../styles';
import { ngColors } from '@utils/styleOverride';

interface UserProfilePanelProps {
  selectedUser: UserProfile | null;
  open: boolean;
  onClose: () => void;
}

export const UserProfilePanel: FC<UserProfilePanelProps> = ({ selectedUser, open, onClose }) => {
  const navigate = useNavigate();
  const { data: user, isLoading, isError } = useUsersProfile(selectedUser?.uuid);
  const { t } = useTranslation<Namespace[]>(['common', 'usersManagement']);

  const navigateToEditPage = useCallback(
    () => selectedUser?.uuid && navigate(USER_CREATION_ROUTE_PATH, { state: { userId: selectedUser.uuid } }),
    [selectedUser?.uuid],
  );

  if (!selectedUser) return null;

  return (
    <SideDrawer open={open} onClickOutside={onClose} width={DRAWER_WIDTH}>
      <Container>
        <DataContainer>
          <IconButton {...closeIconButtonStyles} icon={<IconCross size={24} />} onClick={onClose} />

          <MainSection user={user} prefetchedUser={selectedUser} isLoading={isLoading} isError={isError} />

          <Divider />

          {isError ? (
            <StyledErrorView />
          ) : (
            <>
              <PermissionsSection
                data-testid="default-permissions-section"
                headerIcon={<IconInspection />}
                headerText={t('usersManagement:detailsPanel.defaultPermissions.header')}
                permissions={user?.defaultPermissions ?? []}
                disabledPermissions={[]}
                isLoading={isLoading}
              />

              <Divider />

              {isUserInternal(selectedUser?.userRole) ? (
                <PermissionsSection
                  data-testid="additional-permissions-section"
                  headerIcon={<IconFile />}
                  headerText={`${t('usersManagement:detailsPanel.addFeaturesAccess.header')}`}
                  permissions={user?.additionalPermissions ?? []}
                  disabledPermissions={user?.disabledAdditionalPermissions ?? []}
                  isLoading={isLoading}
                />
              ) : (
                <BusinessPartnersSection user={user} isLoading={isLoading} />
              )}
            </>
          )}
        </DataContainer>

        <ButtonsContainer>
          {isUserCreationToggleOn() && (
            <Button leftIcon={<IconEdit />} status="primary" textColor={ngColors.white} onClick={navigateToEditPage}>
              {t('usersManagement:detailsPanel.buttons.editProfile')}
            </Button>
          )}
          <Button {...closeButtonStyles} onClick={onClose}>
            {t('common:close')}
          </Button>
        </ButtonsContainer>
      </Container>
    </SideDrawer>
  );
};
