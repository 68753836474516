import {
  ButtonProps,
  ComponentOrElement,
  ContentText,
  IconProps,
  Placeholder,
  renderComponentOrElement,
} from '@fortum/elemental-ui-fork';
import { FC } from 'react';
import {
  SectionHeaderContainer,
  sectionIconStyles,
  smallHeaderTextStyles,
  mediumHeaderTextStyles,
  smallHeaderPlaceholderStyles,
  mediumHeaderPlaceholderStyles,
  buttonStyles,
} from './styles';
import { Stylable, Testable } from '@components/types';

interface SectionHeaderProps extends Stylable, Testable {
  size: 'm' | 's';
  text: string;
  icon: ComponentOrElement<IconProps>;
  displayPlaceholder?: boolean;
  rightButton?: ComponentOrElement<ButtonProps>;
}

export const SectionHeader: FC<SectionHeaderProps> = ({
  size,
  text,
  icon,
  className,
  displayPlaceholder = false,
  'data-testid': dataTestId,
  rightButton,
}) => (
  <SectionHeaderContainer data-testid={dataTestId} className={className}>
    {renderComponentOrElement(icon, sectionIconStyles)}
    {displayPlaceholder ? (
      <Placeholder {...(size === 's' ? smallHeaderPlaceholderStyles : mediumHeaderPlaceholderStyles)} />
    ) : (
      <ContentText {...(size === 's' ? smallHeaderTextStyles : mediumHeaderTextStyles)}>{text}</ContentText>
    )}
    {rightButton && renderComponentOrElement(rightButton, buttonStyles)}
  </SectionHeaderContainer>
);
