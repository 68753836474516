import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('VITE_API_URL');
const baseUrl = `${reactAppApiUrl}/rwportal-analytics/v3`;

export const AnalyticsService = {
  hazardousInWasteServicesSummaryForAdmin: `${baseUrl}/charts/hazardous-waste`,
  hazardousInWasteServicesSummary: `${baseUrl}/charts/hazardous-waste/own`,
  ongoingOrdersSummaryForAdmin: `${baseUrl}/charts/ongoing-orders`,
  ongoingOrdersSummary: `${baseUrl}/charts/ongoing-orders/own`,
  locationsWithCoordinates: `${baseUrl}/coordinates/locations`,
  locationsWithCoordinatesOwn: `${baseUrl}/coordinates/locations/own`,
};
