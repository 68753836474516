import { FC, ReactNode, useEffect, useState } from 'react';
import { BusinessPartner } from '@models/user';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { getSelectedBusinessPartners } from '@utils/user';
import { sessionKeys } from '@common/storage';
import { BusinessPartnersContext } from './BusinessPartnersContext';

interface BusinessPartnersContextProviderProps {
  children: ReactNode;
}

export const BusinessPartnersContextProvider: FC<BusinessPartnersContextProviderProps> = ({ children }) => {
  const { data: userInfo } = useUserInfo();

  const [businessPartners, setBusinessPartners] = useState<BusinessPartner[]>([]);
  const [selectedBps, setSelectedBps] = useState<BusinessPartner[]>(getSelectedBusinessPartners);

  useEffect(() => {
    const availableBusinessPartners =
      userInfo &&
      userInfo.businessPartners.map(bp => ({
        id: bp.id.trimStart(),
        name: bp.name.trimStart(),
      }));

    setBusinessPartners(availableBusinessPartners ?? []);
  }, [userInfo]);

  useEffect(() => {
    sessionStorage.setItem(sessionKeys.selectedBusinessPartners, JSON.stringify(selectedBps));
  }, [selectedBps]);

  return (
    <BusinessPartnersContext.Provider value={{ userBps: businessPartners, selectedBps, setSelectedBps }}>
      {children}
    </BusinessPartnersContext.Provider>
  );
};
