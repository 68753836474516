import { useUsersCompanies } from '@data/hooks/useUsersCompanies';
import { useResizeObserver } from '@hooks/useResizeObserver';
import { UserProfile } from '@models/usersManagement';
import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { PageHeader } from './components/PageHeader/PageHeader';
import { Container } from './components/styles';
import { UserProfilePanel } from './components/UserProfilePanel/UserProfilePanel';
import { UsersProfilesTableWithPagination } from './components/UsersProfilesTableWithPagination/UsersProfilesTableWithPagination';
import { COMPANY_FILTER_SELECTIONS_LIMIT } from './config';
import { Notification } from '@components/Notification';
import { CreationErrorNotificationMessage } from './components/CreationErrorNotificationMessage';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { useNavigate } from 'react-router-dom';
import { paths, USER_CREATION_ROUTE_PATH } from '@config/routes';
import { NotificationContext } from '@contexts/notification';
import { PageNavigationHeaderProps } from '@components/PageNavigationHeader';
import { SelectCompanyItem, SelectCompanyPanel } from './components/SelectCompanyPanel/SelectCompanyPanel';

export const UsersManagement: FC = () => {
  const { t } = useTranslation<Namespace[]>(['usersManagement', 'errors']);
  const navigate = useNavigate();
  const pageHeaderContainerRef = useRef<HTMLDivElement>(null);
  const { displayNotification, closeNotification, notificationMessage } = useContext(NotificationContext);

  const [selectedCompanies, setSelectedCompanies] = useState<SelectCompanyItem[]>([]);

  const [isCompanyPanelOpen, setIsCompanyPanelOpen] = useState<boolean>(false);

  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const { data: filtersResponse } = useUsersCompanies();

  const selectCompanyItems = useMemo(() => {
    return (
      filtersResponse?.data.map(company => ({
        name: company.name,
        value: company.code,
      })) || []
    );
  }, [filtersResponse]);

  const { containerHeight: pageHeaderHeight } = useResizeObserver(pageHeaderContainerRef);

  const onTableRawClick = useCallback((user: UserProfile) => setSelectedUser(user), []);
  const resetUser = useCallback(() => setSelectedUser(null), []);

  const setLimitedSelectedCompanies = useCallback((companies: SelectCompanyItem[]) => {
    if (companies.length <= COMPANY_FILTER_SELECTIONS_LIMIT) {
      setSelectedCompanies(companies);
    }
  }, []);

  const notificationLinkProps: PageNavigationHeaderProps['link'] = {
    id: 'navigate-to-contact-us-link',
    description: t('errors:userCreation.requestFailed.notification.message.link'),
    onClick: () => {
      navigate(paths.contactUs);
    },
  };

  const selectedCompaniesForPagination = useMemo(() => selectedCompanies.map(selected => selected.name), [selectedCompanies]);

  return (
    <Container>
      <PageHeader
        ref={pageHeaderContainerRef}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={setLimitedSelectedCompanies}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        onAddNewUserButtonClick={() => navigate(USER_CREATION_ROUTE_PATH)}
        setOpenCompaniesPanel={setIsCompanyPanelOpen}
      />
      <UsersProfilesTableWithPagination
        search={searchQuery}
        companies={selectedCompaniesForPagination}
        pageHeaderHeight={pageHeaderHeight}
        onRowClick={onTableRawClick}
        selectedUserId={selectedUser?.uuid}
      />

      <UserProfilePanel selectedUser={selectedUser} open={!!selectedUser} onClose={resetUser} />
      <SelectCompanyPanel
        initSelectedCompanies={selectedCompanies}
        open={isCompanyPanelOpen}
        multiselect={true}
        onClose={() => setIsCompanyPanelOpen(false)}
        companies={selectCompanyItems}
        onSave={(selected: SelectCompanyItem[]) => {
          setIsCompanyPanelOpen(false);
          setSelectedCompanies(selected);
        }}
        selectionLimit={COMPANY_FILTER_SELECTIONS_LIMIT}
      />

      <Notification
        type="error"
        content={<CreationErrorNotificationMessage linkProps={notificationLinkProps} message={notificationMessage ?? ''} />}
        opened={displayNotification === 'error'}
        onClose={closeNotification}
      />

      <Notification
        type="success"
        data-testid="users-management-success-notification"
        content={notificationMessage ?? ''}
        opened={displayNotification === 'success'}
        onClose={closeNotification}
      />
    </Container>
  );
};
