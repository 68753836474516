import { ContractsFiltersContext } from '@contexts/ContractsFiltersContext';
import { useContractsFiltersHandler } from '@hooks/useContractsFiltersHandler';
import { PropsWithChildren } from 'react';

type ContractsFiltersContextProviderProps = PropsWithChildren & {
  additionalFilteringForOrdersCreation?: boolean;
};

export const ContractsFilterStateProvider = ({
  additionalFilteringForOrdersCreation = false,
  children,
}: ContractsFiltersContextProviderProps) => {
  const filtersHandler = useContractsFiltersHandler(additionalFilteringForOrdersCreation);

  return <ContractsFiltersContext.Provider value={filtersHandler}>{children}</ContractsFiltersContext.Provider>;
};
