import { FC, memo, useContext, useMemo } from 'react';
import { VerticalContainerWithSmallGap } from '../styles';
import { UserDetailsFormContext } from '../UserDetailsFormContext';
import { getUserPermissionsPerUserRole } from '@utils/user';
import { UserRole } from '@models/user';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { PermissionsSection } from '@components/index';
import { Button, IconAdd, IconInspection } from '@fortum/elemental-ui-fork';
import { BusinessPartnerFormWithMarginBottom, buttonStyles } from './styles';

/**
 * At this moment only Customer Basic role is supported for the external user
 * There is a plan to cover also the Customer Admin role in the future
 */
const EXTERNAL_USER_ROLE_NAME: UserRole = 'CUSTOMER_BASIC';

interface ExternalUserPermissionsFormProps {
  disabled?: boolean;
}

export const ExternalUserPermissionsForm: FC<ExternalUserPermissionsFormProps> = memo(({ disabled = false }) => {
  const { t } = useTranslation<Namespace>('usersManagement');
  const { usersPermissionsQueryResult, permissionsPerCompaniesHandler } = useContext(UserDetailsFormContext);
  const { permissionsInCompanyHandler } = permissionsPerCompaniesHandler;

  const { defaultPermissions } = useMemo(
    () => getUserPermissionsPerUserRole(EXTERNAL_USER_ROLE_NAME, usersPermissionsQueryResult.data),
    [usersPermissionsQueryResult.data],
  );

  const discardingEnabled = useMemo(
    () => permissionsInCompanyHandler.permissionsInCompanies.length > 1 && !disabled,
    [permissionsInCompanyHandler.permissionsInCompanies.length, disabled],
  );

  return (
    <VerticalContainerWithSmallGap data-testid="external-user-creation">
      <PermissionsSection
        headerIcon={<IconInspection />}
        headerText={t('detailsPanel.defaultPermissions.header')}
        permissions={defaultPermissions}
        disabledPermissions={[]}
        isLoading={usersPermissionsQueryResult.isLoading}
      />

      {permissionsInCompanyHandler.permissionsInCompanies.map((permissionsInCompany, index) => (
        <BusinessPartnerFormWithMarginBottom
          key={permissionsInCompany.formId}
          tileIndex={index}
          permissionsInCompany={permissionsInCompany}
          discardingEnabled={discardingEnabled}
          disabled={disabled}
        />
      ))}

      <Button {...buttonStyles} leftIcon={<IconAdd />} onClick={permissionsInCompanyHandler.addNewItem} disabled={disabled}>
        {t('creationForm.addNextCompany')}
      </Button>
    </VerticalContainerWithSmallGap>
  );
});

ExternalUserPermissionsForm.displayName = 'ExternalUserPermissionsForm';
