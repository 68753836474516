import styled from 'styled-components';
import { colors, spacing } from '@fortum/elemental-ui-fork';
import { borderTypeToColorMap } from '@components/styles';

export const SelectionsWrapper = styled.div<{
  $maxHeight?: string;
  $minHeight?: string;
  $margin: string | null;
  $emptyState: boolean;
}>`
  --selection-item-height: 35px;
  --row-gap: ${spacing.xxxxs};
  --column-gap: ${spacing.xxxxs};
  --padding: ${`calc(3 * var(--row-gap))`};

  --height: ${`calc(2 * var(--selection-item-height) + 3 * var( --row-gap) + var(--padding))`};
  flex: 1;
  max-height: ${props => props.$maxHeight || 'var(--height)'};
  min-height: ${props => props.$minHeight || 'var(--height)'};

  padding: var(--padding);
  display: flex;
  flex-wrap: wrap;
  overflow: auto;

  align-items: ${props => (props.$emptyState ? 'center' : 'flex-start')};
  justify-content: ${props => (props.$emptyState ? 'center' : 'flex-start')};

  row-gap: var(--row-gap);
  column-gap: var(--column-gap);

  border: thin solid ${borderTypeToColorMap['inactive']};
  border-radius: ${spacing.xxxs};
  background-color: ${colors.lightGrey};
`;

export const SelectionItem = styled.span`
  text-align: center;
  padding: ${spacing.xxxs};
  align-items: center;
  display: flex;
  flex-wrap: no-wrap;
  white-space: nowrap;
  border: thin solid ${colors.fogGrey};
  border-radius: ${spacing.xxxs};
  background-color: ${colors.snowWhite};

  &:hover {
    border-color: ${borderTypeToColorMap['active']};
  }
`;
