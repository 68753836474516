import { logException } from '@config/azureInsights';

export const getEnvProperty = (key: string): string => {
  const value = import.meta.env[key];
  if (!value) {
    const errorMessage =
      value === undefined ? `Missing environment variable: ${key}` : `Empty value for environment variable: ${key}`;

    logException(new Error(errorMessage));
    console.error(errorMessage);
  }

  return value ?? '';
};

export const scrollIntoView = (element?: Element | null) => element?.scrollIntoView({ block: 'end', behavior: 'smooth' });

export const scrollToTop = (element?: Element | null) => element?.scrollTo(0, 0);
