import styled from 'styled-components';
import { colors, spacing } from '@fortum/elemental-ui-fork';
import { Multiselect } from '@components/Multiselect';
import { HorizontalContainer, staticBorderStyles } from '@components/styles';

export const StyledSelect: typeof Multiselect = styled(Multiselect)`
  div > span {
    color: ${colors.sonicGrey};
  }
  button {
    ${staticBorderStyles('2px')};
  }
`;

export const Container = styled(HorizontalContainer)`
  gap: ${spacing.xxs};
`;
