import styled, { css } from 'styled-components';
import { colors, fontWeights } from '@fortum/elemental-ui-fork';

export const MenuButton = styled.button<{ $checked: boolean }>`
  user-select: none;
  cursor: pointer;
  border: none;
  text-align: start;
  word-break: break-word;
  padding: 0 32px;
  font-size: 1rem;
  line-height: 21px;
  font-family: Inter, sans-serif;
  min-height: 21px;
  font-style: normal;
  background-color: ${colors.snowWhite};
  font-weight: ${fontWeights.regular};
  color: ${colors.mineShaftGrey};
  ${props =>
    props.$checked &&
    css`
      color: ${colors.mineShaftGrey};
      font-weight: ${fontWeights.regular};
    `}

  &:hover {
    cursor: pointer;
  }
`;
