import { Namespace } from '@config/i18n';
import { Button, ChipItem, IconAddUser, IconSearch, InputField } from '@fortum/elemental-ui-fork';
import { SEARCH_CHIP_VALUE } from '@utils/dataOperations';
import { createChipsItems } from '@utils/filtersAccordion';
import { FC, forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionComponentsContainer,
  Container,
  FiltersContainer,
  inputFieldStyles,
  StyledCommonHeader,
  StyledFiltersAccordion,
} from './styles';
import { isUserCreationToggleOn } from '@utils/featureToggles';
import { useDebounceWithTemporaryValue } from '@hooks/useDebounceWithTemporaryValue';
import { TRIGGER_REQUEST_SEARCH_LENGTH_THRESHOLD } from '@common/consts';
import { SelectCompanyItem } from '../SelectCompanyPanel/SelectCompanyPanel';
import { ngColors } from '@utils/styleOverride';

interface PageHeaderProps {
  selectedCompanies: SelectCompanyItem[];
  searchQuery: string;
  onSearch: (value: string) => void;
  setSelectedCompanies: (companies: SelectCompanyItem[]) => void;
  onAddNewUserButtonClick: () => void;
  setOpenCompaniesPanel: (isOpen: boolean) => void;
}

type ActionComponentsProps = Pick<
  PageHeaderProps,
  'onSearch' | 'onAddNewUserButtonClick' | 'setOpenCompaniesPanel' | 'searchQuery'
>;
const ActionComponents: FC<ActionComponentsProps> = ({
  onSearch,
  onAddNewUserButtonClick,
  setOpenCompaniesPanel,
  searchQuery,
}) => {
  const { t } = useTranslation<Namespace>('usersManagement');
  const handleSearchValueChange = (searchText: string) => {
    if (!searchText || searchText.trim().length > TRIGGER_REQUEST_SEARCH_LENGTH_THRESHOLD) onSearch(searchText.trim());
  };

  const { changeTemporaryValue: setTempSearchQuery, temporaryValue: tempSearchQuery } = useDebounceWithTemporaryValue(
    searchQuery,
    handleSearchValueChange,
  );

  return (
    <ActionComponentsContainer>
      <FiltersContainer>
        <Button size="s" status="primary" textColor={ngColors.white} onClick={() => setOpenCompaniesPanel(true)}>
          {t('usersManagement:selectCompany')}
        </Button>
        <InputField
          name="search-input-field"
          label={t('usersManagement:filters.search.label')}
          placeholder={t('common:search')}
          value={tempSearchQuery}
          onChange={setTempSearchQuery}
          leftIcon={<IconSearch />}
          {...inputFieldStyles}
        />
      </FiltersContainer>
      {isUserCreationToggleOn() && (
        <Button
          size="s"
          status="primary"
          rightIcon={<IconAddUser />}
          textColor={ngColors.white}
          onClick={onAddNewUserButtonClick}
        >
          {t('addNewUser')}
        </Button>
      )}
    </ActionComponentsContainer>
  );
};

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(
  ({ onSearch, setSelectedCompanies, selectedCompanies, searchQuery, onAddNewUserButtonClick, setOpenCompaniesPanel }, ref) => {
    const { t } = useTranslation<Namespace>('usersManagement');

    const chipsItems = useMemo<ChipItem<string>[]>(
      () =>
        createChipsItems(
          selectedCompanies.map(selected => selected.name),
          searchQuery,
        ),
      [selectedCompanies, searchQuery],
    );

    const resetAllFilters = useCallback(() => {
      setSelectedCompanies([]);
      onSearch('');
    }, [setSelectedCompanies, onSearch]);

    const removeChipItem = useCallback(
      ({ value }: ChipItem<string>) => {
        if (value === SEARCH_CHIP_VALUE) {
          onSearch('');
          return;
        }

        setSelectedCompanies(selectedCompanies.filter(selectedCompany => selectedCompany.name !== value));
      },
      [onSearch, setSelectedCompanies, selectedCompanies],
    );

    return (
      <Container ref={ref}>
        <StyledCommonHeader
          header={t('appMenuItem.label')}
          componentRight={
            <ActionComponents
              onSearch={onSearch}
              onAddNewUserButtonClick={onAddNewUserButtonClick}
              searchQuery={searchQuery}
              setOpenCompaniesPanel={setOpenCompaniesPanel}
            />
          }
        />

        {!!chipsItems.length && (
          <StyledFiltersAccordion chipsItems={chipsItems} clearAllFilters={resetAllFilters} removeFilter={removeChipItem} />
        )}
      </Container>
    );
  },
);

PageHeader.displayName = 'PageHeader';
