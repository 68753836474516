import { Auth0Provider } from '@auth0/auth0-react';
import { envPropKeys } from '@common/environmentProps';
import { SuspenseLoader } from '@components/layout/styles';
import { reactPlugin } from '@config/azureInsights';
import { queryClient } from '@config/queryClient';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { GeneralError } from '@routes/errors/general/general-error';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getEnvProperty } from '@utils/general';
import { FC, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { BusinessPartnersContextProvider } from '@routes/customer/BusinessPartnersContextProvider';

import { router } from './router';
import GlobalStyle from './style';
import { CookieConsentModal } from '@components/CookieConsentModal/CookieConsentModal';

import { createTheme, defaultTheme, ThemeProvider } from '@fortum/elemental-ui-fork';
import { styleOverride } from '@utils/styleOverride';

const baseTheme = defaultTheme;
const ngTheme = createTheme(styleOverride, baseTheme);

export const App: FC = () => {
  return (
    <Auth0Provider
      domain={getEnvProperty(envPropKeys.reactAppAuthDomain)}
      clientId={getEnvProperty(envPropKeys.reactAppAuthClientId)}
      authorizationParams={{ redirect_uri: getEnvProperty(envPropKeys.reactAppAuthRedirectUri) }}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<SuspenseLoader color={styleOverride.colors.primary} />}>
          <AppInsightsErrorBoundary onError={GeneralError} appInsights={reactPlugin}>
            <ThemeProvider theme={ngTheme}>
              <GlobalStyle />
              <CookieConsentModal />
              <BusinessPartnersContextProvider>
                <RouterProvider fallbackElement={<SuspenseLoader />} router={router} />
              </BusinessPartnersContextProvider>
            </ThemeProvider>
          </AppInsightsErrorBoundary>

          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
        </Suspense>
      </QueryClientProvider>
    </Auth0Provider>
  );
};

export default App;
