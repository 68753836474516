import { QueryOptions } from 'src/types/reactQuery';
import { QueryKeys } from '@common/query';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { UsersPermissions } from '@models/user';
import { fetchUsersPermissions } from '@data/api/user';

const usersPermissionsQuery = (): QueryOptions<UsersPermissions> => ({
  queryKey: [QueryKeys.usersPermissions],
  queryFn: () => fetchUsersPermissions(),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useUsersPermissions = () => useQuery<UsersPermissions, Error>(usersPermissionsQuery());
