import { Namespace } from '@config/i18n';
import { Button, IconAddFileAlt, spacing, useToggle } from '@fortum/elemental-ui-fork';
import { forwardRef, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ButtonsContainer, ServiceTilesContainer } from './styles';
import { MissingFieldsModal } from './MissingFieldsModal';
import { ORDERS_CREATION_SUMMARY_ROUTE_PATH } from '@config/routes';
import { FillAvailableContainer, OrdersCreationContext } from '@routes/ordersCreation/components';
import { ServiceTile, ServiceTileProps } from '../ServiceTile';
import { ConfirmationModal } from '@components/index';
import { ngColors } from '@utils/styleOverride';

type ServiceTilesProps = Pick<ServiceTileProps, 'onChooseOtherServiceClick'>;

export const ServicesTiles = forwardRef<HTMLDivElement, ServiceTilesProps>(({ onChooseOtherServiceClick }, ref) => {
  const navigate = useNavigate();

  const { t } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'common']);

  const { creationForms, countMissingRequiredFields, clearForms } = useContext(OrdersCreationContext);

  const [missingFieldsModalOpen, toggleMissingFieldsModalOpen] = useToggle(false);
  const [discardOrderModalOpen, toggleDiscardOrderModalOpen] = useToggle(false);

  const [missingRequiredFieldsCount, setMissingRequiredFieldsCount] = useState(0);

  const submit = useCallback(() => {
    const missingRequiredFields = countMissingRequiredFields();

    setMissingRequiredFieldsCount(missingRequiredFields);

    if (missingRequiredFields > 0) {
      toggleMissingFieldsModalOpen();
      return;
    }

    navigate(ORDERS_CREATION_SUMMARY_ROUTE_PATH);
  }, [countMissingRequiredFields]);

  const atLeastOneOrderFormExists = creationForms.some(creationForm => creationForm.forms.length > 0);

  return (
    <>
      <FillAvailableContainer>
        <ServiceTilesContainer ref={ref}>
          {creationForms.map((creationForm, index) => (
            <ServiceTile
              key={creationForm.contractIdentifier}
              serviceIndex={index}
              ordersCreationForm={creationForm}
              onChooseOtherServiceClick={onChooseOtherServiceClick}
            />
          ))}
        </ServiceTilesContainer>

        {atLeastOneOrderFormExists && (
          <>
            <Button
              status="secondary"
              backgroundColor={ngColors.white}
              textColor={ngColors.white}
              variant="condensed"
              leftIcon={<IconAddFileAlt />}
              onClick={onChooseOtherServiceClick}
              alignSelf="start"
              mt={spacing.xs}
            >
              {t('orderCreation:addNewContract')}
            </Button>

            <ButtonsContainer>
              <Button
                status="plain"
                textColor={ngColors.orange}
                onClick={toggleDiscardOrderModalOpen}
                data-testid="discard-orders-button"
              >
                {t('common:discard')}
              </Button>

              <Button status="primary" textColor={ngColors.white} onClick={submit} data-testid="submit-order-button">
                {t('orderCreation:submitOrder')}
              </Button>
            </ButtonsContainer>
          </>
        )}
      </FillAvailableContainer>

      <MissingFieldsModal
        opened={missingFieldsModalOpen}
        onClose={toggleMissingFieldsModalOpen}
        missingRequiredFieldsCount={missingRequiredFieldsCount}
      />

      <ConfirmationModal
        data-testid="discard-order-modal"
        headerText={t('common:discard')}
        messageText={t('orderCreation:summaryPage.discardModal.message')}
        closingButtonText={t('common:refuseDelete')}
        confirmingButtonText={t('common:confirmDelete')}
        opened={discardOrderModalOpen}
        onClose={toggleDiscardOrderModalOpen}
        onConfirmClick={clearForms}
      />
    </>
  );
});

ServicesTiles.displayName = 'ServicesTiles';
