export const TOTAL_PAGES_ON_DATA_LOADING = 0;
export const INITIAL_PAGE_NUMBER = 1;

export const NOTIFICATION_AUTO_CLOSE_DELAY_MS = 5000;

export const DEFAULT_DEBOUNCE_TIME_MS = 400;
export const EXTENDED_DEBOUNCE_TIME_MS = 1000;

export const TRIGGER_REQUEST_SEARCH_LENGTH_THRESHOLD = 2;

export const KEY_ENTER = 'Enter';
