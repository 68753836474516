import { FC } from 'react';
import { ContentText, Heading, fontWeights, Box, colors, fontSizes, lineHeights, spacing } from '@fortum/elemental-ui-fork';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { MainContainer } from './styles';

export const Unauthorized: FC = () => {
  const { t } = useTranslation<Namespace>('errors');

  return (
    <Box display="flex" justifyContent="center" backgroundColor={colors.snowWhite} height={'100%'} overflow="auto">
      <MainContainer>
        <Heading level={3} textAlign="center" maxWidth={'644px'}>
          {t('unauthorized.header')}
        </Heading>
        <Box display="flex" flexDirection="column">
          <Heading level={5} fontWeight={fontWeights.bold} lineHeight={1} textAlign="center" paddingBottom={spacing.xxs}>
            {t('unauthorized.errorCode')}
          </Heading>
          <ContentText fontSize={fontSizes.l} fontWeight={fontWeights.regular} lineHeight={lineHeights.normal} textAlign="center">
            {t('unauthorized.message')}
          </ContentText>
        </Box>
      </MainContainer>
    </Box>
  );
};
