import { FC, useContext } from 'react';
import { gridStyles, columnStyles, VerticalContainerWithBigGap, wasteColumnStyles } from './styles';
import { Grid, Row, Col } from '@fortum/elemental-ui-fork';
import {
  EquipmentDetails,
  TransportDetailsForm,
  WasteDetailsForm,
  InvoicingDetailsForm,
} from '@routes/ordersCreation/components';
import { Testable } from '@components/types';
import { SingleOrderCreationContext } from '../SingleOrderCreationContext';

export const DetailsFormWithWastes: FC<Testable> = ({ 'data-testid': dataTestId }) => {
  const {
    form,
    contract,
    setSpecifiedWasteDescription,
    contactsDataHandler,
    setContactPerson,
    setLocationPointAdditionalInfo,
    setOrderLevelReference,
    orderTileRef,
  } = useContext(SingleOrderCreationContext);

  const { invoicingDetails } = contract.contractDetails;

  return (
    <Grid {...gridStyles} data-testid={dataTestId}>
      <Row>
        <Col {...wasteColumnStyles}>
          <WasteDetailsForm
            form={form}
            contract={contract}
            setSpecifiedWasteDescription={setSpecifiedWasteDescription}
            orderTileRef={orderTileRef}
          />
        </Col>

        <Col {...columnStyles}>
          <VerticalContainerWithBigGap>
            <EquipmentDetails equipmentDetails={contract.serviceDetails.equipmentDetails} />

            <TransportDetailsForm
              form={form}
              contract={contract}
              contactsDataHandler={contactsDataHandler}
              setContactPerson={setContactPerson}
              setLocationPointAdditionalInfo={setLocationPointAdditionalInfo}
              orderTileRef={orderTileRef}
            />

            <InvoicingDetailsForm
              form={form}
              invoicingDetails={invoicingDetails}
              setOrderLevelReference={setOrderLevelReference}
              orderTileRef={orderTileRef}
            />
          </VerticalContainerWithBigGap>
        </Col>
      </Row>
    </Grid>
  );
};
