import { translateIfExist } from '@utils/internationalization';
import { Tile } from '@components/Tile';
import { StyledDescriptionListItem } from '@components/styles';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui-fork';
import { OrderDetails } from '@models/orders';
import { displayedValue } from '@utils/dataOperations';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getEquipmentQuantityEntryDetails } from './utils';

interface EquipmentDetailsTileProps {
  equipmentDetails: OrderDetails['equipmentDetails'];
}

export const EquipmentDetailsTile: FC<EquipmentDetailsTileProps> = ({ equipmentDetails }) => {
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'orderDetails']);

  const { quantityEntryTestId, quantityEntryLabel, quantityValue } = getEquipmentQuantityEntryDetails(
    equipmentDetails.estimation,
    equipmentDetails.quantity,
  );

  const translatedEqServiceType = translateIfExist(equipmentDetails.serviceType.toUpperCase(), 'orderDetails', 'eqServiceType');

  const translatedEqOwnership = translateIfExist(equipmentDetails.ownership, 'domain', 'equipmentGroup');

  return (
    <Tile header={t('wasteDetails:equipmentDetails')} data-testid={getTileTestId('equipment-details')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem label={t('wasteDetails:equipmentType')} data-testid={getListItemTestId('equipment-type')}>
          {displayedValue(equipmentDetails.equipmentType)}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem label={quantityEntryLabel} data-testid={quantityEntryTestId}>
          {quantityValue}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem
          label={t('orderDetails:equipmentServiceType')}
          data-testid={getListItemTestId('equipment-service-type')}
        >
          {translatedEqServiceType ?? equipmentDetails.serviceType}
        </StyledDescriptionListItem>

        {translatedEqOwnership && (
          <StyledDescriptionListItem
            label={t('wasteDetails:equipmentOwnership')}
            data-testid={getListItemTestId('equipment-ownership')}
          >
            {translatedEqOwnership}
          </StyledDescriptionListItem>
        )}
      </DescriptionList>
    </Tile>
  );
};
