import { FC, ReactNode, useMemo } from 'react';
import { QlikEngineContext } from './QlikEngineContext';

interface QlikEngineContextProviderProps {
  enigma: enigmaJS.IGeneratedAPI | null;
  children: ReactNode;
}
export const QlikEngineContextProvider: FC<QlikEngineContextProviderProps> = ({ enigma, children }) => {
  const providerValue = useMemo(() => ({ enigma }), [enigma]);
  return <QlikEngineContext.Provider value={providerValue}>{children}</QlikEngineContext.Provider>;
};
