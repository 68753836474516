import { SelectItem } from '@fortum/elemental-ui-fork';
import { useEffect, useMemo, useState } from 'react';
import { useContractsSites } from '@data/hooks/useContractsSites';
import {
  mapSelectItemsValuesToLocationCodes,
  mapSiteObjectsToLocationCodes,
  mapToSitesSelectItems,
} from '@utils/servicesFilters';
import { ContractFiltersKeys } from '@models/service';
import { displayMultiselectAppliedValues, moveSelectedToStart } from '@utils/dataOperations';

export const useContractsSitesMultiselect = (
  contractFiltersKeys: ContractFiltersKeys,
  additionalFilteringForOrdersCreation = false,
) => {
  const {
    data: availableSitesResponse,
    isLoading: areSitesLoading,
    isError: isSitesError,
  } = useContractsSites(contractFiltersKeys, additionalFilteringForOrdersCreation);

  const [selectedLocationCodes, setSelectedLocationCodes] = useState<string[]>([]);

  const [selectedSites, setSelectedSites] = useState<string[]>([]);
  const [sitesItems, setSiteItems] = useState<SelectItem<string>[]>([]);

  const sortedSitesItems = useMemo<SelectItem<string>[]>(
    () => moveSelectedToStart(sitesItems, selectedSites),
    [selectedSites, sitesItems],
  );

  useEffect(() => {
    setSiteItems(availableSitesResponse ? mapToSitesSelectItems(availableSitesResponse.sites) : []);

    if (!availableSitesResponse) return;

    if (selectedSites.length === 0) {
      setSelectedLocationCodes(mapSiteObjectsToLocationCodes(availableSitesResponse.sites));
      return;
    }

    setSelectedSites(prev => prev.filter(site => availableSitesResponse.sites.find(siteItem => siteItem.site === site)));
  }, [availableSitesResponse]);

  useEffect(() => {
    if (!availableSitesResponse?.sites) return;

    setSelectedLocationCodes(mapSelectItemsValuesToLocationCodes(selectedSites, availableSitesResponse.sites));
  }, [selectedSites]);

  const selectedSitesDisplayValue = useMemo<string | undefined>(() => {
    if (selectedSites.length > 1) return displayMultiselectAppliedValues(selectedSites);
  }, [selectedSites]);

  const shouldBeDisabled =
    areSitesLoading || !availableSitesResponse || availableSitesResponse.sites.length === 0 || isSitesError;

  return {
    selectedLocationCodes,
    areSitesLoading,
    isSitesError,
    selectedSites,
    sitesItems,
    sortedSitesItems,
    selectedSitesDisplayValue,
    setSelectedSites,
    shouldBeDisabled,
  };
};
