import { SupportedLanguage } from '@common/languages';
import { ReportApp, ReportConnectionConfig } from 'src/types/qlik';
import { getQlikConnectionConfig } from '../connection';
import { DashboardReportConfig, DashboardReportObjects } from './types';
import { dashboardReportConfigSchemaValidator } from './schemas';
import { createSchemaErrorMessage, getReportApp } from '@utils/qlik';

export * from './types';

const validateSchemas = (): ReportConnectionConfig<DashboardReportConfig, DashboardReportObjects> => {
  const connectionConfig = getQlikConnectionConfig();

  if (!import.meta.env.VITE_DASHBOARD_REPORT_CONFIG) {
    throw Error('Dashboard report config not found');
  }

  const reportConfig = JSON.parse(import.meta.env.VITE_DASHBOARD_REPORT_CONFIG);

  const validate = dashboardReportConfigSchemaValidator();

  if (!validate(reportConfig)) {
    throw Error('Report config does not match the schema: ' + createSchemaErrorMessage(validate.errors));
  }

  return { reportConfig, connectionConfig };
};

export const getDashboardReportApp = (lang: SupportedLanguage): ReportApp<DashboardReportObjects> => {
  const validatedSchemas = validateSchemas();

  return getReportApp(validatedSchemas, lang);
};
