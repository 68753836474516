import styled, { css } from 'styled-components';
import {
  colors,
  ContentText,
  CssValue,
  DescriptionListItem,
  fontWeights,
  Link,
  spacing,
  Table as ElementalTable,
  fontSizes,
  px2rem,
} from '@fortum/elemental-ui-fork';
import { ngColors } from '@utils/styleOverride';

//TODO: rename to horizontalContainerStyles
export const horizontalContainer = () => css`
  display: flex;
  flex-flow: row nowrap;
`;

//TODO: rename to verticalContainerStyles
export const verticalContainer = () => css`
  display: flex;
  flex-flow: column nowrap;
`;

export const StyledHomeLink = styled(Link)`
  &:visited {
    color: ${ngColors.black};
  }
`;

export const Circle = styled.span<{ $backgroundColor?: string; $size?: string }>`
  height: ${props => props.$size ?? spacing.xxs};
  width: ${props => props.$size ?? spacing.xxs};
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: inline-block;
  background-color: ${props => props.$backgroundColor ?? colors.forestGreen};
`;

export const Rectangle = styled.span<{
  $backgroundColor?: string;
  $width?: string;
  $height?: string;
}>`
  height: ${props => props.$height ?? spacing.xxxs};
  width: ${props => props.$width ?? spacing.xxxs};
  display: inline-block;
  background-color: ${props => props.$backgroundColor ?? ngColors.orange};
`;

export const StyledDescriptionListItem = styled(DescriptionListItem)`
  dt,
  dd {
    font-size: 16px;
    font-weight: 400;
    vertical-align: top;
  }

  dt {
    width: 204px;
    white-space: unset;
  }

  dd {
    word-wrap: break-word;
  }
`;
export const MediumDescriptionListItem = styled(StyledDescriptionListItem)`
  dt,
  dd {
    font-weight: ${fontWeights.medium};
    color: ${colors.inkGrey};
  }
`;

export const Table = styled(ElementalTable)`
  table {
    font-size: ${fontSizes.s.s};
  }

  th,
  td {
    padding: ${spacing.xxs} 12px;
    vertical-align: middle;
  }

  th {
    white-space: nowrap;
    color: ${colors.inkGrey};
  }

  th:first-child,
  td:first-child {
    padding-left: ${spacing.xxs};
  }

  th:last-child,
  td:last-child {
    padding-right: ${spacing.xxs};
  }
`;

export const CONTAINER_BORDER_RADIUS = spacing.xxxxs;

export const boxTopShadowStyles = () => css`
  box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, 0.15);

  border-bottom-left-radius: ${CONTAINER_BORDER_RADIUS};
  border-bottom-right-radius: ${CONTAINER_BORDER_RADIUS};
`;

export const elevatedBoxWithTopShadowStyles = () => css`
  ${boxTopShadowStyles};

  z-index: 2;
`;

//TODO: handle dynamic interactive border
type BorderType = 'inactive' | 'error' | 'active';
export const borderTypeToColorMap: { [key in BorderType]: string } = {
  inactive: colors.fogGrey,
  active: ngColors.orange,
  error: colors.punchRed,
};

export const staticBorderStyles = (borderWidth: CssValue = '1px') => css`
  border: ${borderWidth} solid ${borderTypeToColorMap['inactive']};
  border-radius: ${CONTAINER_BORDER_RADIUS};
`;

export const VerticalContainer = styled.div`
  ${verticalContainer};
`;

export const HorizontalContainer = styled.div`
  ${horizontalContainer};
`;

export const baseLineBreakItem = `
li {
    padding: 12px 0px;
    box-sizing: border-box;
  }

  li > label > span {
    white-space: pre-wrap;
    line-height: 1.7rem;
  }
`;

export const Divider = styled.div`
  border-bottom: ${px2rem(2)} solid ${colors.cloudGrey};
`;

export const NormalSizeContentText = styled(ContentText)`
  font-size: 1rem;
`;
