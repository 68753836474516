import { VerticalContainer } from '@components/styles';
import { Namespace } from '@config/i18n';
import { UserPermission } from '@config/user';
import { IconFiles } from '@fortum/elemental-ui-fork';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeader, Placeholders } from '@components/index';
import { PermissionItem, PermissionItemProps } from './PermissionItem';
import { ItemsContainer } from './styles';
import { Stylable, Testable } from '@components/types';

interface PermissionsFormProps extends Stylable, Testable {
  permissions: UserPermission[];
  selectedPermissions: UserPermission[];
  onCheckboxClick: PermissionItemProps['onCheckboxClick'];
  isLoading: boolean;
  disabled?: boolean;
}

export const PermissionsForm: FC<PermissionsFormProps> = ({
  permissions,
  selectedPermissions,
  onCheckboxClick,
  isLoading,
  className,
  disabled = false,
  'data-testid': dataTestId,
}) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  return (
    <VerticalContainer className={className} data-testid={dataTestId}>
      <SectionHeader icon={<IconFiles />} text={`${t('detailsPanel.addFeaturesAccess.header')}`} size="s" />
      <ItemsContainer>
        {isLoading ? (
          <Placeholders lines={2} />
        ) : (
          permissions.map(permission => (
            <PermissionItem
              key={permission}
              permission={permission}
              disabled={disabled}
              checked={selectedPermissions.includes(permission)}
              onCheckboxClick={onCheckboxClick}
            />
          ))
        )}
      </ItemsContainer>
    </VerticalContainer>
  );
};
