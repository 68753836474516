import { Namespace } from '@config/i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UserStatusToggleContainer } from './styles';
import { ContentText, Toggle } from '@fortum/elemental-ui-fork';

interface UserStatusToggleProps {
  userIsActive: boolean;
  onToggleChange: () => void;
  toggleDisabled: boolean;
}

export const UserStatusToggle: FC<UserStatusToggleProps> = ({ userIsActive, onToggleChange, toggleDisabled }) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  return (
    <UserStatusToggleContainer>
      <ContentText>{t('userStatusToggle.label')}</ContentText>

      <Toggle checked={userIsActive} name="user-status" onChange={onToggleChange} disabled={toggleDisabled} />
    </UserStatusToggleContainer>
  );
};
