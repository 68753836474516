import { FC } from 'react';
import { gridStyles, columnStyles, VerticalContainerWithBigGap } from './styles';
import { Grid, Row, Col } from '@fortum/elemental-ui-fork';
import { EquipmentDetails, TransportDetailsForm, InvoicingDetailsForm } from '@routes/ordersCreation/components';
import { Testable } from '@components/types';
import { Contract } from '@models/contract';
import { CreationForm } from 'src/types/ordersCreation';

interface OrderPreviewDetailsProps extends Testable {
  form: CreationForm['forms'][number];
  contract: Contract;
}

export const OrderPreviewDetails: FC<OrderPreviewDetailsProps> = ({ form, contract, 'data-testid': dataTestId }) => {
  const { invoicingDetails } = contract.contractDetails;

  return (
    <Grid {...gridStyles} data-testid={dataTestId}>
      <Row>
        <Col {...columnStyles}>
          <TransportDetailsForm form={form} contract={contract} />
        </Col>

        <Col {...columnStyles}>
          <VerticalContainerWithBigGap>
            <EquipmentDetails equipmentDetails={contract.serviceDetails.equipmentDetails} />
            <InvoicingDetailsForm form={form} invoicingDetails={invoicingDetails} />
          </VerticalContainerWithBigGap>
        </Col>
      </Row>
    </Grid>
  );
};
