import styled from 'styled-components';
import { PageHeader as CommonPageHeader } from '@components/PageHeader';
import { spacing } from '@fortum/elemental-ui-fork';
import { HorizontalContainer, VerticalContainer } from '@components/styles';
import { FiltersAccordion } from '@components/FiltersAccordion';
import { InputFieldProps } from '@components/InputField';

export const FILTER_MIN_WIDTH = '7rem';
export const FILTER_MAX_WIDTH = '25rem';

export const StyledCommonHeader = styled(CommonPageHeader)`
  padding-left: 0px;
  padding-right: 0px;
`;

export const Container = styled(VerticalContainer)`
  padding: 0 ${spacing.xxl};
`;

export const FiltersContainer = styled(HorizontalContainer)`
  flex: 1;
  gap: ${spacing.xxs};
  justify-content: flex-end;
  margin-left: ${spacing.xs};

  & > div {
    flex: 1 1 0px;
    max-width: ${FILTER_MAX_WIDTH};
  }
`;

export const StyledFiltersAccordion: typeof FiltersAccordion = styled(FiltersAccordion)`
  margin-bottom: ${spacing.xs};
`;

export const inputFieldStyles: Pick<InputFieldProps, 'size'> = {
  size: 's',
};

export const ActionComponentsContainer = styled(HorizontalContainer)`
  flex: 1;
  gap: ${spacing.xxs};
`;
