import { paths } from '@config/routes';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationHeaderWithMarginBottom, OrdersCreationContext } from '../components';
import { SummaryTable } from './components/SummaryTable/SummaryTable';
import { Container, ButtonsContainer, StyledCheckbox, agreementInfoInPreviewStyles } from './components/styles';
import { CreationDetails } from './components/CreationDetails';
import { Button, ContentText, ErrorMessage, useToggle } from '@fortum/elemental-ui-fork';
import { useTranslation } from 'react-i18next';
import i18n, { Namespace } from '@config/i18n';
import { VerticalContainer } from '@components/styles';
import { discardButtonStyles, backButtonStyles, primaryButtonStyles } from './components/styles';
import { SummaryPreviewHeader } from './components/SummaryPreviewHeader/SummaryPreviewHeader';
import { SummaryHeader } from './components/SummaryHeader/SummaryHeader';
import { PageNavigationHeaderProps } from '@components/PageNavigationHeader';
import { CreationForms } from 'src/types/ordersCreation';
import { useNotification } from '@components/Notification';
import { useOrdersCreationMutation } from '@data/hooks/useOrdersCreationMutation';
import { PendingRequestView } from './components/PendingRequestView/PendingRequestView';
import { OrderCreationTransactionId } from '@models/orders';
import {
  convertOrderSummaryToCSVFormat,
  mapCreationFormsToTransactionIds,
  shouldDisplayErrorSummaryHeader,
} from '@utils/ordersCreation';
import { OrderCreationFailedNotification } from './components/OrderCreationFailedNotification';
import { ConfirmationModal } from '@components/index';
import { getDateString } from '@utils/dates';
import { ContactSection } from './components/ContactSection';
import { CSVExportButton } from '@components/CSVExportButton/CSVExportButton';
import { scrollToTop } from '@utils/general';
import { APPLICATION_CONTAINER_ID } from '@common/ids';

interface CommonSummaryProps {
  creationForms: CreationForms;
  goToOrdersCreation: () => void;
  creationDate: string;
}

interface SummaryPreviewProps extends CommonSummaryProps {
  submitOrders: () => void;
}

const SummaryPreview: FC<SummaryPreviewProps> = ({ creationForms, submitOrders, goToOrdersCreation, creationDate }) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation<Namespace[]>(['common', 'orderCreation']);

  const headerLinkProps = useMemo<PageNavigationHeaderProps['link']>(
    () => ({
      id: 'navigate-to-home-link',
      description: t('orderCreation:returnToPreviousPage'),
      onClick: () => navigate(-1),
    }),
    [i18n.language],
  );

  const checkBoxMessage = `* ${t('orderCreation:summaryPage.confirmationCheckbox.label')}`;

  const [modalOpen, toggleModalOpen] = useToggle(false);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [displayCheckboxError, setDisplayCheckboxError] = useState(false);

  const toggleCheckbox = useCallback(
    () =>
      setCheckboxChecked(prev => {
        if (!prev) setDisplayCheckboxError(false);

        return !prev;
      }),
    [],
  );

  const navigateBack = useCallback(() => navigate(-1), []);

  const submit = useCallback(() => {
    if (checkboxChecked) {
      submitOrders();
    } else setDisplayCheckboxError(true);
  }, [checkboxChecked, submitOrders]);

  return (
    <>
      <NavigationHeaderWithMarginBottom link={headerLinkProps} title={t('orderCreation:pageHeader')} />
      <Container>
        <SummaryPreviewHeader />
        <CreationDetails creationDate={creationDate} />

        <SummaryTable creationForms={creationForms} creationDate={creationDate} />

        <VerticalContainer>
          <StyledCheckbox name="confirmVerification" label={checkBoxMessage} onClick={toggleCheckbox} />
          <ErrorMessage active={displayCheckboxError}>
            <ContentText>{t('orderCreation:summaryPage.checkboxError')}</ContentText>
          </ErrorMessage>
          <ContentText {...agreementInfoInPreviewStyles}>{t('orderCreation:summaryPage.agreementInformation')}</ContentText>
        </VerticalContainer>

        <ButtonsContainer>
          <Button data-testid="discard-creation-button" {...discardButtonStyles} onClick={toggleModalOpen}>
            {t('common:discard')}
          </Button>
          <Button data-testid="back-to-forms-button" {...backButtonStyles} onClick={navigateBack}>
            {t('common:back')}
          </Button>
          <Button data-testid="submit-creation-button" {...primaryButtonStyles} onClick={submit}>
            {t('common:send')}
          </Button>
        </ButtonsContainer>
      </Container>

      <ConfirmationModal
        headerText={t('common:discard')}
        messageText={t('orderCreation:summaryPage.discardModal.message')}
        closingButtonText={t('common:refuseDelete')}
        confirmingButtonText={t('common:confirmDelete')}
        opened={modalOpen}
        onClose={toggleModalOpen}
        onConfirmClick={goToOrdersCreation}
      />
    </>
  );
};

interface SummaryProps extends CommonSummaryProps {
  failedOrders: OrderCreationTransactionId[];
  displayErrorHeader: boolean;
}

const Summary: FC<SummaryProps> = ({ creationForms, goToOrdersCreation, failedOrders, displayErrorHeader, creationDate }) => {
  const navigate = useNavigate();

  const { t } = useTranslation<Namespace[]>(['common', 'orderCreation']);

  const navigateToOrdersOverview = useCallback(() => navigate(paths.ordersTracking), []);

  const summaryCVSFields = useMemo(() => {
    return convertOrderSummaryToCSVFormat(creationForms);
  }, [creationForms, i18n.language]);

  return (
    <Container>
      <SummaryHeader onClick={goToOrdersCreation} displayErrorMessage={displayErrorHeader} />
      <CreationDetails creationDate={creationDate} />
      <SummaryTable creationForms={creationForms} failedOrders={failedOrders} creationDate={creationDate} />
      <ContactSection />
      <ButtonsContainer>
        <CSVExportButton
          data={summaryCVSFields.data}
          headers={summaryCVSFields.headers}
          filename={creationDate + '_' + t('orderCreation:pageHeader')}
        />
        <Button data-testid="navigate-to-orders-button" {...primaryButtonStyles} onClick={navigateToOrdersOverview}>
          {t('orderCreation:summaryPage.goToOrdersOverview')}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export const SummaryPage: FC = () => {
  const { displayNotification, setDisplayErrorNotification, closeNotification } = useNotification();
  const navigate = useNavigate();

  const creationDate = useMemo(() => getDateString(new Date()), []);

  const { creationForms, clearForms } = useContext(OrdersCreationContext);
  const [preview, togglePreview] = useToggle(true);
  const [failedOrders, setFailedOrders] = useState<OrderCreationTransactionId[]>([]);

  const [cachedCreationForms] = useState<CreationForms>(creationForms);

  const [displayErrorHeader, setDisplayErrorHeader] = useState(false);

  const goToOrdersCreation = useCallback(() => {
    clearForms();
    navigate(paths.ordersCreation);
  }, []);

  useEffect(() => {
    if (creationForms.length === 0) navigate(paths.ordersCreation);

    scrollToTop(document.getElementById(APPLICATION_CONTAINER_ID));
  }, []);

  const ordersCreationMutation = useOrdersCreationMutation({
    onSuccess: response => {
      if (response.forbiddenOrders.length > 0) {
        setFailedOrders(response.forbiddenOrders);
        setDisplayErrorNotification();
      }

      setDisplayErrorHeader(shouldDisplayErrorSummaryHeader(response));
    },
    onError: () => {
      setFailedOrders(mapCreationFormsToTransactionIds(creationForms));
      setDisplayErrorHeader(true);
      setDisplayErrorNotification();
    },
    onSettled: () => {
      togglePreview();
      clearForms();
    },
  });

  const createOrders = () => ordersCreationMutation.mutate({ creationForms, language: i18n.language });

  if (ordersCreationMutation.isPending) {
    return <PendingRequestView />;
  }

  return (
    <>
      {preview ? (
        <SummaryPreview
          creationForms={creationForms}
          goToOrdersCreation={goToOrdersCreation}
          submitOrders={createOrders}
          creationDate={creationDate}
        />
      ) : (
        <Summary
          creationForms={cachedCreationForms}
          goToOrdersCreation={goToOrdersCreation}
          failedOrders={failedOrders}
          displayErrorHeader={displayErrorHeader}
          creationDate={creationDate}
        />
      )}

      <OrderCreationFailedNotification displayNotification={displayNotification} closeNotification={closeNotification} />
    </>
  );
};
