import { QueryKeys } from '@common/query';
import { fetchUsersCompanies } from '@data/api/usersManagement';
import { QueryOptions } from 'src/types/reactQuery';
import { UsersCompaniesResponse } from '@models/usersManagement';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';

const usersCompaniesQuery = (): QueryOptions<UsersCompaniesResponse> => ({
  queryKey: [QueryKeys.usersProfilesFilters],
  queryFn: fetchUsersCompanies,
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useUsersCompanies = () => useQuery<UsersCompaniesResponse, Error>(usersCompaniesQuery());
