export const ngColors = {
  orange: '#F15922',
  mist: '#EDEDED',
  black: '#000000',
  earth: '#F2EDE7',
  white: '#FFFFFF',
};

export const styleOverride = {
  colors: {
    primary: ngColors.orange,
  },
  tokens: {
    brand: {
      logo: ngColors.orange,
    },
    notification: { primary: { background: ngColors.orange } },
    text: {
      link: ngColors.black,
    },
  },
  font: { family: '"Inter", sans-serif' },
};
