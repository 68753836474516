import { SelectItem } from '@fortum/elemental-ui-fork';
import { ContractMetaFiltersSelectItems, ContractFilters, ContractsFiltersDisability } from 'src/types/services';
import { ContractFiltersKeys } from '@models/service';
import { KeyValuePair } from 'src/types/general';
import { ContractsSitesResponse } from '@models/contract';

export class ServiceFiltersAvailabilityValidator {
  constructor(
    private selectItems: ContractMetaFiltersSelectItems,
    private isLoading: boolean,
    private isError: boolean,
    private lastDataReloadCause: keyof ContractMetaFiltersSelectItems | null,
  ) {}

  filterDisabled = (key: keyof ContractMetaFiltersSelectItems) => {
    if (this.selectItems[key].length === 0 || this.isError) {
      return true;
    }

    return this.isLoading && !!this.lastDataReloadCause && this.lastDataReloadCause !== key;
  };
}

export const shouldFiltersBeDisabled = (
  filtersSelectItems: ContractMetaFiltersSelectItems,
  isLoading: boolean,
  isError: boolean,
  lastDataReloadCause: keyof ContractMetaFiltersSelectItems | null,
): ContractsFiltersDisability => {
  const validator = new ServiceFiltersAvailabilityValidator(filtersSelectItems, isLoading, isError, lastDataReloadCause);

  return {
    wasteTypes: validator.filterDisabled('wasteTypes'),
    equipmentTypes: validator.filterDisabled('equipmentTypes'),
  };
};

export const mapToFiltersKeys = (filters: ContractFilters): ContractFiltersKeys => ({
  wastes: filters.wasteTypes.map(waste => waste.key),
  equipments: filters.equipmentTypes.map(eq => eq.key),
  search: filters.search,
});

export const mapToServiceFilters = (
  selectedFilterKeys: string[],
  filterSelectItems: ContractMetaFiltersSelectItems['equipmentTypes'] | ContractMetaFiltersSelectItems['wasteTypes'],
): KeyValuePair[] =>
  filterSelectItems
    .filter(selectItem => selectedFilterKeys.includes(selectItem.value))
    .map(selectItem => ({ value: selectItem.name, key: selectItem.value }));

export const mapToFiltersSelectItems = (filters: KeyValuePair[]): SelectItem<string>[] =>
  filters.map(filter => ({
    name: filter.value,
    value: filter.key,
  }));

export const getServiceFiltersByKeyOrValue = (serviceFilters: KeyValuePair[], key: string, value: string) =>
  serviceFilters.find(filter => filter.key === key) || serviceFilters.find(filter => filter.value === value);

export const mapToSitesSelectItems = (sites: ContractsSitesResponse['sites']): SelectItem<string>[] =>
  sites.map(siteItem => ({ name: siteItem.site, value: siteItem.site }));

const getLocationsCodeFromSelectItemValue = (value: string, availableSites: ContractsSitesResponse['sites']) =>
  availableSites.find(availableSite => availableSite.site === value)?.locations ?? [];

export const mapSelectItemsValuesToLocationCodes = (
  selectedSitesValues: string[],
  availableSites: ContractsSitesResponse['sites'],
) => selectedSitesValues.flatMap(selectedSitesValue => getLocationsCodeFromSelectItemValue(selectedSitesValue, availableSites));

export const mapSiteObjectsToLocationCodes = (sites: ContractsSitesResponse['sites']) =>
  sites.flatMap(availableSite => availableSite.locations);
