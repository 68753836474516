import { createGlobalStyle } from 'styled-components';
import { colors } from '@fortum/elemental-ui-fork';
import { ngColors } from '@utils/styleOverride';
const GlobalStyle = createGlobalStyle`

    * {
        padding: 0;
        margin: 0;
        font-family: 'Inter', sans-serif;

    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    html, body {
    height: 100%;
    overflow: hidden;
    }

    #root {
        height: 100%;
        width: 100%;
        margin: 0;
        overflow: hidden;
        background: white;
        scrollbar-color: ${ngColors.orange} ${colors.snowWhite};
        scrollbar-width: thin;
        appearance: none;
        -moz-appearance: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    ::-webkit-scrollbar {
        background-color: ${colors.snowWhite};
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${ngColors.orange};
        border-bottom: 0.125rem solid ${colors.snowWhite};
        border-top: 0.125rem solid ${colors.snowWhite};
        box-shadow: none;
    }

    ::-webkit-scrollbar-track {
        background: ${colors.snowWhite};
        box-shadow: none;
    }
    .leaflet-container {
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    tr:hover td {
    background-color: ${ngColors.mist} !important;
  }
`;

export default GlobalStyle;
