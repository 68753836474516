import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('VITE_API_URL');
const baseUrl = `${reactAppApiUrl}/rwportal-contracts/v5`;

export const ContractService = {
  services: `${baseUrl}/contracts/count/own`,
  servicesAdmin: `${baseUrl}/contracts/count`,
  servicesDetails: `${baseUrl}/contracts/by-location`,
  contractDetails: `${baseUrl}/contracts`,
  servicesFilters: `${baseUrl}/contracts/filters/own`,
  servicesFiltersAdmin: `${baseUrl}/contracts/filters`,
  locations: `${baseUrl}/contracts/locations/own`,
  locationsAdmin: `${baseUrl}/contracts/locations`,
  contractsSites: `${baseUrl}/contracts/sites/own`,
  contractsSitesAdmin: `${baseUrl}/contracts/sites`,
  contractsByLocations: `${baseUrl}/contracts/by-locations`,
};
