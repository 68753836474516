import { horizontalContainer, staticBorderStyles } from '@components/styles';
import {
  spacing,
  ContentTextProps,
  fontSizes,
  Pagination,
  PaginationProps as ElementalPaginationProps,
  CssValue,
} from '@fortum/elemental-ui-fork';
import styled from 'styled-components';
import type { PaginationSize } from './Pagination';
import { ngColors } from '@utils/styleOverride';

export const paginationHeights: { [key in PaginationSize]: CssValue } = {
  m: '56px',
  l: '80px',
};

const subComponentsSizes: { [key in PaginationSize]: CssValue } = {
  m: '40px',
  l: '48px',
};

export const PaginationContainer = styled.div<{ $size: PaginationSize }>`
  ${horizontalContainer};

  width: 100%;

  height: ${({ $size }) => paginationHeights[$size]};
  padding: 0 ${spacing.xs};
  box-sizing: border-box;

  align-items: center;
  justify-content: flex-end;

  background-color: ${ngColors.white};
`;

export const goToTextStyles: ContentTextProps = {
  ph: spacing.xxs,
  color: ngColors.black,
  fontSize: fontSizes.xs,
};

type PaginationProps = ElementalPaginationProps & { $size: PaginationSize };

export const StyledElementalPagination = styled(Pagination)<PaginationProps>`
  height: ${({ $size }) => subComponentsSizes[$size]};

  li {
    height: ${({ $size }) => subComponentsSizes[$size]};
    width: ${({ $size }) => subComponentsSizes[$size]};
    > button {
      color: ${ngColors.black};
      &[aria-current='true'] {
        color: ${ngColors.white};
      }
    }
  }
`;

export const StyledInput = styled.input<{ $size: PaginationSize }>`
  ${staticBorderStyles('2px')};

  font-size: 1.125rem;

  box-sizing: border-box;
  padding: 0 ${spacing.xxxxs};
  outline: none;
  background: ${ngColors.white};

  color: ${ngColors.black};

  text-align: center;
  transition:
    background 250ms,
    border-color 300ms;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);

  height: ${({ $size }) => subComponentsSizes[$size]};
  width: ${({ $size }) => subComponentsSizes[$size]};

  &:hover {
    border-color: ${ngColors.orange};
  }

  &:invalid {
    background-color: ${ngColors.white};
    border-color: ${ngColors.orange};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
