import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { displayMultiselectAppliedValues, moveSelectedToStart } from '@utils/dataOperations';
import { shouldFiltersBeDisabled } from '@utils/servicesFilters';
import { ContractsFiltersDisability } from 'src/types/services';
import {
  useDebounceWithTemporaryValue,
  useContractsSitesMultiselect,
  UseContractsFiltersHandlerReturnValue,
  useContractsFiltersHandler,
} from '@hooks/index';
import { APPLY_ADDITIONAL_FILTERING_TO_SERVICES_SELECTOR_DATA } from '@routes/ordersCreation/config';
import { SelectItem } from '@fortum/elemental-ui-fork';
import { SimplifiedEvent } from 'src/types/events';
import { TRIGGER_REQUEST_SEARCH_LENGTH_THRESHOLD } from '@common/consts';

type ServicesSelectorFilters = {
  filtersKeys: UseContractsFiltersHandlerReturnValue['filtersKeysWithSearch'];
  temporarySearch: string;
  shouldBeDisabled: ContractsFiltersDisability;
  selectedEquipmentDisplayValue: string | undefined;
  selectedWasteDisplayValue: string | undefined;
  sortedEquipmentItems: SelectItem<string>[];
  sortedWasteItems: SelectItem<string>[];
  changeTemporarySearch: (event: SimplifiedEvent<string>) => void;
} & Pick<
  UseContractsFiltersHandlerReturnValue,
  'queryResult' | 'debouncedFiltersKeysWithSearch' | 'handleWasteTypesChange' | 'handleEquipmentTypesChange'
>;

export type UseServicesSelectorReturnType = {
  filters: ServicesSelectorFilters;
  sites: {
    selectedSitesDisplayValue: string | undefined;
    sitesItems: SelectItem<string>[];
    selectedLocationCodes: string[];
    selectedSites: string[];
    shouldBeDisabled: boolean;
    areSitesLoading: boolean;
    isSitesError: boolean;
    sortedSitesItems: SelectItem<string>[];
    setSelectedSites: Dispatch<SetStateAction<string[]>>;
  };
  clearAllSelections: () => void;
};

export const useServicesSelector = (): UseServicesSelectorReturnType => {
  const {
    handleSearchChange,
    handleWasteTypesChange,
    handleEquipmentTypesChange,
    queryResult: filtersQueryResult,
    search,
    selectedEquipmentTypes,
    selectedWasteTypes,
    filtersKeysWithSearch: filtersKeys,
    filtersSelectItems,
    debouncedFiltersKeysWithSearch,
    lastDataReloadCause,
    resetAll: resetAllFilters,
  } = useContractsFiltersHandler(APPLY_ADDITIONAL_FILTERING_TO_SERVICES_SELECTOR_DATA);

  const {
    areSitesLoading,
    isSitesError,
    sitesItems,
    selectedSitesDisplayValue,
    selectedSites,
    selectedLocationCodes,
    shouldBeDisabled,
    setSelectedSites,
    sortedSitesItems,
  } = useContractsSitesMultiselect(debouncedFiltersKeysWithSearch, APPLY_ADDITIONAL_FILTERING_TO_SERVICES_SELECTOR_DATA);

  const handleSearchValueChange = (searchText: string) => {
    if (!searchText || searchText.trim().length > TRIGGER_REQUEST_SEARCH_LENGTH_THRESHOLD) handleSearchChange(searchText.trim());
  };

  const { changeTemporaryValue: changeTemporarySearch, temporaryValue: temporarySearch } = useDebounceWithTemporaryValue(
    search,
    handleSearchValueChange,
  );

  const sortedWasteItems = useMemo(
    () => moveSelectedToStart(filtersSelectItems.wasteTypes, filtersKeys.wastes),
    [filtersKeys.wastes, filtersSelectItems.wasteTypes],
  );

  const sortedEquipmentItems = useMemo(
    () => moveSelectedToStart(filtersSelectItems.equipmentTypes, filtersKeys.equipments),
    [filtersSelectItems.equipmentTypes, filtersKeys.equipments],
  );

  const selectedWasteDisplayValue = useMemo<string | undefined>(() => {
    if (selectedWasteTypes.length > 0) {
      return displayMultiselectAppliedValues(selectedWasteTypes.map(wasteType => wasteType.value));
    }
  }, [selectedWasteTypes]);

  const selectedEquipmentDisplayValue = useMemo<string | undefined>(() => {
    if (selectedEquipmentTypes.length > 0) {
      return displayMultiselectAppliedValues(selectedEquipmentTypes.map(equipmentType => equipmentType.value));
    }
  }, [selectedEquipmentTypes]);

  const filtersDisabled = useMemo<ContractsFiltersDisability>(
    () =>
      shouldFiltersBeDisabled(filtersSelectItems, filtersQueryResult.isLoading, filtersQueryResult.isError, lastDataReloadCause),
    [filtersQueryResult.isLoading, filtersQueryResult.isError, filtersSelectItems, lastDataReloadCause],
  );

  const clearAllSelections = useCallback(() => {
    resetAllFilters();
    setSelectedSites([]);
  }, [resetAllFilters, setSelectedSites]);

  return {
    filters: {
      debouncedFiltersKeysWithSearch,
      filtersKeys,
      queryResult: filtersQueryResult,
      temporarySearch,
      shouldBeDisabled: filtersDisabled,
      selectedEquipmentDisplayValue,
      selectedWasteDisplayValue,
      sortedEquipmentItems,
      sortedWasteItems,
      changeTemporarySearch,
      handleWasteTypesChange,
      handleEquipmentTypesChange,
    },
    sites: {
      selectedSitesDisplayValue,
      sitesItems,
      selectedLocationCodes,
      selectedSites,
      shouldBeDisabled,
      areSitesLoading,
      isSitesError,
      sortedSitesItems,
      setSelectedSites,
    },
    clearAllSelections,
  };
};
