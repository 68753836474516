import { PropsWithChildren } from 'react';

import { ServicesSelectorContext } from './ServicesSelectorContext';
import { useServicesSelector } from './useServicesSelector';
type ServicesSelectorContextProviderProps = PropsWithChildren;

export const ServicesSelectorStateProvider = ({ children }: ServicesSelectorContextProviderProps) => {
  const servicesSelectorHandler = useServicesSelector();

  return <ServicesSelectorContext.Provider value={servicesSelectorHandler}>{children}</ServicesSelectorContext.Provider>;
};
