import { FC, ReactNode } from 'react';
import {
  colors,
  ContentText,
  Float,
  IconCheck,
  IconError,
  Notification as ElementalNotification,
  NotificationProps as ElementalNotificationProps,
} from '@fortum/elemental-ui-fork';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { Testable } from '@components/types';

export type NotificationType = 'error' | 'success';
type Position = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'topCenter' | 'bottomCenter';
interface NotificationProps extends Testable {
  type: NotificationType;
  content: string | ReactNode;
  position?: Position;
}

export const Notification: FC<NotificationProps & ElementalNotificationProps> = ({
  type,
  content,
  position = 'bottomCenter',
  id,
  'data-testid': dataTestId,
  ...props
}) => {
  const { t } = useTranslation<Namespace>('common');

  return (
    <Float opened={!!props.opened} anchorPos={position} id={id}>
      <ElementalNotification
        readMoreText={t('showMore')}
        data-testid={dataTestId}
        {...props}
        iconButtonProps={{ 'aria-label': 'close notification' }}
        backgroundColor={type === 'error' ? colors.hazeRedOrange : colors.forestGreen}
        textColor={colors.snowWhite}
        icon={type === 'error' ? IconError : IconCheck}
      >
        {typeof content === 'string' ? <ContentText>{content}</ContentText> : <>{content}</>}
      </ElementalNotification>
    </Float>
  );
};
