export const userPermissions = [
  'OrderTrackingAndServiceView',
  'SendMessageToCustomerService',
  'SiteManagement',
  'Reports',
  'UserAccountManagement',
  'DocumentManagement',
  'OrderCreation',
  'CO2Report',
  'ManageMetalsMasterData',
  'ManageMetalsContracts',
  'ManageMetalsOrders',
  'ManageMetalsPrices',
  'ReportsDashboardAndCostDetails',
  'ReportsWasteDetails',
  'OrdersManagement',
] as const;

export type UserPermission = (typeof userPermissions)[number];
