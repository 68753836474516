import { CssValue, TableProps, tableColumn } from '@fortum/elemental-ui-fork';
import { ContractByLocation } from '@models/contract';
import { FC, useCallback, useMemo } from 'react';
import { getColumnsBaseConfiguration } from './config';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { CellPlaceholder } from '@components/CellPlaceholder';
import { getEmptyData } from '@utils/dataPlaceholders';
import {
  StyledTable,
  defaultCellContainerStyle,
  companyCellContainerStyle,
  companyLocationCellContainerStyle,
  contractNoCellContainerStyle,
} from './ContractsTable.styles';
import { translate } from '@utils/internationalization';
import { createContractIdentifier } from '@utils/routes';
import { StyledFilteringResult } from './styles';
import { camelCase } from 'lodash';

interface ContractsTableProps {
  id: string;
  contracts: ContractByLocation[];
  isLoading: boolean;
  paginationHeight: CssValue;
  onRowClick: (row: ContractByLocation) => void;
  selectedContractIdentifier: string | null;
}

const emptyContracts: ContractByLocation[] = getEmptyData<ContractByLocation>(10).map((contract, index) => ({
  ...contract,
  contractNo: index.toString(),
}));

export const ContractsTable: FC<ContractsTableProps> = ({
  id,
  isLoading,
  contracts,
  paginationHeight,
  selectedContractIdentifier,
  onRowClick,
}) => {
  const { t, i18n } = useTranslation<Namespace>('orderCreation');

  const columnsBaseConfig = useMemo(getColumnsBaseConfiguration, [i18n.language]);

  const placeholderColumns = useMemo(
    () => [
      tableColumn<ContractByLocation, string>(row => row.companyName)({
        ...columnsBaseConfig.companyName,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: companyCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.contractLocation)({
        ...columnsBaseConfig.contractLocation,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: companyLocationCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.serviceType)({
        ...columnsBaseConfig.serviceType,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: defaultCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.wasteType)({
        ...columnsBaseConfig.wasteType,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: defaultCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.equipmentType)({
        ...columnsBaseConfig.equipmentType,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: defaultCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.transportType)({
        ...columnsBaseConfig.transportType,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: defaultCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.contractNo)({
        ...columnsBaseConfig.contractNo,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: contractNoCellContainerStyle,
      }),
    ],
    [columnsBaseConfig],
  );

  const columns = useMemo(
    () => [
      tableColumn<ContractByLocation, string>(row => row.companyName)({
        ...columnsBaseConfig.companyName,
        cellContainerStyle: companyCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.contractLocation)({
        ...columnsBaseConfig.contractLocation,
        cellContainerStyle: companyLocationCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => translate(camelCase(row.serviceType), 'domain', 'contractType'))({
        ...columnsBaseConfig.serviceType,
        cellContainerStyle: defaultCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.wasteType)({
        ...columnsBaseConfig.wasteType,
        cellContainerStyle: defaultCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.equipmentType)({
        ...columnsBaseConfig.equipmentType,
        cellContainerStyle: defaultCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => translate(row.transportType, 'domain', 'transportType'))({
        ...columnsBaseConfig.transportType,
        cellContainerStyle: defaultCellContainerStyle,
      }),
      tableColumn<ContractByLocation, string>(row => row.contractNo)({
        ...columnsBaseConfig.contractNo,
        cellContainerStyle: contractNoCellContainerStyle,
      }),
    ],
    [columnsBaseConfig],
  );

  const getRowKey = useCallback((row: ContractByLocation) => createContractIdentifier(row.dataSource, row.contractNo), []);

  const selectedRows = useMemo(
    () => (selectedContractIdentifier ? [selectedContractIdentifier] : []),
    [selectedContractIdentifier],
  );

  if (!isLoading && contracts.length === 0) {
    return <StyledFilteringResult message={t('servicesSelector.table.noContractsToShow')} />;
  }

  const stateDependantTableProps: Pick<TableProps<ContractByLocation>, 'rows' | 'columns' | 'onRowClick'> = isLoading
    ? {
        rows: emptyContracts,
        columns: placeholderColumns,
        onRowClick: undefined,
      }
    : {
        rows: contracts,
        columns,
        onRowClick,
      };

  return (
    <StyledTable
      id={id}
      data-testid="contracts-table"
      size="small"
      rowStyle="zebra"
      $paginationHeight={paginationHeight}
      stickyHeader
      getRowKey={getRowKey}
      selectedRows={selectedRows}
      {...stateDependantTableProps}
    />
  );
};
