import { getEnvProperty } from '@utils/general';
import { SupportedLanguage, fallbackLang } from './languages';

export const getPrivacyNoticeURL = (currentLanguage: SupportedLanguage) => {
  const privacyNoticeUrls = getEnvProperty('VITE_PRIVACY_NOTICE_URLS');
  if (!privacyNoticeUrls) return null;

  const parsedUrls = JSON.parse(privacyNoticeUrls);

  return parsedUrls[currentLanguage] || parsedUrls[fallbackLang] || null;
};
