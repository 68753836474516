import { Tile } from '@components/Tile';
import { StyledDescriptionListItem } from '@components/styles';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui-fork';
import { OrderDetails } from '@models/orders';
import { displayedValue } from '@utils/dataOperations';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SiteAddress } from './SiteAddress';

interface CompanySiteDetailsProps {
  companySiteDetails: OrderDetails['companySiteDetails'];
  wrapSiteAddress?: boolean;
}

export const CompanySiteDetailsTile: FC<CompanySiteDetailsProps> = ({ companySiteDetails, wrapSiteAddress = false }) => {
  const { t } = useTranslation<Namespace>('wasteDetails');

  return (
    <Tile header={t('companySiteDetails')} data-testid={getTileTestId('company-site-details')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem label={t('company')} data-testid={getListItemTestId('company')}>
          {displayedValue(companySiteDetails.company)}
        </StyledDescriptionListItem>
        <StyledDescriptionListItem label={t('address')} data-testid={getListItemTestId('address')}>
          <SiteAddress address={companySiteDetails.address} wrap={wrapSiteAddress} />
        </StyledDescriptionListItem>
      </DescriptionList>
    </Tile>
  );
};
