import { Box, Button, colors, ContentText, fontSizes, Heading, IconSubtract, spacing } from '@fortum/elemental-ui-fork';
import { FC, memo, useMemo } from 'react';
import { Namespace } from '@config/i18n';
import { useTranslation } from 'react-i18next';
import { ContentTextStyles, SelectedContainer, SelectedHeader, SelectedOutterContainer } from './styles';
import { SelectCompanyItem } from './SelectCompanyPanel';
import { ngColors } from '@utils/styleOverride';

interface SelectedBoxProp {
  selected: SelectCompanyItem[];
  selectionLimit: number;
  onClear: () => void;
  deselect: (company: SelectCompanyItem) => void;
}

const SelectedBoxLayout: FC<SelectedBoxProp> = ({ selected, selectionLimit, onClear, deselect }) => {
  const { t } = useTranslation<Namespace[]>(['usersManagement']);

  const selectedComponent = useMemo(() => {
    return (
      <Box display="flex" flexDirection="column" gap={spacing.xxxs}>
        {selected.map((selectedCompany, index) => {
          const key = `${selectedCompany.name}-${selectedCompany.value}-"selected"-${index}`;
          return (
            <Box id={key} key={key} onClick={() => deselect(selectedCompany)}>
              <Box display="flex">
                <IconSubtract color={ngColors.orange} size={24} />
                <ContentText pl={spacing.xxs} color={colors.inkGrey} {...ContentTextStyles}>
                  {selectedCompany.name}
                </ContentText>
              </Box>
              <ContentText pl={spacing.m} color={colors.sonicGrey} {...ContentTextStyles}>
                {selectedCompany.value}
              </ContentText>
            </Box>
          );
        })}
      </Box>
    );
  }, [selected]);

  return (
    <SelectedOutterContainer>
      <SelectedContainer>
        <SelectedHeader>
          <Heading fontSize={fontSizes.m}>
            {t('companyPanel.countSelected', {
              selected: `${selected.length} /${selectionLimit}`,
            })}
          </Heading>
          <Button
            p={0}
            m={0}
            id={'clear-all'}
            height={'auto'}
            color={selected.length <= 0 ? colors.fogGrey : ngColors.orange}
            variant="normal"
            status="plain"
            onClick={onClear}
          >
            {t('companyPanel.clearAllSelection')}
          </Button>
        </SelectedHeader>
        {selected.length === 0 ? (
          <ContentText italic color={colors.sonicGrey} fontSize={fontSizes.s}>
            {t('companyPanel.selectionsWillAppear')}
          </ContentText>
        ) : (
          selectedComponent
        )}
      </SelectedContainer>
    </SelectedOutterContainer>
  );
};

export const SelectedBox = memo(SelectedBoxLayout);
