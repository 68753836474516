import { QueryKeys } from '@common/query';
import { Namespace } from '@config/i18n';
import { queryClient } from '@config/queryClient';
import { paths } from '@config/routes';
import { NotificationContext } from '@contexts/notification';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserDetailsFormContext } from './UserDetailsFormContext';
import { CreateUserRequestBody, EditUserRequestBody } from '@models/usersManagement';
import { mapUserCreationFormToRequestBody } from '@utils/usersManagement';
import { useUserEditMutation } from '@data/hooks/useEditUserMutation';

export const useUserEditing = (userId: string | undefined) => {
  const { t } = useTranslation<Namespace[]>(['usersManagement', 'errors']);

  const navigate = useNavigate();

  const { setDisplayErrorNotification, setDisplaySuccessfulNotification } = useContext(NotificationContext);
  const {
    permissionsPerCompaniesHandler,
    personalInformationFormHandler,
    validateForm,
    selectedInternalUserPermissionsHandler,
    userActive,
    toggleUserActive,
  } = useContext(UserDetailsFormContext);

  const userEditMutation = useUserEditMutation({
    onMutate: () => navigate(paths.usersManagement),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.usersProfiles] });
      setDisplaySuccessfulNotification(t('usersManagement:userEditedSuccessfully'));
    },
    onError: () => setDisplayErrorNotification(t('errors:userEdit.requestFailed.notification.message.text')),
  });

  const onEditButtonClick = () => {
    if (!userId || !validateForm()) return;

    const creationRequestBody: CreateUserRequestBody = mapUserCreationFormToRequestBody(
      personalInformationFormHandler.personalInformation,
      permissionsPerCompaniesHandler.permissionsInCompanyHandler.permissionsInCompanies,
      selectedInternalUserPermissionsHandler.selectedPermissions,
    );

    const editRequestBody: EditUserRequestBody = {
      ...creationRequestBody,
      active: userActive ?? false,
    };

    userEditMutation.mutate({ body: editRequestBody, uuid: userId });
  };

  const toggleUserActiveState = useCallback(() => {
    if (!validateForm()) return;

    toggleUserActive(prev => prev !== undefined && !prev);
  }, [toggleUserActive, validateForm]);

  return {
    onEditButtonClick,
    toggleUserActiveState,
  };
};
