import { NonWrappingLinkLowerCased } from '@components/layout/styles';
import { PageNavigationHeaderProps } from '@components/PageNavigationHeader';
import { LinkProps, spacing, colors, ContentText } from '@fortum/elemental-ui-fork';
import { FC } from 'react';

const linkStyles: LinkProps = {
  ml: spacing.xxxxs,
  color: colors.snowWhite,
};

interface CreationErrorNotificationMessageProps {
  message: string;
  linkProps: PageNavigationHeaderProps['link'];
}

export const CreationErrorNotificationMessage: FC<CreationErrorNotificationMessageProps> = ({ message, linkProps }) => (
  <ContentText>
    {message}
    <NonWrappingLinkLowerCased {...linkStyles} id={linkProps.id} onClick={linkProps.onClick}>
      {linkProps.description}
    </NonWrappingLinkLowerCased>
  </ContentText>
);
