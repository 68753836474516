import styled from 'styled-components';
import { spacing } from '@fortum/elemental-ui-fork';

interface VisualizationWrapperProps {
  $height: string;
  $minHeight: string;
  $width: string;
  $minWidth: string;
  $maxWidth: string;
  $padding: string;
  $backgroundColor: string;
}

export const VisualizationWrapper = styled.div<VisualizationWrapperProps>`
  display: flex;
  border-radius: ${spacing.xxxs};
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  height: ${props => props.$height};
  min-height: ${props => props.$minHeight};

  width: ${props => props.$width};
  min-width: ${props => props.$minWidth};
  max-width: ${props => props.$maxWidth};

  padding: ${props => props.$padding};

  background-color: ${props => props.$backgroundColor};
`;

export const QlikHeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const FullWidthAndHeightContainer = styled.div`
  height: 100%;
  width: 100%;
`;
