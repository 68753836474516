import { QueryKeys } from '@common/query';
import { sessionKeys, StorageUtils } from '@common/storage';
import { queryClient } from '@config/queryClient';
import { UserPermission, userPermissions } from '@config/user';
import {
  UsersPermissions,
  userTypes,
  type BusinessPartner,
  type InternalAccess,
  type User,
  type UserAccess,
  type UserRole,
  type UserType,
} from '@models/user';
import { SelectCompanyItem } from '@routes/usersManagement/components/SelectCompanyPanel/SelectCompanyPanel';

export const SELECTED_BUSINESS_PARTNERS_LIMIT = 10;

export const bpsSelectionMissing = (userAccess: UserAccess | undefined) => {
  if (userAccess?.isInternal && userAccess?.selectedBusinessPartners.length === 0) {
    return true;
  }

  return false;
};

// TODO: consider this moving into another util bundle, but as for now it's mostly used with users operations, thus it could live here
export const getSelectedBusinessPartners = (): BusinessPartner[] =>
  StorageUtils.getObject<BusinessPartner[]>(sessionKeys.selectedBusinessPartners) || [];

export const isUserInternal = (userRole: UserRole | undefined) => userRole === 'INTERNAL';

export const isUserAccessOfInternal = (userAccess: UserAccess | undefined): userAccess is InternalAccess =>
  !!userAccess && userAccess.isInternal;

export const getUserAccessInfo = (): UserAccess => {
  const user = queryClient.getQueryData<User>([QueryKeys.userInfo]);

  if (!user) throw new Error('User not initialized');

  if (isUserInternal(user.userRole)) {
    return {
      isInternal: true,
      selectedBusinessPartners: getSelectedBusinessPartners(),
    };
  }

  return { isInternal: false };
};

export const bpsSelectionItemDisabled = (selectedBps: BusinessPartner[], itemId: string, itemName: string) =>
  selectedBps.length >= SELECTED_BUSINESS_PARTNERS_LIMIT &&
  !selectedBps.some(selectedBP => selectedBP.id === itemId && selectedBP.name === itemName);

export const companiesSelectionItemDisabled = (
  selectedCompanies: SelectCompanyItem[],
  itemId: string,
  itemName: string,
  limit: number,
) =>
  selectedCompanies.length >= limit &&
  !selectedCompanies.some(selectedCompany => selectedCompany.value === itemId && selectedCompany.name === itemName);

export const getUserName = (user: User | undefined) => (user ? [user.name, user.lastname].filter(Boolean).join(' ') : '');

export const hasPermission = (user: User, permission: UserPermission) => {
  const permissionsPerCompany = Object.values(user.permissionsPerCompany).flat();

  return user.permissions.includes(permission) || permissionsPerCompany.includes(permission);
};

export const getUserType = (userRole: UserRole): UserType => (isUserInternal(userRole) ? 'INTERNAL' : 'EXTERNAL');

export const isUserType = (userType: string): userType is UserType => userTypes.includes(userType as UserType);

export const isUserPermission = (permission: string): permission is UserPermission =>
  userPermissions.includes(permission as UserPermission);

export const getUserPermissionsPerUserRole = (
  userRole: UserRole,
  usersPermissions: UsersPermissions | undefined,
): { defaultPermissions: UserPermission[]; additionalPermissions: UserPermission[] } => {
  const userPermissions = usersPermissions?.find(permissionsConfig => permissionsConfig.roleName === userRole);

  return {
    defaultPermissions: userPermissions?.defaultPermissions ?? [],
    additionalPermissions: userPermissions?.additionalPermissions ?? [],
  };
};
