import { ContentText } from '@fortum/elemental-ui-fork';
import { ChangeEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { PaginationContainer, goToTextStyles, StyledInput, StyledElementalPagination } from './styles';
import { Stylable } from '@components/types';
import { useDebounceCallback } from 'usehooks-ts';
import { DEBOUNCE_TIME_MS, debounceOptions } from '@config/inputs';
import { isWithinRange } from '@utils/numbers';

export type PaginationSize = 'l' | 'm';

interface PaginationProps extends Stylable {
  activePage: number;
  totalPages: number;
  hidePagination: boolean;
  setActivePage: (page: number) => void;
  size?: PaginationSize;
}
//TODO: remove goToPage key from orders namespace

const MIN_PAGE_VALUE = 1;

export const Pagination: FC<PaginationProps> = ({
  activePage,
  totalPages,
  hidePagination,
  setActivePage,
  size = 'l',
  className,
}) => {
  const { t } = useTranslation<Namespace>('common');

  const changeActivePage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      isWithinRange(event.target.value, totalPages, MIN_PAGE_VALUE) && setActivePage(Number(event.target.value)),

    [totalPages],
  );

  const debouncedChangeActivePage = useDebounceCallback(changeActivePage, DEBOUNCE_TIME_MS, debounceOptions);

  return (
    <PaginationContainer className={className} $size={size}>
      {!hidePagination && (
        <>
          <StyledElementalPagination $size={size} active={activePage} pages={totalPages} onPageChange={setActivePage} />
          <ContentText {...goToTextStyles}>{t('goToPage')}</ContentText>
          <StyledInput $size={size} type="number" min={MIN_PAGE_VALUE} max={totalPages} onChange={debouncedChangeActivePage} />
        </>
      )}
    </PaginationContainer>
  );
};
