import { Box, ContentText, fontSizes } from '@fortum/elemental-ui-fork';
import { OrderDetails } from '@models/orders';
import { hasValue } from '@utils/dataOperations';
import { displayedValue } from '@utils/dataOperations';
import { compact, uniq } from 'lodash';
import { FC } from 'react';

interface InvoicingAddressProps {
  location: OrderDetails['invoicingDetails']['invoicingLocation'];
}

export const InvoicingAddress: FC<InvoicingAddressProps> = ({ location }) => {
  const locationComponents = uniq(
    compact([
      displayedValue(location.companyName),
      hasValue(location.specificName) ? location.specificName : undefined,
      hasValue(location.address) ? location.address : undefined,
      displayedValue(location.city),
      hasValue(location.invoicingEmail) ? location.invoicingEmail : undefined,
    ]),
  );

  return (
    <Box display="flex" flexDirection="column">
      {locationComponents.map((value, index) => (
        <ContentText key={index} fontSize={fontSizes.s}>
          {value}
        </ContentText>
      ))}
    </Box>
  );
};
