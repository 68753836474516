import { VerticalContainer } from '@components/styles';
import { spacing, ContentTextProps, fontSizes, colors, IconProps, LoaderProps } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';
import { ngColors } from '@utils/styleOverride';

export const PendingRequestViewContainer = styled(VerticalContainer)`
  flex: 1;
  padding: ${spacing.xs};
  align-items: center;
`;

export const headingStyle: ContentTextProps = {
  fontSize: fontSizes.xl.s,
  color: colors.emperorGrey,
};

export const iconStyles: IconProps = {
  size: 48,
  color: ngColors.orange,
  pb: spacing.xxxs,
};

export const MessageWrapper = styled.div`
  font-size: ${fontSizes.m};
  color: ${colors.silverGrey};
  max-width: '350px';
  text-align: 'center';
  padding-bottom: ${spacing.s};
`;

export const loaderStyles: LoaderProps = { size: 48 };
