import { FC, useCallback } from 'react';
import { SectionHeader } from '@components/index';
import { colors, ComponentOrElement, ContentText, IconCheck, IconCross, IconProps } from '@fortum/elemental-ui-fork';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { labelStyles, ListItemContainer, VerticalContainerWithSmallGap, VerticalContainerWithSmallGapAndPadding } from './styles';
import { UserPermission } from '@config/user';
import { Stylable, Testable } from '@components/types';
import { Placeholders } from '../Placeholders';
import { VerticalContainer } from '@components/styles';
import { ngColors } from '@utils/styleOverride';

interface PermissionsSectionProps extends Stylable, Testable {
  permissions: UserPermission[];
  disabledPermissions: UserPermission[];
  isLoading: boolean;
  headerIcon: ComponentOrElement<IconProps>;
  headerText: string;
}

export const PermissionsSection: FC<PermissionsSectionProps> = ({
  headerIcon,
  headerText,
  permissions,
  disabledPermissions,
  isLoading,
  className,
  'data-testid': dataTestId,
}) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  const displayBorderOnPermissionsListItem = useCallback(
    (index: number) => index < permissions.length - 1 || disabledPermissions.length > 0,
    [permissions.length, disabledPermissions.length],
  );

  const displayBorderOnDisabledPermissionsListItem = useCallback(
    (index: number) => index < disabledPermissions.length - 1,
    [disabledPermissions.length],
  );

  return (
    <VerticalContainerWithSmallGap className={className} data-testid={dataTestId}>
      <SectionHeader icon={headerIcon} text={headerText} size="s" />

      <VerticalContainerWithSmallGapAndPadding>
        {isLoading ? (
          <VerticalContainer>
            <Placeholders lines={2} />
          </VerticalContainer>
        ) : (
          <>
            {permissions.map((permission, index) => (
              <ListItemContainer key={permission} $displayBorder={displayBorderOnPermissionsListItem(index)}>
                <ContentText {...labelStyles}>{t(`permissions.${permission}`)}</ContentText>
                <IconCheck color={ngColors.orange} />
              </ListItemContainer>
            ))}
            {disabledPermissions.map((disabledPermission, index) => (
              <ListItemContainer key={disabledPermission} $displayBorder={displayBorderOnDisabledPermissionsListItem(index)}>
                <ContentText {...labelStyles}>{t(`permissions.${disabledPermission}`)}</ContentText>
                <IconCross color={colors.powerRed} />
              </ListItemContainer>
            ))}
          </>
        )}
      </VerticalContainerWithSmallGapAndPadding>
    </VerticalContainerWithSmallGap>
  );
};
