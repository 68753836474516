import { HorizontalContainer, VerticalContainer, elevatedBoxWithTopShadowStyles, staticBorderStyles } from '@components/styles';
import { Box, colors, ContentTextProps, fontSizes, IconButtonProps, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const ListContainer = styled(Box)`
  margin-bottom: ${spacing.xs};
  height: calc(100% - 120px);
`;

export const closeIconButtonStyles: Omit<IconButtonProps, 'icon'> = {
  status: 'plain',
  color: colors.inkGrey,
  top: '19px',
  right: spacing.xs,
  position: 'absolute',
};

export const ButtonsContainer = styled(HorizontalContainer)`
  padding: ${spacing.xs} ${spacing.s};
  justify-content: space-between;
`;
export const SelectedHeader = styled(HorizontalContainer)`
  justify-content: space-between;
  margin-bottom: ${spacing.xxs};
`;
export const SelectedContainer = styled(VerticalContainer)`
  ${staticBorderStyles()};
  padding: ${spacing.xxs};
  overflow-y: scroll;
  height: 100px;
`;

export const SelectedOutterContainer = styled.div`
  padding: ${spacing.xs};
  padding-bottom: 0;
  box-sizing: border-box;
`;

export const StickyContent = styled.div`
  ${elevatedBoxWithTopShadowStyles()}
`;

export const ContentTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};
