import { verticalContainer } from '@components/styles';
import { ContentTextProps, Link, colors, fontSizes, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const Container = styled.div`
  ${verticalContainer};
  flex: 1;
  align-items: center;

  padding: ${spacing.xxs} ${spacing.xxs} 48px;
`;

export const NonWrappingLinkLowerCased = styled(Link)`
  white-space: nowrap;
  text-transform: lowercase;

  font-size: ${fontSizes.s.s};
  margin: 0 ${spacing.xxxxs};
`;

export const headerTextStyles: ContentTextProps = {
  fontSize: '23px',
  color: colors.emperorGrey,
  textAlign: 'center',
};

export const messageTextStyles: ContentTextProps = {
  textAlign: 'center',
  fontSize: fontSizes.s,
  color: colors.silverGrey,
  pb: spacing.xxs,
};
