import { RadioButtonItemProps } from '@components/HorizontalRadioButtonGroup';
import { equipmentServiceTypesToOrderTypesMap } from '@config/order';
import { OrderCreationTransactionId, OrdersCreationResponse, OrderType, orderTypes } from '@models/orders';
import { isEquipmentServiceType } from '@utils/contract';
import { NO_DATA_BADGE } from '@utils/dataOperations';
import { t } from 'i18next';
import { uniq, flatten } from 'lodash';
import { RefObject } from 'react';
import { FormValues, BasicFormValues, CreationForms, FormRequiredFields, OrderSummaryRowCSV } from 'src/types/ordersCreation';
import { Headers } from 'react-csv/lib/core';

export * from './forms';
export * from './requests';

export const mapOrderTypesToRadioItems = (
  orderTypes: OrderType[],
  formIdentifier: string,
  orderTileRef: RefObject<HTMLDivElement>,
): RadioButtonItemProps[] => {
  const containerElement = orderTileRef.current ?? undefined;

  return orderTypes.map(orderType => {
    const tooltipProps: RadioButtonItemProps['tooltipProps'] = {
      content: [t(`orderCreation:form.orderType.tooltip.${orderType}`)],
      containerElement,
    };

    return {
      label: t(`orderCreation:form.orderType.${orderType}`),
      value: orderType,
      id: `${orderType}-${formIdentifier}`,
      tooltipProps,
    };
  });
};

export const mapRawEquipmentServiceTypesToOrderTypes = (rawEquipmentServiceTypes: string[]) =>
  uniq(
    flatten(
      rawEquipmentServiceTypes
        .filter(isEquipmentServiceType)
        .map(eqServiceType => equipmentServiceTypesToOrderTypesMap[eqServiceType]),
    ),
  );

export const isOrderType = (value: string): value is OrderType => orderTypes.includes(value as OrderType);

/**
 * formValues[field] as string casting is necessary, because transpiler is not satisfied with typeof formValues[field] === 'string' type checking
 * It still treats formValues[field] as string | number
 */
export const getMissingRequiredFields = (formValues: FormValues, requiredFields: (keyof BasicFormValues)[]) =>
  requiredFields.filter(field =>
    typeof formValues[field] === 'string' ? !(formValues[field] as string).trim() : !formValues[field],
  );

export const countAllMissingRequiredFields = (creationForms: CreationForms, requiredFields: FormRequiredFields) =>
  creationForms
    .flatMap(creationForm =>
      creationForm.forms.map(
        orderForm => getMissingRequiredFields(orderForm.values, requiredFields[orderForm.values.orderType]).length,
      ),
    )
    .reduce((prev, current) => prev + current, 0);

export const isContractsFetchingEnabled = (
  selectedSites: string[],
  selectedWasteTypes: string[],
  selectedEquipmentTypes: string[],
  search: string,
) => selectedSites.length > 0 || search.trim().length > 0 || selectedWasteTypes.length > 0 || selectedEquipmentTypes.length > 0;

export const createServiceTileId = (contractIdentifier: string) => `service-tile-${contractIdentifier}`;

export const mapCreationFormsToTransactionIds = (creationForms: CreationForms): OrderCreationTransactionId[] =>
  creationForms.flatMap(creationForm => creationForm.forms.map(form => ({ uuid: form.formIdentifier })));

export const shouldDisplayErrorSummaryHeader = ({ failedOrders, forbiddenOrders, successOrders }: OrdersCreationResponse) =>
  forbiddenOrders.length > 0 && failedOrders.length === 0 && successOrders.length === 0;

export const quantityColumnSortComparatorAsc = (firstQuantity: number | string, secondQuantity: number | string) => {
  if (typeof firstQuantity === 'string' && typeof secondQuantity === 'string') {
    return firstQuantity === NO_DATA_BADGE ? -1 : 1;
  }

  if (typeof firstQuantity === 'string') return -1;
  if (typeof secondQuantity === 'string') return 1;

  return firstQuantity - secondQuantity;
};

export const convertOrderSummaryToCSVFormat = (creationForms: CreationForms) => {
  const headers: Headers = [
    { label: t('wasteDetails:company'), key: 'company' },
    { label: t('wasteDetails:wasteDescription'), key: 'wasteDescription' },
    { label: t('wasteDetails:equipmentType'), key: 'equipmentType' },
    { label: t('orderCreation:summaryPage.table.estimatedQty'), key: 'estimatedQty' },
    { label: t('orderCreation:summaryPage.table.estimatedWasteQty'), key: 'estimatedWasteQty' },
    { label: t('orderDetails:orderTypeFieldLabel'), key: 'orderTypeFieldLabel' },
    { label: t('wasteDetails:contractNo'), key: 'contractNo' },
    { label: t('orderCreation:summaryPage.table.additionalComment'), key: 'additionalComment' },
  ];

  const data: OrderSummaryRowCSV[] = creationForms.flatMap(creationForm =>
    creationForm.forms.map(orderForm => ({
      company: creationForm.serviceSummary?.company.customer,
      wasteDescription: creationForm.serviceSummary?.wasteDescription,
      equipmentType: creationForm.serviceSummary?.equipmentDescription,
      estimatedQty: orderForm.values.equipmentQuantity,
      estimatedWasteQty: orderForm.values.estimatedWasteQuantity,
      orderTypeFieldLabel: t(`orderCreation:form.orderType.${orderForm.values.orderType}`),
      contractNo: creationForm.contractNo,
      additionalComment: orderForm.values.comments,
    })),
  );

  return {
    headers,
    data,
  };
};
