import { UserPermission } from '@config/user';
import { UserRole } from '@models/user';
import { CreateUserRequestBody, DetailedUserProfile, UserPermissionsInCompany } from '@models/usersManagement';
import { fill } from 'lodash';
import { PermissionsInCompanyForm, UserPersonalInformation } from 'src/types/usersManagement';
import { getUserType } from './user';
import { v4 as uuidV4 } from 'uuid';
import { hasValue } from './dataOperations';

export const COMPANY_IDENTIFIER_DELIMITER = '-';

export const getEmptyCompaniesData = (dataAmount: number): UserPermissionsInCompany[] =>
  fill(Array(dataAmount), {
    additionalPermissions: [],
    disabledAdditionalPermissions: [],
  }) as unknown as UserPermissionsInCompany[];

export const createCompanyIdentifier = (code: string, bpName: string, delimiter = COMPANY_IDENTIFIER_DELIMITER) =>
  [code, bpName].join(delimiter);

export const destructureCompanyIdentifier = (
  identifier: string,
  delimiter = COMPANY_IDENTIFIER_DELIMITER,
): { code: string; bpName: string } => {
  const identifierElements = identifier.split(delimiter);
  return { code: identifierElements[0] ?? '', bpName: identifierElements.splice(1).join(' ') ?? '' };
};

export const updateFormsWithSelectedCompany = (
  forms: PermissionsInCompanyForm[],
  formId: string,
  selectedCompanyIdentifier: string,
) => forms.map(item => (item.formId === formId ? { ...item, companyIdentifier: selectedCompanyIdentifier } : item));

export const mapUserCreationFormToRequestBody = (
  personalInformation: UserPersonalInformation,
  permissionsInCompanies: PermissionsInCompanyForm[],
  internalUserPermissions: UserPermission[],
): CreateUserRequestBody => {
  const userRole: UserRole = personalInformation.userType === 'EXTERNAL' ? 'CUSTOMER_BASIC' : 'INTERNAL';

  const mappedExternalPermissions: CreateUserRequestBody['companies'] =
    personalInformation.userType === 'EXTERNAL'
      ? permissionsInCompanies.map(company => {
          const { code: businessPartnerCode, bpName: businessPartnerName } = destructureCompanyIdentifier(
            company.companyIdentifier,
          );

          return {
            businessPartnerCode,
            businessPartnerName,
            permissions: company.additionalPermissions,
          };
        })
      : null;

  return {
    name: personalInformation.name,
    lastName: personalInformation.lastName,
    email: personalInformation.email,
    phone: personalInformation.phone,
    language: personalInformation.language.toUpperCase(),
    userRole,
    permissions: personalInformation.userType === 'INTERNAL' ? internalUserPermissions : null,
    companies: mappedExternalPermissions,
  };
};

interface UserDetailsFormValues {
  personalInformation?: UserPersonalInformation;
  internalUserPermissions?: UserPermission[];
  externalUserPermissions?: PermissionsInCompanyForm[];
  isUserActive?: boolean;
}

const mapPermissionsInCompanyToFormStructure = (companies: UserPermissionsInCompany[]): PermissionsInCompanyForm[] =>
  companies.map(company => ({
    formId: uuidV4(),
    companyIdentifier: createCompanyIdentifier(company.companyCode, company.companyName),
    additionalPermissions: [], //TODO: implement additional permissions for external user
  }));

export const parseDetailedUserProfile = (user?: DetailedUserProfile): UserDetailsFormValues => {
  if (!user) {
    return {};
  }

  const userType = getUserType(user.userRole);

  const personalInformation: UserPersonalInformation = {
    name: user.name,
    lastName: user.lastName,
    email: user.email,
    phone: hasValue(user.phone) ? user.phone : '',
    language: user.language.toLowerCase(),
    userType: userType,
  };

  const internalUserPermissions = userType === 'INTERNAL' ? user.additionalPermissions : undefined;

  const externalUserPermissions = userType === 'EXTERNAL' ? mapPermissionsInCompanyToFormStructure(user.companies) : undefined;

  return {
    personalInformation,
    internalUserPermissions,
    externalUserPermissions,
    isUserActive: user.status === 'ACTIVE',
  };
};

export const regexValidation = (field: string, value: string) => {
  if (field === 'email') {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailRegex.test(value);
  }
  if (field === 'phone') {
    const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return phoneRegex.test(value);
  }
};

export const getMissingRequiredFields = (personalInformation: UserPersonalInformation, requiredFields: string[]) =>
  requiredFields.filter(field => !personalInformation[field as keyof UserPersonalInformation]);

export const getIncorrectFormatFields = (personalInformation: UserPersonalInformation, fieldsWithRequiredFormat: string[]) =>
  fieldsWithRequiredFormat.filter(field => {
    const value = personalInformation[field as keyof UserPersonalInformation];
    // Filter out empty values as they as not considered "incorrect"
    if (!value) return false;
    return !regexValidation(field, value);
  });
