import { Box, ContentText, spacing } from '@fortum/elemental-ui-fork';
import { FC, ReactNode } from 'react';
import { Container, headerTextStyles } from './styles';
import { Stylable, Testable } from '@components/types';

interface TileProps extends Testable, Stylable {
  header: string;
  children: ReactNode;
}

export const Tile: FC<TileProps> = ({ header, children, className, 'data-testid': testId }) => (
  <Container className={className} data-testid={testId}>
    <ContentText {...headerTextStyles}>{header}</ContentText>

    <Box display="flex" flexDirection="column" ph={spacing.xs} pv={spacing.xxs}>
      {children}
    </Box>
  </Container>
);
