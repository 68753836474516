import { PageNavigationHeader, PageNavigationHeaderProps } from '@components/index';
import { FailedToLoad } from '@components/layout/FailedToLoad';
import { CenteredLoader } from '@components/layout/styles';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { useOrderDetails } from '@data/hooks/useOrderDetails';
import { Box, Col, Grid, Row, spacing } from '@fortum/elemental-ui-fork';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderDetailsTile } from './components';
import { DeliveryOrderDetails } from './components/DeliveryOrderDetails';
import { ReceivingCollectionOrderDetails } from './components/ReceivingCollectionOrderDetails';
import { containerProps, GRID_GAP, gridLayoutProps } from './style';

export const OrderDetails: FC = () => {
  const { t } = useTranslation<Namespace[]>(['orderDetails', 'mainLayout']);
  const { orderNo } = useParams();
  const navigate = useNavigate();

  const { data: order, isLoading, fetchStatus, error } = useOrderDetails(orderNo || '');

  if (isLoading || fetchStatus !== 'idle') {
    return <CenteredLoader />;
  } else if (error || !order) {
    return <FailedToLoad />;
  }

  const headerLinkProps: PageNavigationHeaderProps['link'] = {
    id: 'navigate-to-orders-link',
    description: t('mainLayout:orders'),
    onClick: () => navigate(paths.ordersTracking),
  };

  return (
    <Box {...containerProps}>
      <Grid {...gridLayoutProps}>
        <Row mb={spacing.xxs}>
          <Col xs={12}>
            <PageNavigationHeader link={headerLinkProps} title={t('orderDetails:pageTitle')} />
          </Col>
        </Row>

        <Row mb={GRID_GAP}>
          <Col xs={12}>
            <OrderDetailsTile generalInfo={order.generalInfo} />
          </Col>
        </Row>

        {order.wasteDetails ? <ReceivingCollectionOrderDetails order={order} /> : <DeliveryOrderDetails order={order} />}
      </Grid>
    </Box>
  );
};
