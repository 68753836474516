import { createContext, Dispatch, SetStateAction } from 'react';

import { SortByValue } from './config';
import { OrdersFiltersHandlerReturnType } from './useOrdersFiltersHandler';

export type OrdersFiltersContextProps = OrdersFiltersHandlerReturnType & {
  sortBy: SortByValue;
  setSortBy: Dispatch<SetStateAction<SortByValue>>;
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
};

export const OrdersFiltersContext = createContext<OrdersFiltersContextProps | null>(null);
