import { FC, ReactNode } from 'react';
import { useOrdersCreation } from './useOrdersCreation';
import { OrdersCreationContext, OrdersCreationContextProps } from './OrdersCreationContext';

interface OrdersCreationContextProviderProps {
  children: ReactNode;
}

export const OrdersCreationContextProvider: FC<OrdersCreationContextProviderProps> = ({ children }) => {
  const creationHandler = useOrdersCreation();

  const contextValue: OrdersCreationContextProps = {
    ...creationHandler,
  };

  return <OrdersCreationContext.Provider value={contextValue}>{children}</OrdersCreationContext.Provider>;
};
