import { Box, colors, IconChevronDown, IconChevronUp, IconProps, spacing } from '@fortum/elemental-ui-fork';
import { KeyboardEvent, memo, useCallback, useMemo, useState } from 'react';
import { City } from '@models/service';
import { CompanyLayout } from '@routes/services/components/location/CompanyLayout';
import { StyledAnchor, StyledContentTextBold } from '@routes/services/components/location/styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '@config/routes';
import { findCompanyByLocationCode } from '@utils/services';
import { KEY_ENTER } from '@common/consts';

interface Props {
  id: string;
  city: City;
}

const chevronIconProps: IconProps = {
  color: colors.inkGrey,
  pr: spacing.xxxxs,
  size: 20,
};

export const CityLayout = memo(({ id, city }: Props) => {
  const { t } = useTranslation<Namespace>('services');
  const [searchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState<boolean>(() => {
    const locationCodeFromSearchParams = searchParams.get(SEARCH_PARAMS.locationCode);

    return !!locationCodeFromSearchParams && !!findCompanyByLocationCode(city.companies, locationCodeFromSearchParams);
  });

  const toggleIsOpen = useCallback(() => setIsOpen(prevState => !prevState), []);

  const companyLayouts = useMemo(
    () =>
      city.companies.map((company, index) => {
        const key = `${city.name}-${company.name}`;

        return <CompanyLayout id={company.name} city={city.name} key={`${key}-${index}`} company={company} />;
      }),
    [city.companies],
  );

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.code === KEY_ENTER) {
      e.preventDefault();
      toggleIsOpen();
    }
  };

  return (
    <Box id={id} display="flex" flexDirection="column" backgroundColor={colors.snowWhite}>
      <StyledAnchor tabIndex={0} onClick={toggleIsOpen} onKeyDown={handleKeyDown}>
        <Box display="flex" flex="1" alignItems="center">
          <StyledContentTextBold color={colors.inkGrey}>{city.name}</StyledContentTextBold>
        </Box>

        {isOpen ? (
          <IconChevronUp {...chevronIconProps} aria-label={t('findLocation')} />
        ) : (
          <IconChevronDown {...chevronIconProps} aria-label={t('findLocation')} />
        )}
      </StyledAnchor>

      <Box display="flex" flexDirection="column" gap={spacing.xxs}>
        {isOpen && companyLayouts}
      </Box>
    </Box>
  );
});

CityLayout.displayName = 'CityLayout';
