import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { namespaces } from './i18n.namespaces';
import { fallbackLang, isSupportedLanguage, languages } from '@common/languages';
import resourcesToBackend from 'i18next-resources-to-backend';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    returnNull: false,
    fallbackLng: fallbackLang,
    debug: import.meta.env.NODE_ENV === 'development',
    supportedLngs: languages,
    defaultNS: 'common',
    ns: namespaces,
    nsSeparator: ':',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      backends: [
        LocizeBackend,
        resourcesToBackend((lng: string, ns: string) => import(`../../../public/locales/${lng}/${ns}.json`)),
      ],
      backendOptions: [
        {
          projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
          version: import.meta.env.VITE_LOCIZE_VERSION,
          referenceLng: fallbackLang,
        },
      ],
    },
  });

i18n.on('initialized', () => {
  const newLanguage = isSupportedLanguage(i18n.language) ? i18n.language : fallbackLang;

  document.documentElement.setAttribute('lang', newLanguage);
});

export const onLanguageChange = async (lang: string) => {
  const newLanguage = isSupportedLanguage(lang) ? lang : fallbackLang;

  document.documentElement.setAttribute('lang', newLanguage);

  await i18n.changeLanguage(newLanguage);
};

export default i18n;
