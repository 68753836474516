import { Multiselect } from '@components/Multiselect';
import { baseLineBreakItem, horizontalContainer, verticalContainer } from '@components/styles';
import { Button, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';
import { ngColors } from '@utils/styleOverride';

export const ContactFormContainer = styled.div`
  ${verticalContainer};
  flex: 1;
  align-items: center;
  padding: 0 12px;
  max-width: 448px;
`;

export const Form = styled.div`
  ${verticalContainer};
  margin: ${spacing.xs} 0;

  width: 100%;

  row-gap: ${spacing.xxs};
`;

export const SitesInputContainer = styled.div`
  ${verticalContainer};
  row-gap: ${spacing.xxxs};
`;

export const SubmitContainer = styled.div`
  ${horizontalContainer};
  align-items: center;
  column-gap: ${spacing.xxs};
`;

export const SubmitButton = styled(Button)`
  white-space: nowrap;
  align-self: center;
  color: ${ngColors.white};

  &:hover {
    color: ${ngColors.white};
  }
`;

export const StyledMultiselect: typeof Multiselect = styled(Multiselect)`
  ${baseLineBreakItem}
`;
