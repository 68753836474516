import { RefreshIssuer, TokenRefreshUtils } from '@common/auth';
import { CustomHttpHeaders, HttpError, isHttpError, isUnauthorizedError } from '@common/http';
import { QueryKeys } from '@common/query';
import { StorageUtils } from '@common/storage';
import { queryClient } from '@config/queryClient';
import { paths } from '@config/routes';
import { User } from '@models/user';
import { getEnvProperty } from '@utils/general';
import { compact } from 'lodash';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';

export const onRequestError = (err: Error, issuer: RefreshIssuer) => {
  if (
    (!issuer.includes(QueryKeys.qlikAuth) && !isUnauthorizedError(err)) ||
    !TokenRefreshUtils.validateTokenRefreshAttempts(issuer).refreshAvailable
  )
    return;

  sessionStorage.removeItem(StorageUtils.sessionKeys.accessToken);
  sessionStorage.removeItem(StorageUtils.sessionKeys.idToken);

  if (window.location.pathname !== paths.auth) {
    window.location.reload();
    return;
  }

  const redirectPath = StorageUtils.popItem(StorageUtils.sessionKeys.redirectUri) ?? paths.main;

  window.location.replace(redirectPath);
};

export const RETRY_AMOUNT_LIMIT = 3;

export const shouldRetry = (failureCount: number, error: Error, retryAmountLimit: number = RETRY_AMOUNT_LIMIT) =>
  isHttpError(error) && error.getStatusCode() >= 500 && failureCount < retryAmountLimit;

export const getAuthHeaders = (): Headers => {
  const token = sessionStorage.getItem(StorageUtils.sessionKeys.accessToken);
  if (!token) throw Error('Token not found');

  const userId = queryClient.getQueryData<User>([QueryKeys.userInfo])?.uuid;

  return new Headers(
    compact([
      [CustomHttpHeaders.AUTH_TOKEN, token],
      userId ? [CustomHttpHeaders.USER_ID, userId] : undefined,
      [CustomHttpHeaders.OCP_APIM_SUBSCRIPTION_KEY, `${getEnvProperty('VITE_OCP_APIM_SUBSCRIPTION_KEY')}`],
    ]),
  );
};
export const appendRequestParams = (url: string, params: Map<string, string | number>) => {
  if (params.size === 0) {
    return url;
  }

  let urlWithParams = `${url}?`;

  params.forEach((value, key) => {
    urlWithParams += `${key}=${value}&`;
  });

  return urlWithParams.substring(0, urlWithParams.length - 1);
};
export const getData = async <T>(URL: string, additionalHeaders?: Map<string, string>): Promise<T> => {
  const headers = getAuthHeaders();

  additionalHeaders?.forEach((value: string, key: string) => {
    headers.append(key, value);
  });

  const response = await fetch(URL, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  });

  if (response.ok) {
    return response.json();
  }
  throw new HttpError(response.status, response.statusText);
};
export const postData = async <T>(URL: string, body?: string, additionalHeaders?: Map<string, string>): Promise<T> => {
  const headers = getAuthHeaders();

  additionalHeaders?.forEach((value: string, key: string) => {
    headers.append(key, value);
  });

  const response = await fetch(URL, {
    method: 'POST',
    mode: 'cors',
    headers,
    body,
  });

  if (response.ok) {
    return response.json();
  }
  throw new HttpError(response.status, response.statusText);
};

export const deleteData = async <T extends unknown | void>(url: string, additionalHeaders?: Map<string, string>): Promise<T> => {
  const headers = getAuthHeaders();

  headers.append(HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON);

  additionalHeaders?.forEach((value: string, key: string) => {
    headers.append(key, value);
  });

  const response = await fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    headers,
  });

  if (response.ok) {
    return response.json();
  }

  throw new HttpError(response.status, response.statusText);
};
