import { StyledDescriptionListItem, VerticalContainer } from '@components/styles';
import { FC } from 'react';
import { IconProfileOutline } from '@fortum/elemental-ui-fork';
import { DetailedUserProfile, UserProfile } from '@models/usersManagement';
import { DescriptionListContainer, MediumDescriptionListItem, Status, StyledDescriptionList } from './styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { translate } from '@utils/internationalization';
import { getUserType } from '@utils/user';
import { PlaceholderWrapper, SectionHeader } from '@components/index';
import { NO_DATA_BADGE } from '@utils/dataOperations';

interface MainSectionProps {
  user: DetailedUserProfile | undefined;
  prefetchedUser: UserProfile;
  isLoading: boolean;
  isError: boolean;
}

export const MainSection: FC<MainSectionProps> = ({ user, isLoading, prefetchedUser, isError }) => {
  const { t } = useTranslation<Namespace[]>(['usersManagement', 'common']);

  const translatedUserType = translate(getUserType(prefetchedUser.userRole), 'usersManagement', 'userType');

  const displayNoDataBadge = isError || !user;

  return (
    <VerticalContainer>
      <SectionHeader icon={<IconProfileOutline />} text={t('usersManagement:detailsPanel.mainSection.header')} size="m" />

      <DescriptionListContainer>
        <StyledDescriptionList size="condensed">
          <MediumDescriptionListItem
            label={t('usersManagement:detailsPanel.mainSection.name.label')}
            data-testid="main-section-name-item"
          >
            {`${prefetchedUser.name} ${prefetchedUser.lastName}`}
          </MediumDescriptionListItem>

          <StyledDescriptionListItem
            label={t('usersManagement:detailsPanel.mainSection.email.label')}
            data-testid="main-section-email-item"
          >
            {prefetchedUser.email}
          </StyledDescriptionListItem>

          <StyledDescriptionListItem
            label={t('usersManagement:detailsPanel.mainSection.phone.label')}
            data-testid="main-section-phone-item"
          >
            {prefetchedUser.phone}
          </StyledDescriptionListItem>

          <StyledDescriptionListItem
            label={t('usersManagement:detailsPanel.mainSection.userType.label')}
            data-testid="main-section-user-type-item"
          >
            {translatedUserType}
          </StyledDescriptionListItem>

          <StyledDescriptionListItem
            label={t('usersManagement:detailsPanel.mainSection.language.label')}
            data-testid="main-section-language-item"
          >
            <PlaceholderWrapper displayPlaceholder={isLoading}>
              {displayNoDataBadge ? NO_DATA_BADGE : t(`common:languages.${user.language}`)}
            </PlaceholderWrapper>
          </StyledDescriptionListItem>

          <StyledDescriptionListItem
            label={t('usersManagement:detailsPanel.mainSection.status.label')}
            data-testid="main-section-status-item"
          >
            <PlaceholderWrapper displayPlaceholder={isLoading}>
              {displayNoDataBadge ? (
                NO_DATA_BADGE
              ) : (
                <Status $status={user.status}>{translate(user.status, 'usersManagement', 'status')}</Status>
              )}
            </PlaceholderWrapper>
          </StyledDescriptionListItem>
        </StyledDescriptionList>
      </DescriptionListContainer>
    </VerticalContainer>
  );
};
