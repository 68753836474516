import {
  baseLineBreakItem,
  borderTypeToColorMap,
  horizontalContainer,
  staticBorderStyles,
  verticalContainer,
} from '@components/styles';
import { ButtonProps, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';
import { BusinessPartnerForm } from './BusinessPartnerForm';
import { Select } from '@components/Select';
import { ngColors } from '@utils/styleOverride';
interface BusinessPartnerTile_Props {
  $tileHasError: boolean;
  $disabled: boolean;
}

export const BusinessPartnerTile = styled.div<BusinessPartnerTile_Props>`
  ${verticalContainer};
  ${staticBorderStyles('2px')};

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'auto')};

  padding: ${spacing.xs};
  gap: ${spacing.xs};

  &:hover,
  &:focus-within {
    border-color: ${props =>
      props.$disabled
        ? borderTypeToColorMap['inactive']
        : props.$tileHasError
          ? borderTypeToColorMap['error']
          : borderTypeToColorMap['active']};
  }

  border-color: ${props =>
    props.$disabled ? borderTypeToColorMap['inactive'] : props.$tileHasError && borderTypeToColorMap['error']};

  transition: border-color 300ms;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
`;

export const BusinessPartnerFormWithMarginBottom = styled(BusinessPartnerForm)`
  margin-bottom: ${spacing.xxxs};
`;

export const buttonStyles: ButtonProps = {
  mt: spacing.xxxs,
  alignSelf: 'flex-start',
  status: 'primary',
  variant: 'condensed',
  textColor: ngColors.white,
};

export const MultiselectStyleLineBreak = styled(Select)`
  ${baseLineBreakItem}
`;

export const CompanyItemContainer = styled.div`
  ${horizontalContainer};
  gap: ${spacing.xs};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
