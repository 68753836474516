import {
  HorizontalContainer,
  StyledDescriptionListItem,
  VerticalContainer,
  elevatedBoxWithTopShadowStyles,
  staticBorderStyles,
} from '@components/styles';
import {
  BoxProps,
  ButtonProps,
  colors,
  ContentTextProps,
  DescriptionList,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@fortum/elemental-ui-fork';
import { UserProfileStatus } from '@models/usersManagement';
import styled, { css } from 'styled-components';
import { FailedToLoadDataNote, PermissionsSection } from '@components/index';
import { ngColors } from '@utils/styleOverride';

export const HeaderContainer = styled(HorizontalContainer)`
  justify-content: space-between;
  align-items: center;
`;

export const MediumDescriptionListItem = styled(StyledDescriptionListItem)`
  dd {
    font-size: ${fontSizes.m.s};
    font-weight: ${fontWeights.medium};
  }
`;

export const StyledDescriptionList = styled(DescriptionList)`
  div > dd,
  div > dt {
    padding-top: ${spacing.xxxxs} !important;
    padding-bottom: ${spacing.xxxxs} !important;
  }
`;

export const DescriptionListContainer = styled.div`
  padding: ${spacing.xs} ${spacing.s} 0;
`;

export const labelStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};

export const smallPaddingBottomBoxProps: BoxProps = {
  padding: `${spacing.xxxs} ${spacing.s} ${spacing.xxs}`,
};

export const ButtonsContainer = styled(HorizontalContainer)`
  padding: ${spacing.xs} ${spacing.s};
  justify-content: space-between;

  ${elevatedBoxWithTopShadowStyles};
`;

export const closeButtonStyles: ButtonProps = {
  minWidth: '40%',
  marginLeft: 'auto',
  textColor: ngColors.white,
};

export const PermissionsContainer = styled(VerticalContainer)`
  padding-left: ${spacing.s};
`;

export const ShiftedAdditionalPermissionsSection = styled(PermissionsSection)`
  padding-left: ${spacing.s};
`;

export const Status = styled.p<{ $status: UserProfileStatus }>`
  ${staticBorderStyles('0px')};
  ${({ $status }) =>
    $status === 'ACTIVE'
      ? css`
          color: ${ngColors.orange};
          background-color: rgba(15, 122, 90, 0.1);
        `
      : $status === 'PENDING'
        ? css`
            color: ${colors.fineOrange};
            background-color: rgba(214, 146, 28, 0.1);
          `
        : css`
            color: ${colors.punchRed};
            background-color: rgba(184, 62, 77, 0.1);
          `};

  line-height: ${lineHeights.normal};
  padding: 0 ${spacing.xs};
  width: fit-content;
`;

export const UserRoleContainer = styled(HorizontalContainer)`
  gap: ${spacing.xs};
`;

export const UserRole = styled.p`
  ${staticBorderStyles('0px')};
  color: ${colors.sonicGrey};
  background-color: ${colors.cloudGrey};

  text-align: center;
  line-height: ${lineHeights.normal};
  padding: 0 ${spacing.xs};
`;

export const StyledErrorView = styled(FailedToLoadDataNote)`
  margin-top: 48px;
  margin-bottom: ${spacing.xs};
`;
