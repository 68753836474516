import { BoxPropValue, BoxProps, ColProps, colors, spacing } from '@fortum/elemental-ui-fork';
import { LIMITED_LAYOUT_MAX_WIDTH_PX } from '@components/layout/styles';

export const GRID_GAP = spacing.xs;

const gridMarginHorizontal: BoxPropValue = {
  xs: spacing.xxs,
  m: spacing.xs,
  xxl: spacing.xs,
};

const gridMaxWidth: BoxPropValue = {
  xxl: `calc(${LIMITED_LAYOUT_MAX_WIDTH_PX} - var(--app-navigation-width) - 2 * ${gridMarginHorizontal.xxl})`,
  m: `calc(100% - 2 * ${gridMarginHorizontal.m})`,
  xs: `calc(100% - 2 * ${gridMarginHorizontal.xs})`,
};

const gridWidth: BoxPropValue = {
  xxl: `calc(100% - 2 * ${gridMarginHorizontal.xxl})`,
};

export const gridLayoutProps: BoxProps = {
  marginHorizontal: gridMarginHorizontal,
  maxWidth: gridMaxWidth,
  width: gridWidth,
  paddingHorizontal: { xs: 0 },
  mb: spacing.s,
};

export const containerProps: BoxProps = {
  backgroundColor: colors.snowWhite,
  height: '100%',
};

export const columnInnerContainerProps: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: GRID_GAP,
};

export const columnInWrappableRowProps: ColProps = {
  mb: { xs: GRID_GAP, xl: 0 },
};
