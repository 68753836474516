import { Box, Button, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const ErrorContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing.m};
`;

export const StyledClearButton = styled(Button)`
  &:active,
  &:hover {
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;
  }
`;
