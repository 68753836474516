import { ChangeEvent, FC, memo } from 'react';
import { ItemContainer } from './styles';
import { Checkbox, CheckboxProps, ContentText, fontSizes } from '@fortum/elemental-ui-fork';
import { UserPermission } from '@config/user';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

export interface PermissionItemProps {
  permission: UserPermission;
  checked: CheckboxProps['checked'];
  onCheckboxClick: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const propsAreEqual = (prevProps: PermissionItemProps, nextProps: PermissionItemProps) =>
  prevProps.permission === nextProps.permission &&
  prevProps.checked === nextProps.checked &&
  prevProps.disabled === nextProps.disabled;

export const PermissionItem: FC<PermissionItemProps> = memo(({ permission, checked, onCheckboxClick, disabled = false }) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  return (
    <ItemContainer>
      <ContentText fontSize={fontSizes.s}>{t(`permissions.${permission}`)}</ContentText>
      <Checkbox name={permission} checked={checked} disabled={disabled} onChange={onCheckboxClick} />
    </ItemContainer>
  );
}, propsAreEqual);

PermissionItem.displayName = 'PermissionItem';
