import { FC, useCallback, useEffect, useState } from 'react';
import { SelectionItem, SelectionsWrapper } from './styles';
import { ContentText, ErrorMessage, IconButton, IconCross, colors, spacing } from '@fortum/elemental-ui-fork';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { compact } from 'lodash';
import { Stylable } from '@components/types';

export interface CurrentSelectionsProps extends Stylable {
  selectionsLimit?: number;
  maxHeight?: string;
  minHeight?: string;
  margin?: string | null;
  qSelections: enigmaJS.QSelection[];
  clearSelection: (field: string) => void;
  error?: boolean;
  errorMessage?: string;
}

interface Selection {
  field: string;
  selected: string[];
  total: number;
}

export const CurrentSelections: FC<CurrentSelectionsProps> = ({
  selectionsLimit = 3,
  maxHeight,
  minHeight,
  margin = null,
  qSelections,
  clearSelection,
  error,
  errorMessage,
  className,
}) => {
  const { t, i18n } = useTranslation<Namespace>('reports');

  const [selections, setSelections] = useState<Selection[]>([]);

  const getSelectedDescriptions = useCallback(
    (selectedField: enigmaJS.QSelection, selectionsLimit: number): string[] => {
      if (selectedField.qSelectedCount === 0) {
        return [];
      }
      if (selectedField.qSelectedCount >= 1 && selectedField.qSelectedCount <= selectionsLimit) {
        return selectedField.qSelectedFieldSelectionInfo.map(selectionInfo => selectionInfo.qName);
      }

      return [
        t('selectionsManagement.selectionsSummary', {
          selected: selectedField.qSelectedCount,
          total: selectedField.qTotal,
        }),
      ];
    },
    [i18n.language],
  );

  const mapQSelections = useCallback(
    (qSelections: enigmaJS.QSelection[]): Selection[] =>
      compact(
        qSelections.map(qSelection => {
          if (qSelection.qSelectedCount <= 0) {
            return null;
          }

          return {
            field: qSelection.qField,
            selected: getSelectedDescriptions(qSelection, selectionsLimit),
            total: qSelection.qTotal,
          };
        }),
      ),
    [selectionsLimit, i18n.language],
  );

  useEffect(() => {
    setSelections(mapQSelections(qSelections));
  }, [qSelections]);

  const renderContent = () => {
    if (error) {
      return <ErrorMessage active>{errorMessage}</ErrorMessage>;
    }

    if (selections && selections.length >= 1) {
      return selections.map(selection => (
        <SelectionItem key={selection.field}>
          <ContentText size="xs" fontWeight="bold" data-testid={`select-title-${selection.field}`}>
            {`${selection.field}: `}
            &nbsp;
          </ContentText>
          {selection.selected.map((selectedDesc: string, index: number) => (
            <ContentText
              size="xs"
              data-testid={`selected-value-${selection.field}-${selectedDesc}`}
              key={`${selection.field}-${selectedDesc}`}
            >
              {(index > 0 ? ', ' : '') + selectedDesc}
            </ContentText>
          ))}
          <IconButton
            status="plain"
            size={20}
            icon={<IconCross size={14} />}
            ml={spacing.xxxs}
            color={colors.inkGrey}
            onClick={() => clearSelection(selection.field)}
          />
        </SelectionItem>
      ));
    }

    return (
      <ContentText data-testid="no-selections" fontSize="s" color={colors.sonicGrey}>
        {t('selectionsManagement.nothingSelected')}
      </ContentText>
    );
  };

  return (
    <SelectionsWrapper
      data-testid="current-selections-container"
      $maxHeight={maxHeight}
      $minHeight={minHeight}
      $margin={margin}
      className={className}
      $emptyState={qSelections.length === 0}
    >
      {renderContent()}
    </SelectionsWrapper>
  );
};
