import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui-fork';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledDescriptionListItem } from '@components/styles';
import { displayedValue } from '@utils/dataOperations';
import { EquipmentDetails } from '@models/contract';
import { translateIfExist } from '@utils/internationalization';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';

interface EquipmentDetailsTileProps {
  equipmentDetails: EquipmentDetails;
}

export const EquipmentDetailsTile: FC<EquipmentDetailsTileProps> = ({ equipmentDetails }) => {
  const { t } = useTranslation<Namespace>('wasteDetails');

  const equipmentOwnership = translateIfExist(equipmentDetails.type, 'domain', 'equipmentGroup');

  return (
    <Tile header={t('equipmentDetails')} data-testid={getTileTestId('equipment-details')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem label={t('equipmentType')} data-testid={getListItemTestId('equipment-type')}>
          {displayedValue(equipmentDetails.description)}
        </StyledDescriptionListItem>

        {equipmentOwnership && (
          <StyledDescriptionListItem label={t('equipmentOwnership')} data-testid={getListItemTestId('equipment-ownership')}>
            {equipmentOwnership}
          </StyledDescriptionListItem>
        )}
      </DescriptionList>
    </Tile>
  );
};
