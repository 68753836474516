import { useUserInfo } from '@data/hooks/useUserInfo';
import { Box, Col, Grid, Row, colors } from '@fortum/elemental-ui-fork';
import { BusinessPartner } from '@models/user';
import { CharactersLayout } from '@routes/customer/components/CharactersLayout';
import { ListLayout } from '@routes/customer/components/ListLayout';
import { SelectedLayout } from '@routes/customer/components/SelectedLayout';
import { CharacterChips } from 'src/types/filters';
import _, { isNaN, sortBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { sessionKeys, StorageUtils } from '@common/storage';
import { BusinessPartnersContext } from './BusinessPartnersContext';
import { useNavigate } from 'react-router-dom';
import { paths } from '@config/routes';

export const SelectCustomer = () => {
  const navigate = useNavigate();
  const { data: userInfo } = useUserInfo();
  const [active, setActive] = useState<string[]>([]);
  const { userBps, selectedBps, setSelectedBps } = useContext(BusinessPartnersContext);
  const [searchValue, setSearchValue] = useState<string>('');
  const [businessPartners, setBusinessPartners] = useState<BusinessPartner[]>(userBps);

  const onContinueButtonClicked = useCallback(() => {
    const redirectPath = StorageUtils.popItem(sessionKeys.redirectUri) ?? paths.main;
    navigate(redirectPath);
  }, []);

  const groupedCompanies = useMemo(() => {
    return _(businessPartners)
      .groupBy(o => o.name[0].toUpperCase())
      .map((contacts, letter) => ({
        letter,
        firstContract: contacts[0],
      }))
      .value()
      .reduce<Record<string, BusinessPartner>>((ac, c) => {
        ac[c.letter] = c.firstContract;
        return ac;
      }, {});
  }, [businessPartners]);

  useEffect(() => {
    const sortedUserBps = sortBy(userBps, [o => o.name.toLocaleLowerCase()]);

    if (searchValue !== '' && active.length === 0) {
      setBusinessPartners(sortedUserBps.filter(textSearch));
    } else if (active.length > 0 && searchValue === '') {
      setBusinessPartners(sortedUserBps.filter(characterSearch));
    } else if (active.length > 0 && searchValue !== '') {
      setBusinessPartners(sortedUserBps.filter(characterSearch).filter(textSearch));
    } else {
      setBusinessPartners(sortedUserBps);
    }
  }, [searchValue, active, userBps]);

  const characterSearch = (bpId: BusinessPartner) => {
    const filterNumber = active.includes('numbers');
    const activeLetters = active.filter(a => a !== 'numbers');
    return (
      activeLetters.some(letter => bpId.name.startsWith(letter)) ||
      (filterNumber && characterChips.numbers.some(asd => bpId.name.startsWith(asd.value)))
    );
  };
  const textSearch = (bp: BusinessPartner) => bp.name.toLowerCase().includes(searchValue.toLowerCase());

  const characterChips: CharacterChips = useMemo(() => {
    const grouped = _(userInfo && userInfo.businessPartners)
      .groupBy(o => o.name.trimStart()[0].toUpperCase())
      .map((_, key) => ({ label: key, value: key }))
      .value();

    const data = sortBy(
      grouped.filter(e => isNaN(Number(e.value))),
      'value',
    );

    const numbers = sortBy(
      grouped.filter(e => !isNaN(Number(e.value))),
      'value',
    );
    data.unshift({
      label: `${numbers[0].value}-${numbers[numbers.length - 1].value}`,
      value: 'numbers',
    });

    return { letters: data, numbers };
  }, [userInfo]);

  const selectBusinessPartner = useCallback(
    (businessPartner: BusinessPartner) => {
      const userToBeSelected = businessPartners.find(bp => bp.id === businessPartner.id && bp.name === businessPartner.name);
      if (userToBeSelected) {
        setSelectedBps(prev => [...prev, userToBeSelected]);
      }
    },
    [setSelectedBps, businessPartners],
  );

  const deselect = useCallback(
    (businessPartner: BusinessPartner) =>
      setSelectedBps(prev => prev.filter(bp => !(bp.id === businessPartner.id && bp.name === businessPartner.name))),
    [setSelectedBps],
  );

  // when user does not have assigned BPs, it is treated as an error
  const hasError = userBps.length === 0;

  return (
    <Box display="flex" flex="1" flexDirection="column" backgroundColor={colors.snowWhite}>
      <Grid backgroundColor={colors.snowWhite}>
        <Row>
          <Col xxs={6}>
            <Box display="flex" flex={1} flexDirection="column" backgroundColor={colors.snowWhite}>
              <CharactersLayout characterChips={characterChips} active={active} setActive={setActive} />
              <ListLayout
                hasError={hasError}
                businessPartners={businessPartners}
                groupedBP={groupedCompanies}
                search={setSearchValue}
                searchValue={searchValue}
                selectedBps={selectedBps}
                deselect={deselect}
                select={selectBusinessPartner}
              />
            </Box>
          </Col>
          <Col xxs={1} />
          <Col xxs={5}>
            <SelectedLayout
              selectedBPs={selectedBps}
              deselect={deselect}
              clearSelection={() => setSelectedBps([])}
              onContinueButtonClicked={onContinueButtonClicked}
            />
          </Col>
        </Row>
      </Grid>
    </Box>
  );
};
