import { FailedToLoadDataNote, NoResultsNote } from '@components/index';

import { Namespace } from '@config/i18n';
import {
  Box,
  ContentText,
  IconAdd,
  IconCross,
  IconSearch,
  IconSubtract,
  InputField,
  colors,
  fontSizes,
  spacing,
} from '@fortum/elemental-ui-fork';
import { BusinessPartnerRow } from '@routes/customer/style';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { companiesSelectionItemDisabled } from '@utils/user';

import { ErrorContainer } from '@routes/services/styles';
import { DividerNonBorder } from '../styles';
import { ListContainer } from './styles';
import { SelectCompanyItem } from './SelectCompanyPanel';
import { ngColors } from '@utils/styleOverride';

interface Props {
  companies: SelectCompanyItem[];
  selectedCompanies: SelectCompanyItem[];
  searchValue: string;
  limit: number;
  search: (val: string) => void;
  deselect: (company: SelectCompanyItem) => void;
  select: (company: SelectCompanyItem) => void;
  hasError?: boolean;
}

const ListComponent: FC<Props> = ({ searchValue, companies, search, selectedCompanies, limit, deselect, select, hasError }) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  return (
    <ListContainer>
      <InputField
        id="filterBusinessPartners"
        autoFocus
        name={'filterBusinessPartners'}
        label={t('companyPanel.searchInputLabel')}
        width="100%"
        leftIcon={<IconSearch />}
        rightIcon={searchValue !== '' ? <IconCross onClick={() => search('')} /> : undefined}
        value={searchValue}
        onRightIconClick={() => search('')}
        onChange={event => search(event.target.value)}
      />
      <DividerNonBorder />
      {hasError ? (
        <ErrorContainer>
          <FailedToLoadDataNote />
        </ErrorContainer>
      ) : companies.length === 0 ? (
        <ErrorContainer>
          <NoResultsNote />
        </ErrorContainer>
      ) : (
        <ListContent
          selectedCompanies={selectedCompanies}
          deselect={deselect}
          select={select}
          companies={companies}
          limit={limit}
        />
      )}
    </ListContainer>
  );
};

type ListContentProps = Pick<Props, 'selectedCompanies' | 'deselect' | 'select' | 'companies' | 'limit'>;

const ListContent: FC<ListContentProps> = ({ selectedCompanies, deselect, select, companies, limit }) => (
  <AutoSizer>
    {({ height, width }) => (
      <List innerElementType={'ul'} itemData={companies} itemCount={companies.length} itemSize={70} height={height} width={width}>
        {({ data, index, style }) => (
          <ListItem
            data={data}
            index={index}
            style={style}
            selectedCompanies={selectedCompanies}
            deselect={deselect}
            select={select}
            limit={limit}
          />
        )}
      </List>
    )}
  </AutoSizer>
);

type ListItemProps = ListChildComponentProps & Pick<ListContentProps, 'selectedCompanies' | 'deselect' | 'select' | 'limit'>;

const ListItem: FC<ListItemProps> = ({ data, index, style, selectedCompanies, deselect, select, limit }) => {
  const key = `${data[index].name}-${data[index].value}-${index}`;
  const handleItemClick = () => {
    const isSelectedCompanyExist = selectedCompanies.some(company => company.value === data[index].value);
    if (isSelectedCompanyExist) {
      deselect(data[index]);
    } else {
      select(data[index]);
    }
  };

  return (
    <li style={style} id={key}>
      <BusinessPartnerRow
        $disabled={companiesSelectionItemDisabled(selectedCompanies, data[index].value, data[index].name, limit)}
        onClick={handleItemClick}
        key={key}
      >
        <Box paddingTop="2px">
          {selectedCompanies.some(
            selectedCompany => selectedCompany.value === data[index].value && selectedCompany.name === data[index].name,
            limit,
          ) ? (
            <IconSubtract
              size={24}
              color={
                companiesSelectionItemDisabled(selectedCompanies, data[index].value, data[index].name, limit)
                  ? colors.fogGrey
                  : ngColors.orange
              }
            />
          ) : (
            <IconAdd
              size={24}
              color={
                companiesSelectionItemDisabled(selectedCompanies, data[index].value, data[index].name, limit)
                  ? colors.fogGrey
                  : ngColors.orange
              }
            />
          )}
        </Box>
        <Box pl={spacing.xxs}>
          <ContentText fontSize={fontSizes.m} color={colors.inkGrey}>
            {data[index].name}
          </ContentText>
          <ContentText fontSize={fontSizes.m} color={colors.sonicGrey}>
            {data[index].value}
          </ContentText>
        </Box>
      </BusinessPartnerRow>
    </li>
  );
};

export const ListLayout = memo(ListComponent);
