import { px2rem, colors, spacing, ContentText, Link } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  --horizontal-padding: ${spacing.xs};

  display: flex;
  flex-flow: row wrap-reverse;
  align-items: center;
  min-height: ${px2rem(40)};
  max-height: ${px2rem(86)};
  padding: ${spacing.xxxs} var(--horizontal-padding);
  box-sizing: border-box;
  border-top: thin solid ${colors.cloudGrey};
  column-gap: ${px2rem(40)};
  row-gap: ${px2rem(16)};
`;

export const AbsolutelyPositionedText = styled(ContentText)`
  position: absolute;
  right: var(--horizontal-padding);
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.inkGrey};

  :hover {
    text-decoration: underline;
    color: ${colors.inkGrey};
  }
  :visited {
    color: ${colors.inkGrey};
  }
`;
