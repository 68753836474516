import { Box, ContentText, fontSizes } from '@fortum/elemental-ui-fork';
import { FC } from 'react';

interface InvoicingAddressProps {
  addressElements: string[];
}

export const InvoicingAddress: FC<InvoicingAddressProps> = ({ addressElements }) => (
  <Box display="flex" flexDirection="column" data-testid="invoicing-address">
    {addressElements.map((value, index) => (
      <ContentText key={index} fontSize={fontSizes.s}>
        {value}
      </ContentText>
    ))}
  </Box>
);
