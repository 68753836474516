import { HttpError } from '@common/http';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { editUser } from '@data/api/usersManagement';
import { EditUserRequestParams } from '@models/usersManagement';

type UserEditMutationOptions = Omit<UseMutationOptions<void, HttpError, EditUserRequestParams, unknown>, 'mutationFn'>;

export const useUserEditMutation = (mutationOptions?: UserEditMutationOptions) =>
  useMutation({
    mutationFn: editUser,
    ...mutationOptions,
  });
