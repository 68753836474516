import { verticalContainer } from '@components/styles';
import { ColProps, CommonGridProps, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const VerticalContainerWithBigGap = styled.div`
  ${verticalContainer};

  gap: ${spacing.xs};
`;

export const gridStyles: CommonGridProps = {
  mh: { xxl: 0 },
  maxWidth: { xxl: '100%' },
  ph: { l: 0 },
};

export const columnStyles: ColProps = {
  xxs: 12,
  xl: 6,
};

export const wasteColumnStyles: ColProps = {
  ...columnStyles,
  mb: { l: spacing.xs },
};
