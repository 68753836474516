import { RowKey, TableColumnProps } from '@fortum/elemental-ui-fork';
import { ContractByLocation } from '@models/contract';
import { t } from 'i18next';

type ColumnProps = Pick<TableColumnProps<ContractByLocation, RowKey>, 'key' | 'name' | 'sortable'>;
type ContractByLocationTableKeys = keyof Omit<ContractByLocation, 'dataSource'>;

export const getColumnsBaseConfiguration = (): {
  [key in ContractByLocationTableKeys]: ColumnProps;
} => ({
  companyName: {
    key: 'companyName',
    name: t('orders:table.businessPartner'),
  },
  contractLocation: {
    key: 'contractLocation',
    name: t('orders:table.site'),
  },
  serviceType: {
    key: 'serviceType',
    name: t('wasteDetails:serviceType'),
  },
  wasteType: {
    key: 'wasteType',
    name: t('orders:table.wasteType'),
  },
  equipmentType: {
    key: 'equipmentType',
    name: t('orders:table.containerType'),
  },
  transportType: {
    key: 'transportType',
    name: t('orders:table.transportType'),
  },
  contractNo: {
    key: 'contractNo',
    name: t('orders:table.contractNo'),
  },
});
