import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { FC } from 'react';
import { User } from '@models/user';
import { Box, ContentText, fontSizes, fontWeights, px2rem } from '@fortum/elemental-ui-fork';
import { ContactEmail } from './styles';

interface EmailAddressProps {
  email: User['customerService']['email'];
}

export const EmailAddress: FC<EmailAddressProps> = ({ email }) => {
  const { t } = useTranslation<Namespace[]>(['contactUs', 'orderCreation']);

  return (
    <Box width={px2rem(284)}>
      <ContentText fontWeight={fontWeights.medium}>{t('orderCreation:footer.email')}</ContentText>
      <ContentText fontSize={fontSizes.s}>{t('orderCreation:footer.customerService')}</ContentText>
      <ContactEmail>{email}</ContactEmail>
    </Box>
  );
};
