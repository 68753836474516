import { HttpError } from '@common/http';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { createUser } from '@data/api/usersManagement';
import { CreateUserRequestBody } from '@models/usersManagement';

type UserCreationMutationOptions = Omit<UseMutationOptions<void, HttpError, CreateUserRequestBody, unknown>, 'mutationFn'>;

export const useUserCreationMutation = (mutationOptions?: UserCreationMutationOptions) =>
  useMutation({
    mutationFn: createUser,
    ...mutationOptions,
  });
