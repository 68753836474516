import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BusinessPartnerTile, CompanyItemContainer } from './styles';
import { SectionHeader } from '@components/index';
import { Box, Button, colors, ContentText, fontSizes, IconBusinessUi, IconTrash, spacing } from '@fortum/elemental-ui-fork';
import { Divider, HorizontalContainer, VerticalContainer } from '@components/styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { UserDetailsFormContext } from '../UserDetailsFormContext';
import { PermissionsInCompanyForm } from 'src/types/usersManagement';
import { Stylable } from '@components/types';
import { SelectCompanyItem, SelectCompanyPanel } from '@routes/usersManagement/components/SelectCompanyPanel/SelectCompanyPanel';
import { COMPANY_IDENTIFIER_DELIMITER, createCompanyIdentifier } from '@utils/usersManagement';
import { ngColors } from '@utils/styleOverride';
interface BusinessPartnerFormProps extends Stylable {
  tileIndex: number;
  permissionsInCompany: PermissionsInCompanyForm;
  discardingEnabled: boolean;
  disabled?: boolean;
}

//TODO: add PermissionsForm

export const BusinessPartnerForm: FC<BusinessPartnerFormProps> = ({
  tileIndex,
  permissionsInCompany,
  discardingEnabled,
  className,
  disabled = false,
}) => {
  const { t, i18n } = useTranslation<Namespace[]>(['usersManagement']);

  const { permissionsPerCompaniesHandler } = useContext(UserDetailsFormContext);
  const { companiesQueryResult, companiesSelectItems, permissionsInCompanyHandler } = permissionsPerCompaniesHandler;
  const tileHasError = permissionsInCompanyHandler.tilesWithErrors.includes(permissionsInCompany.formId);
  const [selectedCompany, setSelectedCompany] = useState<SelectCompanyItem[]>([]);

  const onSaveHandler = useCallback(
    (selected: SelectCompanyItem[]) => {
      const { name, value } = selected[0];
      const indentifier = createCompanyIdentifier(value, name);
      permissionsInCompanyHandler.setSelectedCompany(permissionsInCompany.formId, indentifier);
      setSelectedCompany(selected);
      setPanelOpen(false);
    },
    [permissionsInCompany.formId, permissionsInCompanyHandler.setSelectedCompany],
  );

  const [panelOpen, setPanelOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!permissionsInCompany.companyIdentifier) return;
    const companyItem = companiesSelectItems.find(
      (company: SelectCompanyItem) =>
        company.value.trim() === permissionsInCompany.companyIdentifier.split(COMPANY_IDENTIFIER_DELIMITER)[0].trim(),
    );
    if (companyItem) {
      setSelectedCompany([companyItem]);
    }
  }, [permissionsInCompany.companyIdentifier, setSelectedCompany]);

  const company = useMemo(() => {
    return selectedCompany[0];
  }, [selectedCompany]);

  const selectDisabled = companiesQueryResult.isLoading || companiesQueryResult.isError || disabled;

  const renderDiscardButton = useCallback(
    () =>
      discardingEnabled ? (
        <Button
          leftIcon={<IconTrash />}
          color={colors.powerRed}
          onClick={() => permissionsInCompanyHandler.removeItem(permissionsInCompany.formId)}
        >
          {t('common:discard')}
        </Button>
      ) : undefined,
    [discardingEnabled, permissionsInCompany.formId, i18n.language],
  );

  return (
    <BusinessPartnerTile
      data-testid={`business-partner-tile-${tileIndex}`}
      className={className}
      $disabled={disabled}
      $tileHasError={tileHasError}
    >
      <SectionHeader
        icon={<IconBusinessUi />}
        rightButton={renderDiscardButton()}
        text={t('usersManagement:creationForm.businessPartnerTile.header', { tileIndex: tileIndex + 1 })}
        size="s"
      />

      <Divider />
      <HorizontalContainer>
        <CompanyItemContainer>
          <VerticalContainer>
            <ContentText fontSize={fontSizes.m} color={colors.inkGrey} paddingBottom={spacing.xxxs}>
              {t('usersManagement:creationForm.companySelect.label')}
            </ContentText>
            {company ? (
              <Box p={0} display="flex" flex={1} flexDirection="column">
                <ContentText fontSize={fontSizes.s} color={colors.inkGrey}>
                  {company.name}
                </ContentText>
                <ContentText fontSize={fontSizes.s} color={colors.sonicGrey}>
                  {company.value}
                </ContentText>
              </Box>
            ) : (
              <ContentText fontSize={fontSizes.s} color={colors.sonicGrey}>
                {t('usersManagement:noCompanySelected')}
              </ContentText>
            )}
          </VerticalContainer>
          <Button disabled={selectDisabled} textColor={ngColors.white} onClick={() => setPanelOpen(!panelOpen)} size="s">
            {company ? t('usersManagement:creationForm.edit') : t('usersManagement:selectCompany')}
          </Button>
        </CompanyItemContainer>
        <SelectCompanyPanel
          companies={companiesSelectItems}
          open={panelOpen}
          onClose={() => setPanelOpen(false)}
          onSave={onSaveHandler}
          initSelectedCompanies={selectedCompany}
        />
      </HorizontalContainer>
    </BusinessPartnerTile>
  );
};
