import { useUsersCompanies } from '@data/hooks/useUsersCompanies';
import { SelectItem } from '@fortum/elemental-ui-fork';
import { updateFormsWithSelectedCompany } from '@utils/usersManagement';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PermissionsInCompanyForm } from 'src/types/usersManagement';
import { v4 as uuidV4 } from 'uuid';

export type UseCompaniesQueryResultType = ReturnType<typeof useUsersCompanies>;

export interface UsePermissionsPerCompanies {
  companiesQueryResult: Pick<UseCompaniesQueryResultType, 'isLoading' | 'isError' | 'data'>;
  permissionsInCompanyHandler: {
    permissionsInCompanies: PermissionsInCompanyForm[];
    tilesWithErrors: string[];
    addNewItem: () => void;
    removeItem: (formId: string) => void;
    setSelectedCompany: (formId: string, companyIdentifier: string) => void;
    validateBusinessPartnerForm: () => boolean;
  };
  companiesSelectItems: SelectItem<string>[];
}

//TODO: handle setting selected permissions per company
const initializePermissionsInCompany = (): PermissionsInCompanyForm => ({
  formId: uuidV4(),
  companyIdentifier: '',
  additionalPermissions: [],
});

export const usePermissionsPerCompanies = (
  initialPermissionsInCompanies?: PermissionsInCompanyForm[],
): UsePermissionsPerCompanies => {
  const companiesQueryResult = useUsersCompanies();

  const [tilesWithErrors, setTilesWithErrors] = useState<string[]>([]);
  const [permissionsInCompanies, setPermissionsInCompanies] = useState<PermissionsInCompanyForm[]>(
    initialPermissionsInCompanies ?? [initializePermissionsInCompany()],
  );

  useEffect(
    () => initialPermissionsInCompanies && setPermissionsInCompanies(initialPermissionsInCompanies),
    [initialPermissionsInCompanies],
  );

  const companiesSelectItems = useMemo<SelectItem<string>[]>(
    () =>
      companiesQueryResult.data?.data.map(company => ({
        value: company.code.trimStart(),
        name: company.name.trimStart(),
      })) ?? [],
    [companiesQueryResult.data],
  );

  const addNewItem = useCallback(() => setPermissionsInCompanies(prev => [...prev, initializePermissionsInCompany()]), []);

  const removeItem = useCallback((formId: string) => {
    setPermissionsInCompanies(prev => prev.filter(item => item.formId !== formId));

    removeTileFromErrors(formId);
  }, []);

  const setSelectedCompany = useCallback((formId: string, companyIdentifier: string) => {
    setPermissionsInCompanies(prev => updateFormsWithSelectedCompany(prev, formId, companyIdentifier));

    if (companyIdentifier) {
      removeTileFromErrors(formId);
    }
  }, []);

  const validateBusinessPartnerForm = () => {
    const incorrectFormsIds: string[] = permissionsInCompanies
      .filter(company => company.companyIdentifier === '')
      .map(company => company.formId);

    setTilesWithErrors(incorrectFormsIds);
    return incorrectFormsIds.length === 0;
  };

  const removeTileFromErrors = useCallback(
    (formId: string) => setTilesWithErrors(prev => prev.filter(idx => idx !== formId)),
    [],
  );

  return {
    companiesQueryResult,
    companiesSelectItems,
    permissionsInCompanyHandler: {
      permissionsInCompanies,
      tilesWithErrors,
      addNewItem,
      removeItem,
      setSelectedCompany,
      validateBusinessPartnerForm,
    },
  };
};
