import { RadioButtonItemProps } from '@components/HorizontalRadioButtonGroup';
import { SelectChangeEvent } from '@components/types';
import { Namespace } from '@config/i18n';
import { getUserAvailableLanguages } from '@config/usersManagement';
import { SelectItem } from '@fortum/elemental-ui-fork';
import { userTypes } from '@models/user';
import { isUserType } from '@utils/user';
import { getMissingRequiredFields, getIncorrectFormatFields, regexValidation } from '@utils/usersManagement';
import { useMemo, useCallback, ChangeEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SimplifiedEvent } from 'src/types/events';
import { UserPersonalInformation } from 'src/types/usersManagement';

export interface UsePersonalInformationForm {
  personalInformation: UserPersonalInformation;
  accountLanguageItems: SelectItem<string>[];
  userTypeRadioButtonItems: RadioButtonItemProps[];
  setEmail: (ev: SimplifiedEvent<string>) => void;
  setFirstName: (ev: SimplifiedEvent<string>) => void;
  setLastName: (ev: SimplifiedEvent<string>) => void;
  setLanguage: (e: ChangeEvent<SelectChangeEvent<string>>) => void;
  setUserType: (ev: SimplifiedEvent<string>) => void;
  setPhone: (ev: SimplifiedEvent<string>) => void;
  validatePersonalInformation: () => boolean;
  missingRequiredFields: string[];
  incorrectFormatFields: string[];
  isPersonalInformationValid: boolean;
}

const defaultInitialPersonalInformation: UserPersonalInformation = {
  name: '',
  lastName: '',
  email: '',
  phone: '',
  language: '',
  userType: 'EXTERNAL',
};

const requiredFields = ['name', 'lastName', 'email', 'language'];
const fieldsWithRequiredFormat = ['email', 'phone'];

export const usePersonalInformationForm = (initialPersonalInformation?: UserPersonalInformation): UsePersonalInformationForm => {
  const [personalInformation, setPersonalInformation] = useState<UserPersonalInformation>(
    initialPersonalInformation ?? defaultInitialPersonalInformation,
  );

  useEffect(() => initialPersonalInformation && setPersonalInformation(initialPersonalInformation), [initialPersonalInformation]);

  const [missingRequiredFields, setMissingRequiredFields] = useState<string[]>([]);
  const [incorrectFormatFields, setIncorrectFormatFields] = useState<string[]>([]);

  const [emailIsValidated, setEmailIsValidated] = useState(false);
  const [phoneIsValidated, setPhoneIsValidated] = useState(false);

  const [isPersonalInformationValid, setIsPersonalInformationValid] = useState(true);

  const { t, i18n } = useTranslation<Namespace[]>(['usersManagement', 'common']);

  const accountLanguageItems = useMemo<SelectItem<string>[]>(
    () => getUserAvailableLanguages().map(lang => ({ name: t(`common:languages.${lang.toUpperCase()}`), value: lang })),
    [i18n.language],
  );

  const userTypeRadioButtonItems = useMemo<RadioButtonItemProps[]>(
    () =>
      userTypes.map(userType => ({
        label: t(`usersManagement:userType.${userType}`),
        value: userType,
        id: userType,
      })),
    [i18n.language],
  );

  useEffect(() => {
    setIsPersonalInformationValid(missingRequiredFields.length === 0 && incorrectFormatFields.length === 0);
  }, [missingRequiredFields, incorrectFormatFields]);

  const validatePersonalInformation = useCallback(() => {
    const missingFields = getMissingRequiredFields(personalInformation, requiredFields);
    const incorrectFields = getIncorrectFormatFields(personalInformation, fieldsWithRequiredFormat);

    setMissingRequiredFields(missingFields);
    setIncorrectFormatFields(incorrectFields);

    // Variable for knowing whether the fields have been invalid once or not
    // This is used in the revalidation - you should not validate the format before the fields have already been validated once
    setEmailIsValidated(true);
    // Phone should not be validated until it has been invalid once
    setPhoneIsValidated(incorrectFields.includes('phone'));

    return missingFields.length === 0 && incorrectFields.length === 0;
  }, [personalInformation, requiredFields, fieldsWithRequiredFormat, getMissingRequiredFields, getIncorrectFormatFields]);

  const revalidateMissingField = (field: string, value: string) =>
    value
      ? setMissingRequiredFields(prev => (value ? prev.filter(missingField => missingField !== field) : [...prev, field]))
      : setMissingRequiredFields(prev => [...prev, field]);

  const revalidateIncorrectField = (field: string, value: string) => {
    if (!regexValidation(field, value)) {
      setIncorrectFormatFields(prev => (prev.includes(field) ? prev : [...prev, field]));
    }

    setIncorrectFormatFields(prev => {
      return value
        ? prev.filter(incorrectField => !regexValidation(incorrectField, value))
        : prev.filter(incorrectField => incorrectField !== field);
    });
  };

  const setFirstName = useCallback(
    (ev: SimplifiedEvent<string>) => {
      revalidateMissingField('name', ev.target.value);
      setPersonalInformation(prev => ({ ...prev, name: ev.target.value }));
    },
    [revalidateMissingField],
  );

  const setLastName = useCallback(
    (ev: SimplifiedEvent<string>) => {
      revalidateMissingField('lastName', ev.target.value);
      setPersonalInformation(prev => ({ ...prev, lastName: ev.target.value }));
    },
    [revalidateMissingField],
  );

  const setEmail = useCallback(
    (ev: SimplifiedEvent<string>) => {
      revalidateMissingField('email', ev.target.value);
      if (emailIsValidated) {
        revalidateIncorrectField('email', ev.target.value);
      }
      setPersonalInformation(prev => ({ ...prev, email: ev.target.value }));
    },
    [revalidateMissingField, emailIsValidated, revalidateIncorrectField],
  );

  const setPhone = useCallback(
    (ev: SimplifiedEvent<string>) => {
      if (phoneIsValidated) {
        revalidateIncorrectField('phone', ev.target.value);
      }
      setPersonalInformation(prev => ({ ...prev, phone: ev.target.value }));
    },
    [phoneIsValidated, revalidateIncorrectField],
  );

  const setLanguage = useCallback(
    (e: ChangeEvent<SelectChangeEvent<string>>) => {
      revalidateMissingField('language', e.target.value);
      setPersonalInformation(prev => ({ ...prev, language: e.target.value }));
    },
    [revalidateMissingField],
  );

  const setUserType = useCallback((ev: SimplifiedEvent<string>) => {
    const userTypeValue = ev.target.value;
    if (!isUserType(userTypeValue)) return;

    setPersonalInformation(prev => ({ ...prev, userType: userTypeValue }));
  }, []);

  return {
    personalInformation,
    accountLanguageItems,
    userTypeRadioButtonItems,
    setEmail,
    setFirstName,
    setLastName,
    setLanguage,
    setUserType,
    setPhone,
    validatePersonalInformation,
    missingRequiredFields,
    incorrectFormatFields,
    isPersonalInformationValid,
  };
};
