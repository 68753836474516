import { PageNavigationHeaderProps } from '@components/PageNavigationHeader';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { Button } from '@fortum/elemental-ui-fork';
import { StyledPageNavigationHeader, UserCreationContainer, ContentContainer, buttonStyles } from './components/styles';
import { parseDetailedUserProfile } from '@utils/usersManagement';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PersonalInformationForm, InternalUserPermissionsForm, ExternalUserPermissionsForm } from './components';
import type { FormDisableVariant } from './components';
import { UserDetailsFormContextProvider } from './components/UserDetailsFormContextProvider';
import { UserDetailsFormContext } from './components/UserDetailsFormContext';
import { useUsersProfile } from '@data/hooks/useUserProfile';
import { UserStatusToggle } from './components/UserStatusToggle';
import { useUserCreation } from './components/useUserCreation';
import { useUserEditing } from './components/useUserEditing';

interface UserCreationLayoutProps {
  userId?: string;
}

const UserCreationLayout: FC<UserCreationLayoutProps> = ({ userId }) => {
  const editMode = !!userId;

  const navigate = useNavigate();
  const { t } = useTranslation<Namespace[]>(['usersManagement', 'errors']);

  const { personalInformationFormHandler, userActive, isFormValid } = useContext(UserDetailsFormContext);

  const { onCreateButtonClick } = useUserCreation();

  const { onEditButtonClick, toggleUserActiveState } = useUserEditing(userId);

  const headerLinkProps: PageNavigationHeaderProps['link'] = {
    id: 'navigate-to-users-list-link',
    description: t('appMenuItem.label'),
    onClick: () => {
      navigate(paths.usersManagement);
    },
  };

  const formDisabled = editMode && !userActive;
  const personalInformationFormDisability: FormDisableVariant = formDisabled ? 'all' : userActive ? 'email' : 'none';

  return (
    <>
      <StyledPageNavigationHeader
        link={headerLinkProps}
        title={editMode ? t('usersManagement:userEditing.header') : t('usersManagement:userCreation.header')}
      />
      <UserCreationContainer>
        <ContentContainer>
          {editMode && userActive !== undefined && (
            <UserStatusToggle userIsActive={userActive} onToggleChange={toggleUserActiveState} toggleDisabled={!isFormValid} />
          )}

          <PersonalInformationForm disableVariant={personalInformationFormDisability} />

          {personalInformationFormHandler.personalInformation.userType === 'INTERNAL' ? (
            <InternalUserPermissionsForm disabled={formDisabled} />
          ) : (
            <ExternalUserPermissionsForm disabled={formDisabled} />
          )}

          {editMode ? (
            <Button {...buttonStyles} onClick={onEditButtonClick}>
              {t('usersManagement:userEditing.saveChanges')}
            </Button>
          ) : (
            <Button {...buttonStyles} onClick={onCreateButtonClick}>
              {t('usersManagement:creationForm.create')}
            </Button>
          )}
        </ContentContainer>
      </UserCreationContainer>
    </>
  );
};

export const UserCreation: FC = () => {
  const location = useLocation();

  const userId = location.state?.userId;

  const { data: user } = useUsersProfile(userId);

  const { personalInformation, internalUserPermissions, externalUserPermissions, isUserActive } = useMemo(
    () => parseDetailedUserProfile(user),
    [user],
  );

  return (
    <UserDetailsFormContextProvider
      initialPersonalInformation={personalInformation}
      initialInternalUserPermissions={internalUserPermissions}
      initialExternalUserCompanies={externalUserPermissions}
      initialIsUserActive={isUserActive}
    >
      <UserCreationLayout userId={userId} />
    </UserDetailsFormContextProvider>
  );
};
