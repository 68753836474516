import { OrdersRequestParams, useOrders } from '@data/hooks/useOrders';
import { OrdersFiltersContext } from '@routes/orders/components/OrdersFiltersContext';
import { INITIAL_PAGE_NUMBER } from '@common/consts';
import { FC, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NUMBER_OF_PAGE_ELEMENTS } from '../config';
import { OrdersTable } from './OrdersTable';
import { LayoutContainer, StyledPaginationLayout, StyledErrorView } from './styles';
import { TOTAL_PAGES_ON_DATA_LOADING } from '@common/consts';
import { PaginationSize, paginationHeights } from '@components/Pagination';
import { scrollToTop } from '@utils/general';

interface Props {
  pageHeaderHeight: string | undefined;
}

const PAGINATION_SIZE: PaginationSize = 'l';
const PAGINATION_HEIGHT_PX = paginationHeights[PAGINATION_SIZE];
const TABLE_ID = 'orders-table';

export const TableComponent: FC<Props> = ({ pageHeaderHeight }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const filtersContext = useContext(OrdersFiltersContext);

  if (!filtersContext) throw Error('Filter context is not initialized');

  const [ordersRequestParams, setOrdersRequestParams] = useState<OrdersRequestParams>({
    activePage: filtersContext.activePage,
    sortBy: filtersContext.sortBy,
    filters: filtersContext.debouncedSelectedFiltersValuesWithPrecedence.selectedFiltersValues,
  });

  const [totalPaginationPages, setTotalPaginationPages] = useState<number>(TOTAL_PAGES_ON_DATA_LOADING);

  const { data: ordersResponse, isLoading: isLoadingOrders, fetchStatus, error } = useOrders(ordersRequestParams);

  useEffect(() => {
    const shouldRestoreActivePage = totalPaginationPages === TOTAL_PAGES_ON_DATA_LOADING;

    setOrdersRequestParams({
      activePage: shouldRestoreActivePage ? filtersContext.activePage : INITIAL_PAGE_NUMBER,
      sortBy: filtersContext.sortBy,
      filters: filtersContext.debouncedSelectedFiltersValuesWithPrecedence.selectedFiltersValues,
    });

    if (!shouldRestoreActivePage) filtersContext.setActivePage(INITIAL_PAGE_NUMBER);

    setTotalPaginationPages(TOTAL_PAGES_ON_DATA_LOADING);
  }, [filtersContext.sortBy, filtersContext.debouncedSelectedFiltersValuesWithPrecedence.selectedFiltersValues]);

  const setActivePage = useCallback((page: number) => {
    setOrdersRequestParams(prev => ({ ...prev, activePage: page }));

    filtersContext.setActivePage(page);

    scrollToTop(containerRef.current?.querySelector(`#${TABLE_ID}`));
  }, []);

  useEffect(() => {
    if (ordersResponse) setTotalPaginationPages(Math.ceil(ordersResponse.metadata.total / NUMBER_OF_PAGE_ELEMENTS));
  }, [ordersResponse]);

  const isLoading = isLoadingOrders || fetchStatus !== 'idle';
  const shouldDisplayTable = isLoading || !!ordersResponse;
  const shouldHidePagination = totalPaginationPages <= 1;
  const paginationDisplayed = (!error && !!ordersResponse && ordersResponse.metadata.total > 0) || isLoading;

  return (
    <LayoutContainer ref={containerRef}>
      {shouldDisplayTable ? (
        <OrdersTable
          id={TABLE_ID}
          isLoading={isLoading}
          orders={ordersResponse?.response?.data ?? []}
          pageHeaderHeight={pageHeaderHeight}
          paginationHeight={paginationDisplayed ? PAGINATION_HEIGHT_PX : '0px'}
        />
      ) : (
        <StyledErrorView />
      )}

      {paginationDisplayed && (
        <StyledPaginationLayout
          size={PAGINATION_SIZE}
          totalPages={totalPaginationPages}
          activePage={ordersRequestParams.activePage}
          setActivePage={setActivePage}
          hidePagination={shouldHidePagination}
        />
      )}
    </LayoutContainer>
  );
};

export const OrdersTableLayout = memo(TableComponent);
