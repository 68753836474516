import {
  colors,
  ComponentOrElement,
  ContentText,
  IconError,
  IconInfo,
  IconProps,
  IconWarning,
  Notification,
  NotificationStatus,
} from '@fortum/elemental-ui-fork';
import { FC } from 'react';
import { CTAText, headingTextProps, notificationProps } from './styles';

type NotificationType = Extract<NotificationStatus, 'warning' | 'alert' | 'info'>;

const notificationTypeToIconMap: Record<NotificationType, ComponentOrElement<IconProps>> = {
  warning: <IconWarning height="100%" />,
  alert: <IconError height="100%" />,
  info: <IconInfo height="100%" />,
};

const typeToBackgroundColorMap: Record<NotificationType, string> = {
  warning: colors.miniYellow,
  alert: colors.powerRed,
  info: colors.cushyBlue,
};

const typeToTextColorMap: Record<NotificationType, string> = {
  warning: colors.inkGrey,
  alert: colors.snowWhite,
  info: colors.snowWhite,
};

interface NotificationBannerProps {
  message: string;
  type: NotificationType;
  canClose?: boolean;
  ctaText?: string;
  ctaOnClick?: () => void;
}

export const NotificationBanner: FC<NotificationBannerProps> = ({ message, type, canClose = false, ctaText, ctaOnClick }) => {
  const hasCTA = !!ctaText && !!ctaOnClick;
  const heading = (
    <ContentText {...headingTextProps}>
      {message}
      {hasCTA && <CTAText onClick={ctaOnClick}>{ctaText}</CTAText>}
    </ContentText>
  );

  return (
    <Notification
      {...notificationProps}
      hideCloseButton={!canClose}
      textColor={typeToTextColorMap[type]}
      backgroundColor={typeToBackgroundColorMap[type]}
      icon={notificationTypeToIconMap[type]}
      heading={heading}
    />
  );
};
