import { InsightEventName } from '@common/statistics';
import { logEvent } from '@config/azureInsights';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { useUserInfo } from '@data/hooks/useUserInfo';
import {
  ContentText,
  IconBin,
  IconDownloadFromCloud,
  IconEmail,
  IconHome,
  IconTermsOfUse,
  fontSizes,
} from '@fortum/elemental-ui-fork';
import { isCo2ReportToggleOn, isOrdersManagementToggleOn, hasAtLeastOneReportPermission } from '@utils/featureToggles';
import { getEnvProperty } from '@utils/general';
import { BaseSyntheticEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavItem } from './NavItem';
import { OrdersSubNavigation } from './OrdersSubNavigation';
import { ReportsSubNavigation } from './ReportsSubNavigation';
import { AppVersionInfo, Navigation } from './styles';
import { ngColors } from '@utils/styleOverride';
interface AppNavbarProps {
  disabled?: boolean;
}

export const AppNavbar: FC<AppNavbarProps> = ({ disabled = false }) => {
  const { t } = useTranslation<Namespace[]>(['mainLayout', 'reports', 'orderCreation']);

  const { data: user } = useUserInfo();

  const handleItemClick = useCallback(
    (e: BaseSyntheticEvent, insightEvent?: InsightEventName) => {
      if (disabled) e.preventDefault();

      if (insightEvent) logEvent(insightEvent, null);
    },
    [disabled],
  );

  const renderVersionDesc = useCallback(() => {
    if (!getEnvProperty('VITE_ENV_DESC_TO_BE_DISPLAYED')) return null;

    const description = getEnvProperty('VITE_RELEASE_VERSION')
      ? `${getEnvProperty('VITE_ENV_DESC_TO_BE_DISPLAYED')} ${getEnvProperty('VITE_RELEASE_VERSION').substring(0, 5)}`
      : getEnvProperty('VITE_ENV_DESC_TO_BE_DISPLAYED');

    return (
      <AppVersionInfo>
        <ContentText color={ngColors.black} fontSize={fontSizes.xs}>
          {description}
        </ContentText>
      </AppVersionInfo>
    );
  }, []);

  return (
    <Navigation>
      <ul>
        <NavItem
          data-testid="home-nav-item"
          to={paths.main}
          onClick={e => handleItemClick(e, 'menuHomeClicked')}
          disabled={disabled}
          icon={<IconHome />}
          description={t('mainLayout:home')}
        />

        <OrdersSubNavigation disabled={disabled} user={user} onItemClick={handleItemClick} />

        <NavItem
          data-testid="services-nav-item"
          to={paths.services}
          onClick={e => handleItemClick(e, 'menuMyServicesClicked')}
          disabled={disabled}
          icon={<IconBin />}
          description={t('mainLayout:services')}
        />

        {user && hasAtLeastOneReportPermission(user) && (
          <ReportsSubNavigation disabled={disabled} user={user} onItemClick={handleItemClick} />
        )}

        {!!user && isCo2ReportToggleOn(user) && (
          <NavItem
            data-testid="co2-report-nav-item"
            to={paths.co2Report}
            onClick={e => handleItemClick(e, 'menuReportsCO2Clicked')}
            disabled={disabled}
            icon={<IconDownloadFromCloud />}
            description={t('reports:co2.navigation')}
          />
        )}
        <NavItem
          data-testid="contact-us-nav-item"
          to={paths.contactUs}
          onClick={e => handleItemClick(e, 'menuContactUsClicked')}
          disabled={disabled}
          icon={<IconEmail />}
          description={t('mainLayout:contact')}
        />

        {user && isOrdersManagementToggleOn(user) && (
          <NavItem
            data-testid="orders-management-nav-item"
            to={paths.ordersManagement}
            onClick={handleItemClick}
            disabled={disabled}
            icon={<IconTermsOfUse />}
            description={t('orderCreation:ordersManagement.header')}
          />
        )}
      </ul>

      {renderVersionDesc()}
    </Navigation>
  );
};
