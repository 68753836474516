import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui-fork';
import { StyledDescriptionListItem } from '@components/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicingAddress } from '@components/invoicingDetails';
import { InvoicingDetails } from '@models/contract';
import { hasValue } from '@utils/dataOperations';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { getInvoicingAddressElements } from '@utils/dataOperations/address';

interface InvoicingDetailsTileProps {
  invoicingDetails: InvoicingDetails;
}

export const InvoicingDetailsTile: FC<InvoicingDetailsTileProps> = ({ invoicingDetails }) => {
  const { t } = useTranslation<Namespace>('wasteDetails');

  const addressElements = getInvoicingAddressElements(invoicingDetails.address);

  return (
    <Tile header={t('invoicingDetails.details')} data-testid={getTileTestId('invoicing-details')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem
          label={t('invoicingDetails.invoicingAddress')}
          data-testid={getListItemTestId('invoicing-address')}
        >
          <InvoicingAddress addressElements={addressElements} />
        </StyledDescriptionListItem>

        {hasValue(invoicingDetails.address.eInvoiceAddress) && (
          <StyledDescriptionListItem
            label={t('invoicingDetails.eInvoiceAddress')}
            data-testid={getListItemTestId('e-invoice-address')}
          >
            {invoicingDetails.address.eInvoiceAddress}
          </StyledDescriptionListItem>
        )}

        {hasValue(invoicingDetails.customerReference) && (
          <StyledDescriptionListItem
            label={t('invoicingDetails.companyReference')}
            data-testid={getListItemTestId('company-reference')}
          >
            {invoicingDetails.customerReference}
          </StyledDescriptionListItem>
        )}

        {hasValue(invoicingDetails.contactPersonName) && (
          <StyledDescriptionListItem
            label={t('invoicingDetails.invoicingContactPerson')}
            data-testid={getListItemTestId('invoicing-contact-person')}
          >
            {invoicingDetails.contactPersonName}
          </StyledDescriptionListItem>
        )}

        {hasValue(invoicingDetails.orderReference) && (
          <StyledDescriptionListItem
            label={t('invoicingDetails.orderReference')}
            data-testid={getListItemTestId('order-reference')}
          >
            {invoicingDetails.orderReference}
          </StyledDescriptionListItem>
        )}
      </DescriptionList>
    </Tile>
  );
};
