import { translate } from '@utils/internationalization';
import { Box, ContentText, fontSizes } from '@fortum/elemental-ui-fork';
import { FC } from 'react';
import { NO_DATA_BADGE } from '@utils/dataOperations';
import { Testable } from '@components/types';

interface WasteConsistencyProps extends Testable {
  consistency: string[];
}

export const WasteConsistency: FC<WasteConsistencyProps> = ({ consistency, 'data-testid': dataTestId }) =>
  consistency.length === 0 ? (
    NO_DATA_BADGE
  ) : (
    <Box display="flex" flexDirection="column" data-testid={dataTestId}>
      {consistency.map(consistencyValue => (
        <ContentText key={consistencyValue} fontSize={fontSizes.s}>
          {translate(consistencyValue.toUpperCase(), 'domain', 'consistency')}
        </ContentText>
      ))}
    </Box>
  );
