import { Tile } from '@components/Tile';
import { StyledDescriptionListItem } from '@components/styles';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui-fork';
import { OrderDetails } from '@models/orders';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicingAddress } from './InvoicingAddress';
import { hasValue } from '@utils/dataOperations';

interface InvoicingDetailsTileProps {
  invoicingDetails: OrderDetails['invoicingDetails'];
}

export const InvoicingDetailsTile: FC<InvoicingDetailsTileProps> = ({ invoicingDetails }) => {
  const { t } = useTranslation<Namespace>('wasteDetails');

  return (
    <Tile header={t('invoicingDetails.details')} data-testid={getTileTestId('invoicing-details')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem
          label={t('invoicingDetails.invoicingAddress')}
          data-testid={getListItemTestId('invoicing-address')}
        >
          <InvoicingAddress location={invoicingDetails.invoicingLocation} />
        </StyledDescriptionListItem>

        {hasValue(invoicingDetails.eInvoiceAddress) && (
          <StyledDescriptionListItem
            label={t('invoicingDetails.eInvoiceAddress')}
            data-testid={getListItemTestId('e-invoice-address')}
          >
            {invoicingDetails.eInvoiceAddress}
          </StyledDescriptionListItem>
        )}

        {hasValue(invoicingDetails.customerReference) && (
          <StyledDescriptionListItem
            label={t('invoicingDetails.companyReference')}
            data-testid={getListItemTestId('company-reference')}
          >
            {invoicingDetails.customerReference}
          </StyledDescriptionListItem>
        )}

        {hasValue(invoicingDetails.invoicingContactPerson) && (
          <StyledDescriptionListItem
            label={t('invoicingDetails.invoicingContactPerson')}
            data-testid={getListItemTestId('invoicing-contact-person')}
          >
            {invoicingDetails.invoicingContactPerson}
          </StyledDescriptionListItem>
        )}

        {hasValue(invoicingDetails.orderReference) && (
          <StyledDescriptionListItem
            label={t('invoicingDetails.orderReference')}
            data-testid={getListItemTestId('order-reference')}
          >
            {invoicingDetails.orderReference}
          </StyledDescriptionListItem>
        )}
      </DescriptionList>
    </Tile>
  );
};
