import { Box } from '@fortum/elemental-ui-fork';
import { FC, useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ContactFooter, OutletContainer, containerStyles } from './components';
import { OrdersCreationContext } from './components/OrdersCreationContext';

export const OrdersCreationLayout: FC = () => {
  const location = useLocation();

  const { createEmptyCreationForm } = useContext(OrdersCreationContext);

  useEffect(() => {
    if (location?.state?.contractIdentifier) {
      createEmptyCreationForm(location.state.contractIdentifier);
    }
  }, []);

  return (
    <Box {...containerStyles}>
      <OutletContainer>
        <Outlet />
      </OutletContainer>

      <ContactFooter />
    </Box>
  );
};
