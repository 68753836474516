import { HorizontalContainer } from '@components/styles';
import { Namespace } from '@config/i18n';
import { Button, ContentText, IconAdd, IconCheck, IconCross, IconDocuments, colors } from '@fortum/elemental-ui-fork';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  checkIconStyles,
  Container,
  crossIconStyles,
  errorTextStyles,
  linkStyles,
  StyledCommonPageHeader,
  successTextStyles,
} from './styles';
import { textStyles, mediumTextStyles, buttonStyles } from './styles';
import { NonWrappingLinkLowerCased } from '@components/layout/styles';
import { paths } from '@config/routes';
import { useNavigate } from 'react-router-dom';
import { ngColors } from '@utils/styleOverride';

interface SummaryHeaderProps {
  onClick: () => void;
  displayErrorMessage: boolean;
}

export const SummaryHeader: FC<SummaryHeaderProps> = ({ onClick, displayErrorMessage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation<Namespace[]>(['orderCreation', 'errors']);

  return (
    <Container>
      <StyledCommonPageHeader
        header={t('orderCreation:summaryPage.header.mainText')}
        icon={<IconDocuments color={ngColors.orange} />}
      />

      <HorizontalContainer>
        {displayErrorMessage ? (
          <>
            <IconCross {...crossIconStyles} />
            <ContentText {...textStyles} {...errorTextStyles}>
              <ContentText {...mediumTextStyles} {...errorTextStyles}>
                {t('errors:summaryPage.header.bolded')}
              </ContentText>
              {t('errors:summaryPage.header.regular')}
              <NonWrappingLinkLowerCased {...linkStyles} onClick={() => navigate(paths.contactUs)}>
                {t('errors:ordersCreation.requestFailed.notification.message.1')}
              </NonWrappingLinkLowerCased>
            </ContentText>
          </>
        ) : (
          <>
            <IconCheck {...checkIconStyles} />
            <ContentText {...textStyles} {...successTextStyles}>
              <ContentText {...mediumTextStyles} {...successTextStyles}>
                {t('orderCreation:summaryPage.confirmationMessage.bolded')}
              </ContentText>
              {t('orderCreation:summaryPage.confirmationMessage.regular')}
            </ContentText>
          </>
        )}
      </HorizontalContainer>

      <Button {...buttonStyles} onClick={onClick} rightIcon={<IconAdd />}>
        {t('orderCreation:createNewOrder')}
      </Button>
    </Container>
  );
};
