import { logEvent } from '@config/azureInsights';
import i18n, { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { IconTruckOutline } from '@fortum/elemental-ui-fork';
import { isOrdersCreationToggleOn } from '@utils/featureToggles';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LinksDirection, Tile, TileLink } from './Tile';
import { useUserInfo } from '@data/hooks/useUserInfo';

interface OrdersTileProps {
  linksDirection?: LinksDirection;
  disabled: boolean;
}

export const OrdersTile: FC<OrdersTileProps> = ({ linksDirection = 'horizontal', disabled }) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'home']);
  const { data: user } = useUserInfo();

  const ordersCreationAllowed = !!user && isOrdersCreationToggleOn(user);

  const ordersAccessLinks = useMemo<TileLink[]>(() => {
    const ordersOverviewLinkProps = {
      id: 'orders-forward-link',
      description: t('home:quickAccess.orders.linkDesc'),
      path: paths.ordersTracking,
      onClick: () => logEvent('homeQuickAccessOrderTrackingTrackOrdersClicked', null),
    };

    return ordersCreationAllowed
      ? [
          ordersOverviewLinkProps,
          {
            id: 'order--creation-forward-link',
            description: t('orderCreation:createNewOrder'),
            path: paths.ordersCreation,
            onClick: () => logEvent('homeQuickAccessCreateNewOrderClicked', null),
          },
        ]
      : [ordersOverviewLinkProps];
  }, [i18n.language]);

  return ordersCreationAllowed ? (
    <Tile
      disabled={disabled}
      icon={<IconTruckOutline />}
      header={t('home:quickAccess.orders.headerIfOrderCreationIsAllowed')}
      description={t('home:quickAccess.orders.descIfOrderCreationIsAllowed')}
      links={ordersAccessLinks}
      linksDirection={linksDirection}
    />
  ) : (
    <Tile
      disabled={disabled}
      icon={<IconTruckOutline />}
      header={t('home:quickAccess.orders.headerIfOrderCreationIsDisabled')}
      description={t('home:quickAccess.orders.descIfOrderCreationIsDisabled')}
      links={ordersAccessLinks}
      linksDirection={linksDirection}
    />
  );
};
