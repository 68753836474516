import { Button, IconFileCsv } from '@fortum/elemental-ui-fork';
import { Namespace } from 'i18next';
import { CSVLink } from 'react-csv';
import { Data, Headers } from 'react-csv/lib/core';
import { useTranslation } from 'react-i18next';
import { ngColors } from '@utils/styleOverride';

interface CSVExportButton_props {
  data: string | Data | (() => string | Data);
  filename: string;
  headers?: Headers;
}

export const CSVExportButton = (props: CSVExportButton_props) => {
  const { t } = useTranslation<Namespace>('common');
  return (
    <CSVLink data={props.data} headers={props.headers} filename={props.filename}>
      <Button leftIcon={IconFileCsv} status="primary" textColor={ngColors.white}>
        {t('common:exportToCSV')}
      </Button>
    </CSVLink>
  );
};
