import { CONTAINER_BORDER_RADIUS, Table } from '@components/styles';
import { CommonBoxProps, CssValue, TableProps } from '@fortum/elemental-ui-fork';
import { ContractByLocation } from '@models/contract';
import styled, { css } from 'styled-components';

export const defaultCellContainerStyle: CommonBoxProps = {
  minWidth: '172px',
};

export const companyCellContainerStyle: CommonBoxProps = {
  minWidth: '168px',
};

export const companyLocationCellContainerStyle: CommonBoxProps = {
  minWidth: '212px',
};

export const contractNoCellContainerStyle: CommonBoxProps = {
  minWidth: '145px',
};

type StyledTableProps = TableProps<ContractByLocation> & {
  $paginationHeight: CssValue;
};

export const StyledTable = styled(Table)<StyledTableProps>`
  border-radius: ${CONTAINER_BORDER_RADIUS};

  ${({ $paginationHeight }) => css`
    height: calc(100% - ${$paginationHeight});
  `};
`;
