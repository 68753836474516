import { staticBorderStyles, horizontalContainer, verticalContainer } from '@components/styles';
import { breakpoints, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const ChartTileContainer = styled.div`
  --row-gap: ${spacing.xxs};
  --title-height: 24px;

  height: 100%;
  ${verticalContainer};
  ${staticBorderStyles()};

  box-sizing: border-box;

  padding: ${spacing.xxs} ${spacing.xs};

  row-gap: var(--row-gap);
`;

export const OuterContainer = styled.div`
  --legend-width: 200px;
  --column-gap: ${spacing.m};

  @media ${`(max-width: ${breakpoints.xl}${'px'})`} {
    --legend-width: 130px;
    --column-gap: ${spacing.xxs};
  }

  ${horizontalContainer};
  column-gap: var(--column-gap);
  height: calc(100% - var(--title-height) - var(--row-gap));
  justify-content: center;
`;

export const ChartContainer = styled.div`
  max-width: calc(100% - var(--legend-width) - var(--column-gap));
  aspect-ratio: 1/1;
  max-height: 100%;
`;

export const LegendContainer = styled.div`
  ${verticalContainer};

  width: var(--legend-width);
  row-gap: ${spacing.xxxxs};
  align-self: center;
`;

export const LegendItemContainer = styled.div`
  ${horizontalContainer};

  column-gap: ${spacing.xxxs};
  align-items: center;
`;
