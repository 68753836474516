import { FC, ReactNode, useEffect, useState } from 'react';
import { usePersonalInformationForm } from './usePersonalInformationForm';
import { PermissionsInCompanyForm, UserPersonalInformation } from 'src/types/usersManagement';
import { useUsersPermissions } from '@data/hooks/useUsersPermissions';
import { UserPermission } from '@config/user';
import { usePermissionsPerCompanies } from './usePermissionsPerCompanies';
import { UserDetailsFormContextProps, UserDetailsFormContext } from './UserDetailsFormContext';

interface UserDetailsFormContextProviderProps {
  children: ReactNode;
  initialPersonalInformation?: UserPersonalInformation;
  initialInternalUserPermissions?: UserPermission[];
  initialExternalUserCompanies?: PermissionsInCompanyForm[];
  initialIsUserActive?: boolean;
}

export const UserDetailsFormContextProvider: FC<UserDetailsFormContextProviderProps> = ({
  children,
  initialPersonalInformation,
  initialInternalUserPermissions,
  initialExternalUserCompanies,
  initialIsUserActive,
}) => {
  const [userActive, toggleUserActive] = useState<boolean | undefined>(initialIsUserActive);
  const personalInformationFormHandler = usePersonalInformationForm(initialPersonalInformation);
  const permissionsPerCompaniesHandler = usePermissionsPerCompanies(initialExternalUserCompanies);
  const [selectedInternalUserPermissions, setSelectedInternalUserPermissions] = useState<UserPermission[]>(
    initialInternalUserPermissions ?? [],
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  useEffect(
    () => initialInternalUserPermissions && setSelectedInternalUserPermissions(initialInternalUserPermissions),
    [initialInternalUserPermissions],
  );

  useEffect(() => {
    toggleUserActive(initialIsUserActive);
  }, [initialIsUserActive]);

  useEffect(() => {
    setIsFormValid(
      personalInformationFormHandler.isPersonalInformationValid &&
        permissionsPerCompaniesHandler.permissionsInCompanyHandler.tilesWithErrors.length === 0,
    );
  }, [
    personalInformationFormHandler.isPersonalInformationValid,
    permissionsPerCompaniesHandler.permissionsInCompanyHandler.tilesWithErrors,
  ]);

  const usersPermissionsQueryResult = useUsersPermissions();

  const validateForm = () => {
    const validatedPersonalInformation = personalInformationFormHandler.validatePersonalInformation();
    const validatedPermissionsInCompanies =
      permissionsPerCompaniesHandler.permissionsInCompanyHandler.validateBusinessPartnerForm();

    // Only external users have business partners (validPermissionsInCompanies) currently
    const validationResult =
      personalInformationFormHandler.personalInformation.userType === 'EXTERNAL'
        ? validatedPersonalInformation && validatedPermissionsInCompanies
        : validatedPersonalInformation;

    setIsFormValid(validationResult);

    return validationResult;
  };

  const value: UserDetailsFormContextProps = {
    personalInformationFormHandler,
    permissionsPerCompaniesHandler,
    usersPermissionsQueryResult,
    selectedInternalUserPermissionsHandler: {
      selectedPermissions: selectedInternalUserPermissions,
      setSelectedPermissions: setSelectedInternalUserPermissions,
    },
    validateForm,
    userActive,
    toggleUserActive,
    isFormValid,
  };

  return <UserDetailsFormContext.Provider value={value}>{children}</UserDetailsFormContext.Provider>;
};
