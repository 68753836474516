import { RowKey, TableColumnProps } from '@fortum/elemental-ui-fork';
import { Order } from '@models/orders';
import { t } from 'i18next';

export const getColumnsBaseConfiguration = (): {
  [key in string]: Pick<TableColumnProps<Order, RowKey>, 'key' | 'name' | 'sortable'>;
} => ({
  orderHandlingStatus: {
    key: 'orderHandlingStatus',
    name: t('orders:table.status'),
    sortable: false,
  },
  executionDate: {
    key: 'executionDate',
    name: t('orders:table.executionDate'),
    sortable: false,
  },
  orderType: {
    key: 'orderType',
    name: t('orders:table.orderType'),
    sortable: false,
  },
  wasteDescription: {
    key: 'wasteDescription',
    name: t('orders:table.wasteType'),
    sortable: false,
  },
  transportType: {
    key: 'transportType',
    name: t('orders:table.transportType'),
    sortable: false,
  },
  equipmentTranslations: {
    key: 'equipmentTranslations',
    name: t('orders:table.containerType'),
    sortable: false,
  },
  businessPartnerName: {
    key: 'businessPartnerName',
    name: t('orders:table.businessPartner'),
    sortable: false,
  },
  site: {
    key: 'site',
    name: t('orders:table.site'),
    sortable: false,
  },
  orderNo: {
    key: 'orderNo',
    name: t('orders:table.orderNo'),
    sortable: false,
  },
  contractNo: {
    key: 'contractNo',
    name: t('orders:table.contractNo'),
    sortable: false,
  },
  tourNo: {
    key: 'tourNo',
    name: t('orders:table.tourNo'),
    sortable: false,
  },
});
