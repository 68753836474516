import { UserPermission } from '@config/user';
import { useUsersPermissions } from '@data/hooks/useUsersPermissions';
import { Dispatch, SetStateAction, createContext } from 'react';
import { UsePermissionsPerCompanies } from './usePermissionsPerCompanies';
import { UsePersonalInformationForm } from './usePersonalInformationForm';

type UseUsersPermissionsQueryResultType = ReturnType<typeof useUsersPermissions>;

export type UserDetailsFormContextProps = {
  personalInformationFormHandler: UsePersonalInformationForm;
  permissionsPerCompaniesHandler: UsePermissionsPerCompanies;
  usersPermissionsQueryResult: Pick<UseUsersPermissionsQueryResultType, 'isLoading' | 'isError' | 'data'>;
  selectedInternalUserPermissionsHandler: {
    selectedPermissions: UserPermission[];
    setSelectedPermissions: Dispatch<SetStateAction<UserPermission[]>>;
  };
  validateForm: () => boolean;
  userActive?: boolean;
  toggleUserActive: Dispatch<SetStateAction<boolean | undefined>>;
  isFormValid: boolean;
};

export const UserDetailsFormContext = createContext<UserDetailsFormContextProps>({
  personalInformationFormHandler: {} as UsePersonalInformationForm,
  usersPermissionsQueryResult: {} as UseUsersPermissionsQueryResultType,
  permissionsPerCompaniesHandler: {} as UsePermissionsPerCompanies,
  selectedInternalUserPermissionsHandler: {
    selectedPermissions: [],
    setSelectedPermissions: () => undefined,
  },
  validateForm: () => false,
  userActive: undefined,
  toggleUserActive: {} as Dispatch<SetStateAction<boolean | undefined>>,
  isFormValid: false,
});
