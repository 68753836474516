import { SupportedLanguage } from '@common/languages';
import { ReportApp, ReportConnectionConfig } from 'src/types/qlik';
import { co2ReportConfigSchemaValidator } from './schemas';
import { getQlikConnectionConfig } from '../connection';
import { Co2ReportConfig, Co2ReportObjects } from './types';
import { createSchemaErrorMessage, getReportApp } from '@utils/qlik';
export * from './types';

const validateSchemas = (): ReportConnectionConfig<Co2ReportConfig, Co2ReportObjects> => {
  const connectionConfig = getQlikConnectionConfig();

  if (!import.meta.env.VITE_CO2_REPORT_CONFIG) {
    throw Error('CO2 report config not found');
  }

  const reportConfig = JSON.parse(import.meta.env.VITE_CO2_REPORT_CONFIG);

  const validate = co2ReportConfigSchemaValidator();

  if (!validate(reportConfig)) {
    throw Error('Report config does not match the schema: ' + createSchemaErrorMessage(validate.errors));
  }

  return { reportConfig, connectionConfig };
};

export const getCo2ReportApp = (lang: SupportedLanguage): ReportApp<Co2ReportObjects> => {
  const validatedSchemas = validateSchemas();

  return getReportApp(validatedSchemas, lang);
};
