import { FC, ReactNode, useState } from 'react';
import { useOrdersFiltersHandler } from './useOrdersFiltersHandler';
import { SortByValue } from './config';
import { INITIAL_PAGE_NUMBER } from '@common/consts';
import { OrdersFiltersContext, OrdersFiltersContextProps } from './OrdersFiltersContext';

interface OrdersFiltersContextProviderProps {
  children: ReactNode;
}
export const OrdersFiltersContextProvider: FC<OrdersFiltersContextProviderProps> = ({ children }) => {
  const filtersHandler = useOrdersFiltersHandler(false);
  const [sortBy, setSortBy] = useState<SortByValue>('status');
  const [activePage, setActivePage] = useState<number>(INITIAL_PAGE_NUMBER);

  const contextValue: OrdersFiltersContextProps = {
    ...filtersHandler,
    sortBy,
    setSortBy,
    activePage,
    setActivePage,
  };

  return <OrdersFiltersContext.Provider value={contextValue}>{children}</OrdersFiltersContext.Provider>;
};
