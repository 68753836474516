import { getEnvProperty } from '@utils/general';
import { isArray } from 'lodash';

const defaultUserAccountLanguages = ['en', 'fi', 'sv'];

export const getUserAvailableLanguages = (): string[] => {
  const envValue = getEnvProperty('VITE_USER_ACCOUNT_LANGUAGES');

  if (!envValue) return defaultUserAccountLanguages;

  const userAccountLanguages = JSON.parse(envValue);

  return isArray(userAccountLanguages) ? userAccountLanguages : defaultUserAccountLanguages;
};
