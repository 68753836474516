import { HorizontalContainer, horizontalContainer, staticBorderStyles, verticalContainer } from '@components/styles';
import {
  ColProps,
  CommonForwardLinkProps,
  ContentTextProps,
  ForwardLink,
  IconProps,
  breakpoints,
  colors,
  fontSizes,
  spacing,
} from '@fortum/elemental-ui-fork';
import styled from 'styled-components';
import { ngColors } from '@utils/styleOverride';

export const CustomerServiceIconRoundedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  border: 2px solid ${ngColors.orange};
  box-sizing: border-box;
`;

export const LeftColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${`(max-width: ${breakpoints.xl}${'px'})`} {
    row-gap: ${spacing.xxs};
  }

  row-gap: ${spacing.xs};
  height: 100%;
  box-sizing: border-box;
`;

export const SocialMediaContainer = styled.div`
  ${horizontalContainer};
  padding: ${spacing.xs};
  background-color: ${colors.lightGrey};

  align-items: center;
  flex: 1;
  box-sizing: border-box;
`;

export const ETrainingContainer = styled.div`
  ${horizontalContainer};
  padding: ${spacing.xs};
  background-color: ${colors.lightGrey};

  align-items: center;
  flex: 1;
  box-sizing: border-box;
`;

export const ContactUsTileContainer = styled.div`
  ${verticalContainer};
  ${staticBorderStyles()};
  align-items: center;
  padding: ${spacing.xs};
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
`;

export const ContactUsHeader = styled(HorizontalContainer)`
  align-items: center;
  margin-bottom: ${spacing.xxxs};
  gap: ${spacing.xxxs};
`;

export const contactUsHeaderTextStyles: ContentTextProps = {
  fontSize: fontSizes.xl,
};

export const contactUsDescriptionTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  mb: spacing.xs,
  textAlign: 'center',
};

export const NonWrappingForwardLink = styled(ForwardLink)`
  white-space: nowrap;
`;

export const iconCustomerServiceStyles: IconProps = {
  size: 38,
  color: ngColors.orange,
};

export const iconConstructionWorkerStyles: IconProps = {
  size: 48,
  marginRight: spacing.xxs,
  color: ngColors.orange,
};

export const iconLinkedinStyles: IconProps = {
  size: 40,
  marginRight: spacing.xxs,
  color: colors.inkGrey,
};

export const columnStyles: ColProps = {
  xl: 6,
};

export const leftColumnStyles: ColProps = {
  ...columnStyles,
  mb: { xl: 0, xs: spacing.xxs },
};

export const descriptionTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  mr: spacing.xxs,
};

export const forwardLinkStyles: CommonForwardLinkProps = {
  color: ngColors.orange,
};

export const positionedForwardLinkStyles: CommonForwardLinkProps = {
  ...forwardLinkStyles,
  marginLeft: 'auto',
};
