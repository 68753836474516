import { verticalContainer } from '@components/styles';
import {
  BoxProps,
  ButtonProps,
  CommonIconButtonProps,
  CommonSelectProps,
  ContentTextProps,
  fontSizes,
  spacing,
} from '@fortum/elemental-ui-fork';
import { ngColors } from '@utils/styleOverride';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  ${verticalContainer};

  padding: ${spacing.m} ${spacing.xs};
  height: 100%;
  box-sizing: border-box;
`;

export const headerBoxStyles: BoxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: spacing.s,
};

export const contentBoxStyles: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.xxs,
  overflowY: 'auto',
  flex: 1,
  mb: spacing.xxs,
};

export const footerBoxStyles: BoxProps = {
  display: 'flex',
  gap: spacing.xs,
  marginTop: 'auto',
};

export const contentTextStyles: ContentTextProps = {
  fontSize: fontSizes.l,
};

export const iconButtonStyles: CommonIconButtonProps = {
  size: spacing.xs,
  status: 'plain',
};

export const clearButtonStyles: ButtonProps = {
  flex: 1,
  status: 'primary',
  textColor: ngColors.white,
};

export const applyButtonStyles: ButtonProps = {
  flex: 1,
  status: 'primary',
  textColor: ngColors.white,
};

export const selectStyles: Omit<CommonSelectProps<string>, 'items'> = {
  borderStyle: 'full',
  variant: 'condensed',
};
