import { Namespace } from '@config/i18n';
import { SelectItem } from '@fortum/elemental-ui-fork';
import { translate } from '@utils/internationalization';
import { t } from 'i18next';

export const displayMultiselectAppliedValues = (selectedValues: string[]) =>
  selectedValues.length > 1 ? t('common:multiselect', { count: selectedValues.length }) : selectedValues[0];

export const moveSelectedToStart = (items: SelectItem<string>[], selectedItemsValues: string[]) => {
  const head = items.filter(item => selectedItemsValues.some(selected => selected === item.value));
  const tail = items.filter(item => !selectedItemsValues.some(selected => selected === item.value));

  return [...head, ...tail];
};

export const filterSelectItems = <T>(items: SelectItem<T>[], filter: string) =>
  filter ? items.filter(item => item.name.toLowerCase().includes(filter.toLowerCase())) : [];

export const mapToSelectItems = <T extends string>(data: T[], nameTransformingFn?: (data: T) => string): SelectItem<T>[] =>
  data.map(datum => ({
    value: datum,
    name: nameTransformingFn ? nameTransformingFn(datum) : datum,
  }));

export const mapToTranslatedSelectItems = <T extends string>(
  data: T[],
  namespace: Namespace,
  subNamespace: string,
): SelectItem<T>[] => mapToSelectItems(data, datum => translate(datum.toUpperCase(), namespace, subNamespace));
