import { FailedToLoadDataNote } from '@components/index';
import { HorizontalContainer, verticalContainer, VerticalContainer } from '@components/styles';
import {
  breakpoints,
  colors,
  ColProps,
  CommonColProps,
  CommonGridProps,
  ContentTextProps,
  fontSizes,
  fontWeights,
  IconButtonProps,
  PlaceholderProps,
  spacing,
} from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const HeaderContainer = styled(HorizontalContainer)`
  justify-content: space-between;
  align-items: center;
`;

export const PreviewContainer = styled(VerticalContainer)`
  gap: ${spacing.xs};
  min-height: 100%;
  box-sizing: border-box;
`;

export const OrderDetailsSummaryContainer = styled(VerticalContainer)`
  gap: ${spacing.xxxs};
`;

export const summaryPreviewColProps: CommonColProps = {
  xxs: 2,
};

export const headerTextStyles: ContentTextProps = {
  fontSize: fontSizes.xl,
  fontWeight: fontWeights.regular,
};

export const closeIconButtonStyles: Omit<IconButtonProps, 'icon'> = {
  size: 24,
  color: colors.inkGrey,
};

export const VerticalContainerWithBigGap = styled.div`
  ${verticalContainer};

  gap: ${spacing.xs};
`;

export const gridStyles: CommonGridProps = {
  mh: { xs: 0 },
  maxWidth: { xxl: '100%' },
  ph: { xs: 0 },
};

export const columnStyles: ColProps = {
  xxs: 12,
  xl: 6,
};

export const wasteColumnStyles: ColProps = {
  ...columnStyles,
  mb: { xs: spacing.xs, xl: 0 },
};

export const PlaceholdersContainer = styled(HorizontalContainer)`
  gap: ${spacing.xs};
  flex: 1;

  @media ${`(max-width: ${breakpoints.xl}${'px'})`} {
    flex-wrap: wrap;
  }
`;

export const placeholderStyles: PlaceholderProps = {
  width: '100%',
  height: 'auto',
};

export const StyledErrorView = styled(FailedToLoadDataNote)`
  margin-top: 48px;
`;
