import { useCallback, useEffect, useState } from 'react';
import { NotificationType } from './Notification';
import { NOTIFICATION_AUTO_CLOSE_DELAY_MS } from '@common/consts';

export interface UseNotification {
  displayNotification: NotificationType | null;
  notificationMessage: string | null;
  setDisplayErrorNotification: (message?: string) => void;
  setDisplaySuccessfulNotification: (message?: string) => void;
  closeNotification: () => void;
}

export const useNotification = (autoCloseDelay: number = NOTIFICATION_AUTO_CLOSE_DELAY_MS): UseNotification => {
  const [notificationMessage, setNotificationMessage] = useState<string | null>(null);
  const [displayNotification, setDisplayNotification] = useState<NotificationType | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (displayNotification === 'success') {
        setDisplayNotification(null);
      }
    }, autoCloseDelay);

    return () => clearTimeout(timeout);
  }, [displayNotification]);

  const setDisplaySuccessfulNotification = useCallback((message?: string) => {
    setDisplayNotification('success');

    if (message) {
      setNotificationMessage(message);
    }
  }, []);

  const setDisplayErrorNotification = useCallback((message?: string) => {
    setDisplayNotification('error');

    if (message) {
      setNotificationMessage(message);
    }
  }, []);

  const closeNotification = useCallback(() => {
    setDisplayNotification(null);
    setNotificationMessage(null);
  }, []);

  return {
    displayNotification,
    notificationMessage,
    setDisplayErrorNotification,
    setDisplaySuccessfulNotification,
    closeNotification,
  };
};
