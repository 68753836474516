import { createContext } from 'react';
import { UseServicesSelectorReturnType } from './useServicesSelector';

type ServicesSelectorContextProps = UseServicesSelectorReturnType;

export const ServicesSelectorContext = createContext<ServicesSelectorContextProps>({
  filters: {} as UseServicesSelectorReturnType['filters'],
  sites: {} as UseServicesSelectorReturnType['sites'],
  clearAllSelections: () => undefined,
});
