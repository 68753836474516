import { FC, ReactNode, useRef } from 'react';
import { CreationForm } from 'src/types/ordersCreation';
import { useSingleOrderCreationForm } from './useSingleOrderCreationForm';
import { Contract } from '@models/contract';
import { OrderType } from '@models/orders';
import { Contact } from '@models/contact';
import { UseQueryResult } from '@tanstack/react-query';

import { SingleOrderCreationContext, SingleOrderCreationContextProps } from './SingleOrderCreationContext';

interface SingleOrderCreationContextProviderProps {
  children: ReactNode;
  contract: Contract;
  availableOrderTypes: OrderType[];
  contractIdentifier: string;
  orderForm: CreationForm['forms'][number];
  contactsDataHandler: UseQueryResult<Contact[], Error>;
}

export const SingleOrderCreationContextProvider: FC<SingleOrderCreationContextProviderProps> = ({
  contract,
  contactsDataHandler,
  availableOrderTypes,
  contractIdentifier,
  orderForm,
  children,
}) => {
  const orderTileRef = useRef<HTMLDivElement>(null);
  const creationHandler = useSingleOrderCreationForm(orderForm, contractIdentifier);

  const contextValue: SingleOrderCreationContextProps = {
    ...creationHandler,
    availableOrderTypes,
    contract,
    form: orderForm,
    contactsDataHandler,
    contractIdentifier,
    orderTileRef,
  };

  return <SingleOrderCreationContext.Provider value={contextValue}>{children}</SingleOrderCreationContext.Provider>;
};
