import styled from 'styled-components';
import { IconCustomerReleases, Modal, colors, px2rem, spacing } from '@fortum/elemental-ui-fork';
import { horizontalContainer, verticalContainer } from '@components/styles';

export const SelectionManagementContainer = styled.section`
  ${horizontalContainer};

  column-gap: ${spacing.xs};
`;

export const ButtonsContainer = styled.div`
  ${verticalContainer};

  justify-content: center;
  row-gap: ${spacing.xxs};
`;

export const StyledModal = styled(Modal)`
  --modal-content-max-height: ${px2rem(572)};
  --modal-content-max-width: ${px2rem(770)};

  > div {
    max-height: var(--modal-content-max-height);
    max-width: var(--modal-content-max-width);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ModalInputsContainer = styled.div`
  ${verticalContainer};

  width: 510px;
  row-gap: ${spacing.xxs};
  margin-top: ${spacing.s};
`;

export const ModalButtonsContainer = styled.div`
  ${horizontalContainer};

  justify-content: center;

  column-gap: ${spacing.xs};
  margin-top: 96px;
`;

/**
 * It is necessary to set the color this way,
 * because QlikSense styles overwrite color set using a component prop to rgb(89, 89, 89)
 */
export const StyledIconCustomerReleases = styled(IconCustomerReleases)`
  color: ${colors.inkGrey};
`;
