import { FC, ReactNode } from 'react';
import { Container, FullWidthGrid } from './style';
import { Testable } from '@components/types';

interface TableTileComponent extends Testable {
  children: ReactNode;
}

export const TableTile: FC<TableTileComponent> = ({ children, 'data-testid': dataTestId }) => {
  return (
    <Container data-testid={dataTestId}>
      <FullWidthGrid>{children}</FullWidthGrid>
    </Container>
  );
};
