import { CONTAINER_BORDER_RADIUS, Table, staticBorderStyles } from '@components/styles';
import { colors, CommonBoxProps, IconProps, Modal, spacing, TableProps } from '@fortum/elemental-ui-fork';
import styled, { css } from 'styled-components';
import type { OrderSummary } from 'src/types/ordersCreation';
import { CompanyDetails, CompanyDetailsProps } from '@components/CompanyDetails';

const FAILED_ORDER_TEXT_COLOR = colors.silverGrey;

interface CellContainerProps {
  $error: boolean;
}

export const CellContainer = styled.div<CellContainerProps>`
  color: ${({ $error }) => ($error ? FAILED_ORDER_TEXT_COLOR : 'unset')};
`;

type StyledCompanyDetailsProps = CompanyDetailsProps & {
  $error: boolean;
};

export const StyledCompanyDetails = styled(CompanyDetails)<StyledCompanyDetailsProps>`
  p {
    color: ${({ $error }) => ($error ? FAILED_ORDER_TEXT_COLOR : 'unset')};
  }
`;

const columnsStylesWithError = () => css`
  td:first-child,
  th:first-child {
    padding: ${spacing.xxs} ${spacing.xxxs};
  }

  td:nth-child(2),
  th:nth-child(2) {
    padding-left: 0px;
  }
`;

type StyledTableProps = TableProps<OrderSummary> & { $failedOrders: boolean };

export const StyledTable = styled(Table)<StyledTableProps>`
  ${staticBorderStyles()};

  table {
    width: 100%;
  }

  th {
    white-space: normal;
  }

  ${({ $failedOrders }) => $failedOrders && columnsStylesWithError()}
`;

export const narrowCellContainerStyle: CommonBoxProps = {
  minWidth: '160px',
};

export const mediumCellContainerStyle: CommonBoxProps = {
  minWidth: '168px',
};

export const wideCellContainerStyle: CommonBoxProps = {
  minWidth: '294px',
};

export const cellWithTextAlignLeftStyle: CommonBoxProps = {
  textAlign: 'left',
};

export const warningIconStyles: IconProps = {
  size: 24,
  color: colors.punchRed,
};

export const StyledModal = styled(Modal)`
  --spacing-vertical: 48px;

  max-width: 1296px;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 2 * var(--spacing-vertical));
  box-sizing: border-box;
  justify-content: start;
  border-radius: ${CONTAINER_BORDER_RADIUS};

  margin: 0 72px;

  overflow: auto;

  > div:nth-child(1) {
    padding: ${spacing.xs};
    margin: 0;
  }

  > div:nth-child(2) {
    padding: 0 ${spacing.xs} ${spacing.xs};
    margin: 0;
    height: 100%;
    box-sizing: border-box;
  }
`;
