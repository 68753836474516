import ellipse from '@assets/icons/EllipseOrange.png';
import selectedEllipse from '@assets/icons/SelectedEllipseOrange.png';
import { CenteredLoader } from '@components/layout/styles';
import { logEvent } from '@config/azureInsights';
import { Namespace } from '@config/i18n';
import { useMapLocations } from '@data/hooks/useAddressesLocation';
import { Box, Col, ContentText, Row, RowProps, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui-fork';
import { MapLocation } from '@models/home';
import { EmptyLocationLayout } from '@routes/home/components/Map/EmptyLocation';
import { MapLocationLayout } from '@routes/home/components/Map/MapLocationLayout';
import { Container, StyledErrorView } from '@routes/home/components/style';
import L, { LatLng, LeafletMouseEvent, Marker } from 'leaflet';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const mapId = 'leafletMap';
const iconSize = 20;

const ellipseIcon = L.icon({
  iconUrl: ellipse,
  iconSize: [iconSize, iconSize],
  iconAnchor: [iconSize, iconSize],
});

const selectedEllipseIcon = L.icon({
  iconUrl: selectedEllipse,
  iconSize: [iconSize, iconSize],
  iconAnchor: [iconSize, iconSize],
});

export const MapLayout: FC<RowProps> = props => {
  const { data: locationData, isLoading, fetchStatus, error } = useMapLocations();
  const [selectedLocation, setSelectedLocation] = useState<MapLocation>();
  const previewSelection = useRef<Marker | null>(null);
  const { t } = useTranslation<Namespace>('home');
  const mapRef = useRef<L.Map | L.LayerGroup | null>(null);
  const zoomLevel = 5;

  const areCoordinatesEqual = useCallback((location: MapLocation, latLng: LatLng) => {
    return location.latitude === latLng.lat && location.longitude === latLng.lng;
  }, []);

  const selectLocation = useCallback(
    (lme: LeafletMouseEvent) => {
      const mapLocation = locationData && locationData.locations.find(location => areCoordinatesEqual(location, lme.latlng));

      setSelectedLocation(mapLocation);

      if (mapLocation) {
        lme.target.setIcon(areCoordinatesEqual(mapLocation, lme.latlng) ? selectedEllipseIcon : ellipseIcon);
      }

      if (previewSelection.current) {
        previewSelection.current.setIcon(ellipseIcon);
      }
      previewSelection.current = lme.target;
    },
    [locationData, previewSelection],
  );

  const handleLocationSelection = useCallback(
    (e: LeafletMouseEvent, location: MapLocation) => {
      selectLocation(e);
      logEvent('homeLocationElementClicked', { locationBusinessPartnerName: location.businessPartnerName });
    },
    [selectLocation],
  );

  // TODO: open topic how to set if no coordinates latitude: 60.167603, longitude: 24.941892
  useEffect(() => {
    if (!document.getElementById(mapId)) {
      return;
    }

    const locationView =
      locationData &&
      locationData.locations.length > 0 &&
      locationData.locations[0].longitude !== null &&
      locationData.locations[0].latitude !== null
        ? locationData.locations[0]
        : { latitude: 60.167603, longitude: 24.941892 };

    mapRef.current = L.map(mapId).setView([locationView.latitude, locationView.longitude], zoomLevel);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(mapRef.current);

    if (locationData && locationData.locations.length > 0 && mapRef.current) {
      const map = mapRef.current;
      locationData.locations.forEach(location => {
        if (location.longitude && location.latitude) {
          const icon =
            selectedLocation?.latitude === location.latitude && selectedLocation?.longitude === location.longitude
              ? selectedEllipseIcon
              : ellipseIcon;

          L.marker([location.latitude, location.longitude], { icon })
            .addTo(map)
            .on('click', lme => handleLocationSelection(lme, location));
        }
      });
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, [locationData, isLoading, error, fetchStatus]);

  const mainView = useMemo(() => {
    if (isLoading || fetchStatus !== 'idle') return <CenteredLoader />;

    if (error) return <StyledErrorView />;

    if (!isLoading && !error)
      return (
        <Box display="flex" flex={1} p={spacing.xs}>
          <Box display="flex" flex="2">
            <div id={mapId} />
          </Box>
          <Box display="flex" flex="1" justifyContent="center" alignItems="center" flexDirection="column">
            {selectedLocation ? <MapLocationLayout location={selectedLocation} /> : <EmptyLocationLayout />}
          </Box>
        </Box>
      );
  }, [isLoading, error, fetchStatus, selectedLocation]);

  return (
    <Row {...props}>
      <Col>
        <ContentText fontSize={fontSizes.m} fontWeight={fontWeights.medium} mb={spacing.xxxs}>
          {t('map.location')}
        </ContentText>
        <Container>{mainView}</Container>
      </Col>
    </Row>
  );
};
