import { BoxPropValue, spacing } from '@fortum/elemental-ui-fork';
import { LIMITED_LAYOUT_MAX_WIDTH_PX } from '@components/layout/styles';

export const GRID_GAP = spacing.xs;

export const gridMarginHorizontal: BoxPropValue = {
  xs: spacing.xxs,
  m: spacing.xs,
  xxl: spacing.xs,
};

export const gridMaxWidth: BoxPropValue = {
  xxl: `calc(${LIMITED_LAYOUT_MAX_WIDTH_PX} - var(--app-navigation-width) - 2 * ${gridMarginHorizontal.xxl})`,
  m: `calc(100% - 2 * ${gridMarginHorizontal.m})`,
  xs: `calc(100% - 2 * ${gridMarginHorizontal.xs})`,
};

export const gridWidth: BoxPropValue = {
  xxl: `calc(100% - 2 * ${gridMarginHorizontal.xxl})`,
};
