import { SelectItem } from '@fortum/elemental-ui-fork';
import { OrderFilters, OrdersSelectedFilters, OrdersSelectedFiltersWithSearch } from '@models/filters';
import { HandlingStatusGroup } from '@models/orders';

type Setters = {
  [key in keyof Omit<OrderFilters, 'timePeriod' | 'orderStatus'>]: (value: string[]) => void;
} & {
  orderStatus: (value: HandlingStatusGroup[]) => void;
} & {
  timePeriod: (value: string) => void;
} & {
  search: (value: string) => void;
};

export type SelectItems = {
  [key in keyof OrderFilters]: SelectItem<string>[];
} & {
  orderStatus: SelectItem<HandlingStatusGroup>[];
} & {
  timePeriod: SelectItem<string>[];
};

export type FiltersPrecedence = { key: keyof OrdersSelectedFiltersWithSearch; order: number }[];

export interface OrdersFiltersHandlerReturnType {
  selectedFiltersValues: OrdersSelectedFiltersWithSearch;
  setters: Setters;
  selectItems: SelectItems;
  reset: () => void;
  setAllExceptSearch: (values: OrdersSelectedFilters, precedence: FiltersPrecedence) => void;
  filtersPrecedence: FiltersPrecedence;
  setAll: (values: OrdersSelectedFiltersWithSearch, precedence: FiltersPrecedence) => void;
  isLoading: boolean;
  isError: boolean;
  debouncedSelectedFiltersValuesWithPrecedence: {
    selectedFiltersValues: OrdersSelectedFiltersWithSearch;
    filtersPrecedence: FiltersPrecedence;
  };
}

export const ALL_TIME_PERIOD = 'ALL';

//Those are keys -> values from SelectItem, not names. But for search it is an actual value
export const SELECTED_FILTERS_DEFAULT_VALUES: OrdersSelectedFiltersWithSearch = {
  orderStatus: [],
  orderType: [],
  city: [],
  containerType: [],
  transportType: [],
  businessPartner: [],
  wasteDescription: [],
  search: '',
  timePeriod: '',
};

export const SELECT_ITEMS_INITIAL_VALUES: SelectItems = {
  orderStatus: [],
  orderType: [],
  city: [],
  containerType: [],
  transportType: [],
  businessPartner: [],
  wasteDescription: [],
  timePeriod: [],
};

/**
 * DEFAULT_PRECEDENCE depends directly on the default timePeriod value.
 * If it's ALL, then in fact nothing is filtered, thus there is no precedence
 * */
export const DEFAULT_PRECEDENCE: FiltersPrecedence = [];
