import { useContext, useEffect, useState } from 'react';
import { QlikEngineContext } from '@components/qlik/QlikEngineContext';
import { getCurrentSelections } from '@components/qlik/utils/currentSelections';
import { mapToSelect, validateMandatorySelections } from './utils';
import { Selection } from './types';
import { logException } from '@config/azureInsights';

export const useDefaultSelections = (selections: Selection[], mandatorySelections?: Selection[]) => {
  const { enigma } = useContext(QlikEngineContext);

  const [mandatorySelectionsValidationInProgress, setMandatorySelectionsValidationInProgress] = useState(
    mandatorySelections && mandatorySelections.length > 0,
  );
  const [mandatorySelectionsError, setMandatorySelectionsError] = useState<boolean>(false);

  useEffect(() => {
    const selectDefaultValues = async () => {
      if (selections.length === 0 && (!mandatorySelections || mandatorySelections.length === 0)) {
        return;
      }

      if (!enigma) {
        setMandatorySelectionsValidationInProgress(false);
        setMandatorySelectionsError(!!(mandatorySelections && mandatorySelections.length > 0));
        return;
      }
      await enigma.clearAll();

      const results = await Promise.allSettled(mapToSelect(selections, enigma));
      results.forEach(result => {
        if (result.status === 'rejected') {
          console.error(`Failed to set default selection`);
          logException(result.reason);
        }
      });
      if (mandatorySelections && mandatorySelections.length > 0) {
        setMandatorySelectionsValidationInProgress(true);
        setMandatorySelectionsError(false);

        try {
          await Promise.all(mapToSelect(mandatorySelections, enigma));

          const currentSelections = await getCurrentSelections(enigma);

          const mandatorySelectionsSelected = currentSelections
            ? validateMandatorySelections(mandatorySelections, currentSelections)
            : false;

          setMandatorySelectionsError(!mandatorySelectionsSelected);
        } catch (_e) {
          //Error is thrown when field not found
          setMandatorySelectionsError(true);
          return;
        } finally {
          setMandatorySelectionsValidationInProgress(false);
        }
      }
    };

    selectDefaultValues();
  }, [enigma?.id]);

  return {
    mandatorySelectionsError,
    mandatorySelectionsValidationInProgress,
  };
};
