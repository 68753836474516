import { InputFieldProps } from '@components/InputField';
import { HorizontalContainer, staticBorderStyles, VerticalContainer } from '@components/styles';
import { ButtonProps, colors, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';
import { PermissionsForm } from './PermissionsForm';
import { PageNavigationHeader } from '@components/PageNavigationHeader';
import { ngColors } from '@utils/styleOverride';

const CONTENT_WIDTH = '680px';

export const UserCreationContainer = styled(VerticalContainer)`
  flex: 1;
  background-color: ${colors.snowWhite};
  padding: ${spacing.xxs} 0;
`;

export const ContentContainer = styled(VerticalContainer)`
  width: ${CONTENT_WIDTH};
  align-self: center;
  gap: ${spacing.xxs};
`;

export const FormContainer = styled(VerticalContainer)`
  gap: ${spacing.xxs};
  overflow: auto;
  padding: 0 48px ${spacing.xs};
  flex: 1;
`;

export const PersonalInformationContainer = styled(VerticalContainer)`
  gap: ${spacing.xxs};
`;

export const PersonalInformationFormRow = styled(HorizontalContainer)`
  gap: ${spacing.xxs};
`;

export const VerticalContainerWithBigGap = styled(VerticalContainer)`
  gap: ${spacing.xs};
`;

export const VerticalContainerWithSmallGap = styled(VerticalContainer)`
  gap: ${spacing.xxs};
`;

export const sizeStyles: Pick<InputFieldProps, 'size' | 'width'> = {
  width: '100%',
  size: 's',
};

export const PermissionsFormWithPaddingBottom = styled(PermissionsForm)`
  padding-bottom: ${spacing.s};
`;

export const StyledPageNavigationHeader = styled(PageNavigationHeader)`
  background-color: ${colors.lightGrey};
  width: ${CONTENT_WIDTH};
  align-self: center;
`;

export const buttonStyles: ButtonProps = {
  alignSelf: 'end',
  status: 'primary',
  textColor: ngColors.white,
};

export const UserStatusToggleContainer = styled(HorizontalContainer)`
  ${staticBorderStyles('2px')};

  height: 64px;
  padding: 0 ${spacing.xs};

  box-sizing: border-box;

  align-items: center;
  justify-content: space-between;
`;
