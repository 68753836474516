import { serviceTypes } from '@config/services';
import { EquipmentServiceType, equipmentServiceTypes } from '@models/contract';
import { Contract } from '@models/contract';
import { ServiceSummary } from 'src/types/ordersCreation';
import { hasValue } from './dataOperations';
import { mapRawEquipmentServiceTypesToOrderTypes } from './ordersCreation';
import { ServiceTypeValue, serviceTypeValues } from '@models/service';

export const mapContractToServiceSummary = (contract: Contract): ServiceSummary => {
  const availableOrderTypes = mapRawEquipmentServiceTypesToOrderTypes(contract.serviceDetails.equipmentDetails.serviceTypes);

  const contactPerson = hasValue(contract.contractDetails.contactDetails?.code)
    ? contract.contractDetails.contactDetails
    : undefined;
  const orderLevelReference = hasValue(contract.contractDetails.invoicingDetails.orderReference)
    ? contract.contractDetails.invoicingDetails.orderReference
    : undefined;

  return {
    wasteDescription: contract.serviceDetails.wasteDetails.description,
    serviceType: contract.generalInfo.serviceType,
    equipmentDescription: contract.serviceDetails.equipmentDetails.description,
    transportType: contract.serviceDetails.transportDetails.type,
    company: {
      customer: contract.generalInfo.customer,
      location: contract.generalInfo.location,
    },
    availableOrderTypes,
    contactPerson,
    orderLevelReference,
  };
};

export const isSupportedServiceTypeValue = (serviceTypeValue: string): serviceTypeValue is ServiceTypeValue =>
  serviceTypeValues.includes(serviceTypeValue as ServiceTypeValue);

export const isEquipmentSalesService = (serviceType: string) =>
  isSupportedServiceTypeValue(serviceType) && serviceTypes.equipmentSalesService === serviceType;

export const containsWasteDetails = (contract: Contract) =>
  contract.generalInfo.wasteDescription && !isEquipmentSalesService(contract.generalInfo.serviceType);

export const isEquipmentServiceType = (serviceType: string): serviceType is EquipmentServiceType =>
  equipmentServiceTypes.includes(serviceType as EquipmentServiceType);
