import { ContentText, IconWarning } from '@fortum/elemental-ui-fork';
import { FC, useContext, useMemo } from 'react';
import {
  SelectedBusinessPartnersContainer,
  bpsTitleStyles,
  SelectedPartnersText,
  selectedBpsTextStyles,
  errorTextStyles,
  ERROR_COLOR,
} from './AppHeader.styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { useNavigate } from 'react-router-dom';
import { paths } from '@config/routes';
import { HorizontalContainer } from '@components/styles';
import { BusinessPartnersContext } from '@routes/customer/BusinessPartnersContext';

const NoBpsSelectedErrorMessage: FC = () => {
  const { t } = useTranslation<Namespace>('errors');

  return (
    <HorizontalContainer>
      <IconWarning color={ERROR_COLOR} />
      <ContentText {...errorTextStyles}>{t('appHeader.businessPartnersSelector.noSelections')}</ContentText>
    </HorizontalContainer>
  );
};

export const SelectedBusinessPartners: FC = () => {
  const { t } = useTranslation<Namespace>('mainLayout');
  const navigate = useNavigate();

  const navigateToSelectCustomer = () => navigate(paths.selectCustomer);
  const { selectedBps } = useContext(BusinessPartnersContext);

  const { selectedBpsLength, selectedBpsDescription } = useMemo(
    () => ({
      selectedBpsLength: selectedBps.length,
      selectedBpsDescription: selectedBps.map(bp => bp.name).join(','),
    }),
    [selectedBps],
  );

  return (
    <SelectedBusinessPartnersContainer onClick={navigateToSelectCustomer}>
      <ContentText {...bpsTitleStyles}>{t('selectedBusinessPartners', { count: selectedBpsLength })}</ContentText>

      {selectedBpsLength > 0 ? (
        <SelectedPartnersText {...selectedBpsTextStyles}>{selectedBpsDescription}</SelectedPartnersText>
      ) : (
        <NoBpsSelectedErrorMessage />
      )}
    </SelectedBusinessPartnersContainer>
  );
};
