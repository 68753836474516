import { VerticalContainer } from '@components/styles';
import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { useOrderNumberOverwriteMutation } from '@data/hooks/useOrderNumberOverwriteMutation';
import { ContentText, spacing, Button } from '@fortum/elemental-ui-fork';
import { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SimplifiedEvent } from 'src/types/events';
import { FormContainer, buttonStyles, inputFieldStyles } from './styles';
import { InputField } from '@components/InputField';
import { OrdersManagementTileProps } from './types';

export const ChangeTemporaryOrderNoTile: FC<OrdersManagementTileProps> = ({
  setDisplaySuccessfulNotification,
  setDisplayErrorNotification,
  setNotificationErrorMessage,
  setNotificationSuccessMessage,
}) => {
  const { t } = useTranslation<Namespace[]>(['orderCreation', 'errors', 'orderDetails', 'common']);

  const [temporaryOrderNo, setTemporaryOrderNo] = useState<string>('');
  const [orderNo, setOrderNo] = useState<string>('');

  const resetForm = useCallback(() => {
    setTemporaryOrderNo('');
    setOrderNo('');
  }, []);

  const [displayValidationError, setDisplayValidationError] = useState(false);

  const orderNoUpdateMutation = useOrderNumberOverwriteMutation({
    onSuccess: () => {
      resetForm();
      setNotificationSuccessMessage(t('orderCreation:ordersManagement.successfulUpdate'));
      setDisplaySuccessfulNotification();
    },
    onError: error => {
      setNotificationErrorMessage(error.getStatusText() || t('errors:ordersManagement.orderNoUpdate'));
      setDisplayErrorNotification();
    },
  });

  const handleTemporaryOrderNoChange = useCallback((ev: SimplifiedEvent<string>) => setTemporaryOrderNo(ev.target.value), []);

  const handleOrderNoChange = useCallback((ev: SimplifiedEvent<string>) => setOrderNo(ev.target.value), []);

  const updateOrderNo = () => {
    if (!temporaryOrderNo || !orderNo) {
      setDisplayValidationError(true);
      return;
    }

    orderNoUpdateMutation.mutate({ temporaryOrderNo, orderNo });
  };

  return (
    <Tile header={t('orderCreation:ordersManagement.orderNoUpdate.header')} data-testid="order-no-update-tile">
      <FormContainer>
        <InputField
          {...inputFieldStyles}
          required
          label={t('orderCreation:ordersManagement.temporaryOrderNo.label')}
          name="temporary-order-no"
          value={temporaryOrderNo}
          onChange={handleTemporaryOrderNoChange}
          errorMessage={t('errors:form.fieldRequired')}
          showError={displayValidationError && !temporaryOrderNo}
        />

        <VerticalContainer>
          <ContentText mb={spacing.xxxs}>{`${t('common:changeTo')}:`}</ContentText>

          <InputField
            {...inputFieldStyles}
            required
            label={t('orderDetails:orderNo')}
            name="order-no"
            value={orderNo}
            onChange={handleOrderNoChange}
            errorMessage={t('errors:form.fieldRequired')}
            showError={displayValidationError && !orderNo}
          />
        </VerticalContainer>

        <Button data-testid="change-button" {...buttonStyles} onClick={updateOrderNo} disabled={orderNoUpdateMutation.isPending}>
          {t('common:change')}
        </Button>
      </FormContainer>
    </Tile>
  );
};
