import { verticalContainer } from '@components/styles';
import { Box, colors, px2rem, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const Container = styled.div`
  ${verticalContainer};
`;

export const LocationContainer = styled.div<{ $pageHeaderHeight?: string }>`
  --page-header-height: ${props => (props.$pageHeaderHeight ? props.$pageHeaderHeight : 'var(--page-header-height)')};
  display: flex;
  row-gap: ${spacing.xs};
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: ${px2rem(306)};
  position: sticky;
  top: calc(var(--app-header-height) + var(--page-header-height));
  overflow-y: auto;
  padding: ${spacing.xs} ${spacing.xxs};
  box-sizing: border-box;
  height: calc(100vh - (var(--app-header-height) + var(--page-header-height)));
  background-color: ${colors.snowWhite};
  margin-right: ${spacing.xxxxs};
`;

export const ErrorContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${px2rem(140)};
`;
