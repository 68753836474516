import { HorizontalContainer, VerticalContainer } from '@components/styles';
import { colors, px2rem, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const ItemsContainer = styled(VerticalContainer)`
  padding: ${spacing.xxxs} 0px 0px ${spacing.s};

  gap: ${spacing.xxxs};

  > div:not(:last-child) {
    border-bottom: ${px2rem(1)} solid ${colors.cloudGrey};
  }
`;

export const ItemContainer = styled(HorizontalContainer)`
  height: 40px;
  padding-top: ${spacing.xxxxs};
  justify-content: space-between;
  box-sizing: border-box;
`;
