import { Col, ContentText, colors, fontSizes, ColProps } from '@fortum/elemental-ui-fork';
import { FC } from 'react';

interface HeaderCellProps {
  text: string;
  gridColumnProps: ColProps;
}

export const HeaderCell: FC<HeaderCellProps> = ({ text, gridColumnProps }) => (
  <Col ph={{ xs: 0 }} {...gridColumnProps}>
    <ContentText fontSize={fontSizes.s} color={colors.sonicGrey}>
      {text}
    </ContentText>
  </Col>
);
