import { CookieCategoriesMapping } from '@config/oneTrustCookie';
import { StorageUtils } from '@common/storage';

const cookieConsentKey = 'cookieConsent';

export const hasSetCookieConsent = (): boolean => {
  return StorageUtils.hasItem(cookieConsentKey);
};

export const setCookieConsent = (cookies: string[]): void => {
  if (cookies.length === 0) {
    return;
  }

  StorageUtils.putItem(cookieConsentKey, JSON.stringify(cookies));
};

const getCookieConsent = (): string[] => {
  return StorageUtils.getObject<string[]>(cookieConsentKey) ?? [];
};

export const hasAllowedPerformanceCookies = (): boolean => {
  const cookieConsent = getCookieConsent();
  return cookieConsent.includes(CookieCategoriesMapping.PERFORMANCE_COOKIES);
};
