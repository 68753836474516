import { StyledHelpIcon, PopoverContentContainer } from './styles';
import { usePopover, colors, Popover, ContentText, fontSizes, PopoverProps } from '@fortum/elemental-ui-fork';
import { FC, useMemo, memo } from 'react';

export interface TooltipProps extends Omit<PopoverProps, 'boundary' | 'opened' | 'anchor' | 'content'> {
  id: string;
  content: string[];
  containerElement?: HTMLElement;
}

export const Tooltip: FC<TooltipProps> = memo(
  ({
    id,
    content,
    containerElement,
    anchorPos = 'top',
    backgroundColor = colors.snowWhite,
    popperOffset = 16,
    shadowStyle = 'small',
    ...popoverProps
  }) => {
    const { open, anchor, handleOpen, handleClose } = usePopover();

    const { popoverId, iconId } = useMemo(
      () => ({
        popoverId: `${id}-popover`,
        iconId: `${id}-icon`,
      }),
      [name],
    );

    return (
      <>
        <StyledHelpIcon
          data-testid={iconId}
          color={colors.silverGrey}
          size={16}
          onMouseEnter={handleOpen}
          onFocus={handleOpen}
          onBlur={handleClose}
          onMouseLeave={handleClose}
          aria-describedby={popoverId}
        />
        <Popover
          id={popoverId}
          anchorPos={anchorPos}
          backgroundColor={backgroundColor}
          popperOffset={popperOffset}
          shadowStyle={shadowStyle}
          opened={open}
          anchor={anchor}
          boundary={containerElement}
          {...popoverProps}
        >
          <PopoverContentContainer>
            {content.map((contentItem, index) => (
              <ContentText key={index} fontSize={fontSizes.s}>
                {contentItem}
              </ContentText>
            ))}
          </PopoverContentContainer>
        </Popover>
      </>
    );
  },
);

Tooltip.displayName = 'Tooltip';
