import { Notification, useNotification } from '@components/Notification';
import { Namespace } from '@config/i18n';
import { sendMessageToCustomerService } from '@data/api/contactCustomerService';
import { useLocations } from '@data/hooks/useLocations';
import { ContentText, fontSizes, IconContactByEmail, InputField, Select, SelectItem, Textarea } from '@fortum/elemental-ui-fork';
import { ReasonForContacting, ReasonsForContacting } from '@models/userMessage';
import { useContactForm } from '@routes/contact/useContactForm';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactFormContainer, Form, SitesInputContainer, StyledMultiselect, SubmitButton } from './styles';
import { filterSelectItems } from '@utils/dataOperations';
import { mapToLocationSelectItems } from '@utils/contactUs';
import { ngColors } from '@utils/styleOverride';

const MAX_TITLE_LENGTH_IN_CHARS = 50;
const MAX_MESSAGE_LENGTH_IN_CHARS = 1000;

export const ContactForm: FC = () => {
  const { t, i18n } = useTranslation<Namespace[]>(['contactUs', 'errors', 'common']);
  const { data, error: locationsError } = useLocations();

  const [sendingInProgress, setSendingInProgress] = useState(false);

  const topicItems = useMemo<SelectItem<ReasonForContacting>[]>(
    () =>
      ReasonsForContacting.map(reason => ({
        name: t(`contactUs:formFields.topic.values.${reason}`),
        value: reason,
      })),
    [i18n.language],
  );

  const sitesSelectItems = useMemo<SelectItem<string>[] | null>(
    () => (data === undefined ? null : mapToLocationSelectItems(data.locations)),
    [data],
  );

  const { formValues, setReasonForContacting, setLocations, setMessage, setTitle, resetForm, requiredFieldErrors, validateForm } =
    useContactForm(MAX_TITLE_LENGTH_IN_CHARS);

  const { displayNotification, setDisplayErrorNotification, closeNotification, setDisplaySuccessfulNotification } =
    useNotification();

  const onSubmit = useCallback(() => {
    if (!validateForm() || formValues.message.length > MAX_MESSAGE_LENGTH_IN_CHARS) return;

    setSendingInProgress(true);

    sendMessageToCustomerService(formValues, i18n.language)
      .then(() => {
        setDisplaySuccessfulNotification();
        resetForm();
      })
      .catch(() => setDisplayErrorNotification())
      .finally(() => setSendingInProgress(false));
  }, [validateForm, resetForm, setDisplaySuccessfulNotification, setDisplayErrorNotification, i18n.language]);

  const displayValue = useMemo<string | undefined>(() => {
    if (!sitesSelectItems || !formValues.locationCodes || formValues.locationCodes.length <= 1) {
      return undefined;
    }

    if (formValues.locationCodes.length === sitesSelectItems.length) {
      return t('formFields.sites.allSelected');
    }

    return t('formFields.sites.multipleSelected', { count: formValues.locationCodes.length });
  }, [formValues.locationCodes, sitesSelectItems?.length, i18n.language]);

  return (
    <>
      <ContactFormContainer>
        <IconContactByEmail size={48} color={ngColors.orange} />
        <ContentText fontSize={fontSizes.l}>{t('contactUs:formHeader')}</ContentText>
        <ContentText fontSize={fontSizes.s} textAlign="center">
          {t('contactUs:recipientInfo')}
        </ContentText>

        <Form>
          <Select
            name="topic"
            label={t('contactUs:formFields.topic.label')}
            placeholder={t('contactUs:formFields.selectPlaceholder')}
            items={topicItems}
            onChange={setReasonForContacting}
            selected={formValues.reason}
          />
          <SitesInputContainer>
            <ContentText fontSize={fontSizes.s}>{t('contactUs:formFields.sites.description')}</ContentText>
            <StyledMultiselect
              name="task-sites"
              label={t('contactUs:formFields.sites.label')}
              placeholder={t('contactUs:formFields.selectPlaceholder')}
              items={sitesSelectItems ?? []}
              selected={formValues.locationCodes}
              onSelectedItemsChange={setLocations}
              displayValue={displayValue}
              selectAll={t('contactUs:formFields.sites.selectAll')}
              disabled={!!locationsError || !sitesSelectItems || sitesSelectItems.length === 0}
              error={!!locationsError}
              errorMessage={t('errors:locations.failedToFetch')}
              filterItems={filterSelectItems}
            />
          </SitesInputContainer>

          <InputField
            name="title"
            label={t('contactUs:formFields.title.label')}
            inputProps={{ maxLength: MAX_TITLE_LENGTH_IN_CHARS }}
            required
            value={formValues.title}
            onChange={setTitle}
            error={!!requiredFieldErrors.title}
            errorMessage={t('errors:form.fieldRequired')}
          />

          <Textarea
            name="message"
            label={t('contactUs:formFields.message.label')}
            required
            value={formValues.message}
            onChange={setMessage}
            maxLength={MAX_MESSAGE_LENGTH_IN_CHARS}
            error={!!requiredFieldErrors.message || formValues.message.length > MAX_MESSAGE_LENGTH_IN_CHARS}
            errorMessage={
              formValues.message.length > MAX_MESSAGE_LENGTH_IN_CHARS
                ? t('errors:form.tooManyCharacters')
                : t('errors:form.fieldRequired')
            }
          />

          <SubmitButton variant="condensed" type="submit" onClick={onSubmit} disabled={sendingInProgress}>
            {t('common:send')}
          </SubmitButton>
        </Form>
      </ContactFormContainer>

      <Notification
        type="success"
        id="form-success-notification"
        content={t('contactUs:successfullySent')}
        opened={displayNotification === 'success'}
        onClose={closeNotification}
        maxWidth="35rem"
      />

      <Notification
        type="error"
        id="form-error-notification"
        content={t('errors:form.failedToSend')}
        opened={displayNotification === 'error'}
        onClose={closeNotification}
        maxWidth="40rem"
      />
    </>
  );
};
