import {
  Box,
  colors,
  ContentText,
  fontSizes,
  fontWeights,
  ForwardLink,
  IconClimateAndResources,
  spacing,
} from '@fortum/elemental-ui-fork';
import { queryPaths } from '@config/routes';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { useNavigate } from 'react-router-dom';
import { MapLocation } from '@models/home';
import React, { FC } from 'react';
import { hasValue } from '@utils/dataOperations';
import { ngColors } from '@utils/styleOverride';

interface Props {
  location: MapLocation;
}

export const MapLocationLayout: FC<Props> = ({ location }) => {
  const { t } = useTranslation<Namespace>('home');
  const navigate = useNavigate();

  return (
    <Box textAlign="center" padding={spacing.xs}>
      <IconClimateAndResources color={ngColors.orange} />
      <ContentText color={colors.inkGrey} fontSize={fontSizes.xl}>
        {t('map.yourLocations')}
      </ContentText>
      <ContentText paddingTop={spacing.xs} color={colors.mineShaftGrey} fontWeight={fontWeights.medium} fontSize={fontSizes.xs}>
        {location.businessPartnerName.toUpperCase()}
      </ContentText>
      {hasValue(location.locationName) && (
        <ContentText color={colors.emperorGrey} fontSize={fontSizes.s}>
          {location.locationName}
        </ContentText>
      )}
      <ContentText color={colors.emperorGrey} fontSize={fontSizes.s}>
        {location.address}
      </ContentText>
      <ForwardLink
        paddingTop={spacing.s}
        color={ngColors.orange}
        onClick={() => navigate(`${queryPaths.servicesByLocation}${location.locationCode}`)}
        id="navigateToServicesFromMapLayout"
      >
        {t('map.seeYourServices')}
      </ForwardLink>
    </Box>
  );
};
