import { Button, ContentText, IconSearch, fontSizes } from '@fortum/elemental-ui-fork';
import { FC, useContext, useEffect, useState } from 'react';
import {
  Container,
  HeaderContainer,
  SearchInputField,
  SitesMultiselect,
  FiltersInnerContainer,
  FiltersContainer,
  HorizontalContainerWithGap,
  ButtonsContainer,
  HeaderDescriptionContainer,
} from './styles';
import { Multiselect } from '@components/Multiselect';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { ContractTableWithPagination } from './ContractsTableWithPagination';
import { isContractsFetchingEnabled } from '@utils/ordersCreation';
import { filterSelectItems } from '@utils/dataOperations';
import { ServicesSelectorContext } from './ServicesSelectorContext';
import { ngColors } from '@utils/styleOverride';

interface ServicesSelectorProps {
  onClose: () => void;
  onContractChoose: (contractIdentifier: string) => void;
}

export const ServicesSelector: FC<ServicesSelectorProps> = ({ onClose, onContractChoose }) => {
  const { t } = useTranslation<Namespace[]>(['common', 'orderCreation', 'services']);
  const [selectedContractIdentifier, setSelectedContractIdentifier] = useState<string | null>(null);

  const { clearAllSelections, sites, filters } = useContext(ServicesSelectorContext);

  const contractsFetchingEnabled = isContractsFetchingEnabled(
    sites.selectedSites,
    filters.debouncedFiltersKeysWithSearch.wastes,
    filters.debouncedFiltersKeysWithSearch.equipments,
    filters.debouncedFiltersKeysWithSearch.search,
  );

  useEffect(() => {
    if (!contractsFetchingEnabled) {
      setSelectedContractIdentifier(null);
    }
  }, [contractsFetchingEnabled]);

  return (
    <Container>
      <HeaderContainer>
        <HeaderDescriptionContainer>
          <ContentText fontSize={fontSizes.xl}>{t('common:chooseNextService')}</ContentText>
          <ContentText fontSize={fontSizes.s}>{t('orderCreation:servicesSelector.description')}</ContentText>
        </HeaderDescriptionContainer>

        <SearchInputField
          name="search-input-field"
          size="s"
          label={t('orderCreation:servicesSelector.searchInput.label')}
          placeholder={t('common:search')}
          value={filters.temporarySearch}
          onChange={filters.changeTemporarySearch}
          leftIcon={<IconSearch />}
        />
      </HeaderContainer>

      <FiltersContainer>
        <FiltersInnerContainer>
          <Multiselect
            name="waste-types"
            label={t('services:wasteType')}
            variant="condensed"
            borderStyle="full"
            placeholder={t('common:all')}
            onSelectedItemsChange={filters.handleWasteTypesChange}
            items={filters.sortedWasteItems}
            selected={filters.filtersKeys.wastes}
            error={filters.queryResult.isError}
            errorMessage={t('errors:generalError.failedToFetch')}
            displayValue={filters.selectedWasteDisplayValue}
            disabled={filters.shouldBeDisabled.wasteTypes}
            filterItems={filterSelectItems}
          />
          <Multiselect
            name="equipment-types"
            label={t('services:equipmentType')}
            variant="condensed"
            borderStyle="full"
            placeholder={t('common:all')}
            onSelectedItemsChange={filters.handleEquipmentTypesChange}
            selected={filters.filtersKeys.equipments}
            items={filters.sortedEquipmentItems}
            error={filters.queryResult.isError}
            errorMessage={t('errors:generalError.failedToFetch')}
            displayValue={filters.selectedEquipmentDisplayValue}
            disabled={filters.shouldBeDisabled.equipmentTypes}
            filterItems={filterSelectItems}
          />
        </FiltersInnerContainer>
        <HorizontalContainerWithGap>
          <SitesMultiselect
            name="contract-sites"
            label={t('services:sites')}
            variant="condensed"
            borderStyle="full"
            placeholder={t('common:all')}
            onSelectedItemsChange={sites.setSelectedSites}
            items={sites.sortedSitesItems}
            selected={sites.selectedSites}
            error={sites.isSitesError}
            errorMessage={t('errors:generalError.failedToFetch')}
            displayValue={sites.selectedSitesDisplayValue}
            disabled={sites.shouldBeDisabled}
            filterItems={filterSelectItems}
          />

          <Button
            data-testid="selector-clear-filters-button"
            status="plain"
            textColor={ngColors.orange}
            size="s"
            onClick={clearAllSelections}
          >
            {t('common:clearFilters')}
          </Button>
        </HorizontalContainerWithGap>
      </FiltersContainer>

      <ContractTableWithPagination
        selectedLocationsCodes={sites.selectedLocationCodes}
        contractsFetchingEnabled={contractsFetchingEnabled}
        setSelectedContractIdentifier={setSelectedContractIdentifier}
        selectedContractIdentifier={selectedContractIdentifier}
        {...filters.debouncedFiltersKeysWithSearch}
      />

      <ButtonsContainer>
        <Button
          data-testid="selector-close-button"
          size="s"
          status="secondary"
          backgroundColor={ngColors.white}
          textColor={ngColors.white}
          onClick={onClose}
        >
          {t('common:close')}
        </Button>

        {!!selectedContractIdentifier && (
          <Button
            data-testid="selector-choose-button"
            size="s"
            textColor={ngColors.white}
            onClick={() => onContractChoose(selectedContractIdentifier)}
          >
            {t('common:choose')}
          </Button>
        )}
      </ButtonsContainer>
    </Container>
  );
};
