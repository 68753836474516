import { ReasonForContacting, UserMessage } from '@models/userMessage';
import { ChangeEvent, useCallback, useState } from 'react';
import DOMPurify from 'dompurify';

/**
 * Elemental Select component does not have a reset functionality.
 * If a value is passed to a 'selected' prop and then it is changed to undefined,
 * the Select component still displays the previous value.
 * If we want to reset to form after it is successfully sent,
 * an empty string has to be set as the 'selected' prop value
 */
const FORM_INITIAL_STATE: UserMessage = {
  reason: '' as ReasonForContacting,
  message: '',
  locationCodes: [],
  title: '',
};

const requiredFields = ['title', 'message'] as const;
type REQUIRED_FIELDS_ERROR_TYPE = {
  [key in (typeof requiredFields)[number]]: boolean;
};

const REQUIRED_FIELDS_ERROR_INITIAL_STATE: REQUIRED_FIELDS_ERROR_TYPE = {
  title: false,
  message: false,
};

export const useContactForm = (maxTitleLengthInChars: number) => {
  const [formValues, setFormValues] = useState<UserMessage>(FORM_INITIAL_STATE);

  const [requiredFieldErrors, setRequiredFieldErrors] = useState<REQUIRED_FIELDS_ERROR_TYPE>(REQUIRED_FIELDS_ERROR_INITIAL_STATE);

  const requiredFieldsMissing = useCallback(
    () =>
      requiredFields.some(field => {
        if (Array.isArray(formValues[field])) {
          return formValues[field].length === 0;
        }
        return typeof formValues[field] === 'object' ? !formValues[field] : !formValues[field].toString().trim();
      }),
    [formValues],
  );

  const resetForm = useCallback(() => {
    setFormValues(FORM_INITIAL_STATE);
    setRequiredFieldErrors(REQUIRED_FIELDS_ERROR_INITIAL_STATE);
  }, []);

  const setReasonForContacting = useCallback(
    (
      e: ChangeEvent<{
        name?: string;
        value: ReasonForContacting;
      }>,
    ) => {
      setFormValues(prev => ({
        ...prev,
        reason: e.target.value,
      }));
    },
    [],
  );

  const setTitle = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      if (ev.target.value.length <= maxTitleLengthInChars) {
        setFormValues(prev => ({ ...prev, title: DOMPurify.sanitize(ev.target.value) }));
      } else {
        setFormValues(prev => ({
          ...prev,
          title: DOMPurify.sanitize(ev.target.value).substring(0, maxTitleLengthInChars),
        }));
      }

      setRequiredFieldErrors(prev => ({ ...prev, title: ev.target.value.trim().length === 0 }));
    },

    [],
  );

  const setMessage = useCallback((ev: ChangeEvent<HTMLTextAreaElement>) => {
    setFormValues(prev => ({ ...prev, message: DOMPurify.sanitize(ev.target.value) }));

    setRequiredFieldErrors(prev => ({ ...prev, message: ev.target.value.trim().length === 0 }));
  }, []);

  const setLocations = useCallback(
    (selectedItems: string[]) => setFormValues(prev => ({ ...prev, locationCodes: selectedItems })),
    [],
  );

  const validateForm = useCallback(() => {
    setRequiredFieldErrors({
      title: formValues.title.trim().length === 0,
      message: formValues.message.trim().length === 0,
    });

    return !requiredFieldsMissing();
  }, [requiredFieldsMissing, formValues]);

  return {
    formValues,
    setLocations,
    setMessage,
    setTitle,
    setReasonForContacting,
    resetForm,
    requiredFieldErrors,
    validateForm,
  };
};
