import { BoxProps, spacing, ContentTextProps, fontSizes, CommonForwardLinkProps } from '@fortum/elemental-ui-fork';
import { ngColors } from '@utils/styleOverride';

export const containerProps: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.xxxs,
};

export const headerContainerProps: BoxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const orderTypeContainerProps: BoxProps = {
  display: 'flex',
  columnGap: spacing.xxxxs,
};

export const headerProps: ContentTextProps = {
  fontSize: fontSizes.s,
  paddingTop: spacing.xxs,
};

export const linkProps: CommonForwardLinkProps = {
  fontSize: '18px',
  color: ngColors.orange,
};
