import { PropsWithChildren } from 'react';
import { useNotification } from '@components/Notification';
import { NotificationContext } from './NotificationContext';

type NotificationContextProviderProps = PropsWithChildren;

export const NotificationContextProvider = ({ children }: NotificationContextProviderProps) => {
  const notificationsHandler = useNotification();

  return <NotificationContext.Provider value={notificationsHandler}>{children}</NotificationContext.Provider>;
};
