import { Button, fontSizes, Heading, IconButton, IconCross } from '@fortum/elemental-ui-fork';
import { FC, useEffect, useState } from 'react';
import { ButtonsContainer, closeIconButtonStyles, StickyContent } from './styles';

import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { SideDrawer } from '@components/SideDrawer';
import { ListLayout } from './ListLayout';
import { orderBy } from 'lodash';
import { PanelContainer, DataContainer, DRAWER_WIDTH } from '../styles';
import { SelectedBox } from './SelectedBox';
import { filterSelectItems } from '@utils/dataOperations';
import { ngColors } from '@utils/styleOverride';
export interface SelectCompanyItem {
  name: string;
  value: string;
}

interface SelectCompanyPanelProps {
  initSelectedCompanies: SelectCompanyItem[];
  companies: SelectCompanyItem[];
  multiselect?: boolean;
  open: boolean;
  selectionLimit?: number;
  onClose: () => void;
  onSave: (selected: SelectCompanyItem[]) => void;
}

export const SelectCompanyPanel: FC<SelectCompanyPanelProps> = ({
  initSelectedCompanies,
  companies,
  multiselect = false,
  open,
  selectionLimit = 1,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation<Namespace[]>(['usersManagement']);
  const [searchValue, setSearchValue] = useState<string>('');
  const [companiesToList, setCompaniesToList] = useState<SelectCompanyItem[]>([]);
  const [selected, setSelected] = useState<SelectCompanyItem[]>(initSelectedCompanies);

  if (!selected) return null;

  useEffect(() => {
    const sortedCompanies = orderBy(companies, [c => c.name.trimStart()]).map(c => ({
      name: c.name.trimStart(),
      value: c.value.trimStart(),
    }));

    if (searchValue !== '') {
      setCompaniesToList(filterSelectItems(sortedCompanies, searchValue));
    } else {
      setCompaniesToList(sortedCompanies);
    }
  }, [searchValue, companies]);

  useEffect(() => {
    setSelected(initSelectedCompanies);
  }, [initSelectedCompanies]);

  const selectCompany = (company: SelectCompanyItem) => {
    const userToBeSelected = companiesToList.find(c => c.value === company.value && c.name === company.name);

    if (!userToBeSelected) return;

    if ((multiselect && selectionLimit && selected.length < selectionLimit) || (!multiselect && selected.length === 0)) {
      setSelected(prev => [...prev, userToBeSelected]);
    }
  };
  const deselectCompany = (company: SelectCompanyItem) =>
    setSelected(prev => prev.filter(c => !(c.value === company.value && c.name === company.name)));

  const onCloseHandler = () => {
    setSelected(initSelectedCompanies);
    setSearchValue('');
    onClose();
  };

  const onSaveHandler = () => {
    setSearchValue('');
    onSave(selected);
  };

  return (
    <SideDrawer open={open} onClickOutside={onCloseHandler} width={DRAWER_WIDTH}>
      <PanelContainer>
        <DataContainer>
          <IconButton {...closeIconButtonStyles} icon={<IconCross size={24} />} onClick={onCloseHandler} />
          <Heading fontSize={fontSizes.xl}> {t('usersManagement:companyPanel.title')}</Heading>
          <ListLayout
            companies={companiesToList}
            selectedCompanies={selected}
            searchValue={searchValue}
            limit={selectionLimit}
            search={setSearchValue}
            deselect={deselectCompany}
            select={selectCompany}
          />
        </DataContainer>
        <StickyContent>
          <SelectedBox
            onClear={() => setSelected([])}
            selected={selected}
            selectionLimit={selectionLimit}
            deselect={deselectCompany}
          />
          <ButtonsContainer>
            <Button status="primary" textColor={ngColors.white} onClick={onCloseHandler}>
              {t('usersManagement:companyPanel.cancel')}
            </Button>
            <Button status="primary" textColor={ngColors.white} onClick={onSaveHandler}>
              {t('usersManagement:companyPanel.add')}
            </Button>
          </ButtonsContainer>
        </StickyContent>
      </PanelContainer>
    </SideDrawer>
  );
};
