import { createContext } from 'react';
import { UseContractsFiltersHandlerReturnValue } from '@hooks/useContractsFiltersHandler';

type ContractsFiltersContextProps = UseContractsFiltersHandlerReturnValue;

export const ContractsFiltersContext = createContext<ContractsFiltersContextProps>({
  selectedWasteTypes: [],
  selectedEquipmentTypes: [],
  search: '',
  queryResult: { isError: false, isLoading: false, fetchStatus: 'idle' },
  filtersKeysWithSearch: { wastes: [], equipments: [], search: '' },
  debouncedFiltersKeysWithSearch: { wastes: [], equipments: [], search: '' },
  filtersSelectItems: { wasteTypes: [], equipmentTypes: [] },
  lastDataReloadCause: null,
  handleEquipmentTypesChange: () => undefined,
  handleSearchChange: () => undefined,
  handleWasteTypesChange: () => undefined,
  resetAll: () => undefined,
});
