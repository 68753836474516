import { HorizontalContainer } from '@components/styles';
import { breakpoints, colors, ContentTextProps, IconProps, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const HeaderContainer = styled(HorizontalContainer)`
  height: var(--page-header-height);
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  gap: ${spacing.xxxs};
  background-color: ${colors.lightGrey};
  padding: 0 ${spacing.xs};

  @media ${`(max-width: ${breakpoints.m}${'px'})`} {
    padding: 0 ${spacing.xxs};
  }
`;

export const iconStyles: IconProps = {
  color: colors.oceanGreen,
};

export const headerTextStyles: ContentTextProps = {
  size: 23,
};
