import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { FC } from 'react';
import {
  Box,
  BoxProps,
  ContentText,
  ContentTextProps,
  IconCustomerService,
  colors,
  fontSizes,
  lineHeights,
  px2rem,
  spacing,
} from '@fortum/elemental-ui-fork';
import { CustomerServiceIconRoundedContainer, MessageContainer } from './styles';
import { ngColors } from '@utils/styleOverride';

const containerStyles: BoxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: px2rem(48),
  gap: spacing.xxxs,
};

const titleTextStyles: ContentTextProps = {
  color: ngColors.orange,
  fontSize: fontSizes.xl,
  lineHeight: lineHeights.normal,
};

export const NeedHelpMessage: FC = () => {
  const { t } = useTranslation<Namespace>('orderCreation');

  return (
    <MessageContainer>
      <Box {...containerStyles}>
        <CustomerServiceIconRoundedContainer>
          <IconCustomerService color={colors.snowWhite} />
        </CustomerServiceIconRoundedContainer>
        <ContentText {...titleTextStyles}>{t('footer.mainTitle')}</ContentText>
      </Box>
      <Box>
        <ContentText fontSize={fontSizes.s} paddingBottom={spacing.m}>
          {t('footer.message')}
        </ContentText>
      </Box>
    </MessageContainer>
  );
};
