import { Table } from '@components/styles';
import { TableProps } from '@fortum/elemental-ui-fork';
import { Detail } from '@models/service';
import styled from 'styled-components';

export const DetailsTable = styled(Table)<TableProps<Detail>>`
  table {
    width: 100%;
  }
`;
