import { CssValue, Placeholder } from '@fortum/elemental-ui-fork';
import { FC, ReactNode } from 'react';

interface PlaceholderOrValueProps {
  displayPlaceholder?: boolean;
  placeholderWidth?: CssValue;
  placeholderHeight?: CssValue;
  children: ReactNode;
}

export const PlaceholderWrapper: FC<PlaceholderOrValueProps> = ({
  children,
  displayPlaceholder,
  placeholderWidth = '168px',
  placeholderHeight = '21px',
}) => (displayPlaceholder ? <Placeholder height={placeholderHeight} width={placeholderWidth} /> : children);
