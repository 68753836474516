import { createContext } from 'react';

interface BookmarksContextProps {
  createBookmark: (title: string, description: string) => Promise<void>;
  selectBookmark: (id: string) => Promise<void>;
  selectedBookmark: string;
  bookmarksFetchingResult: {
    qBookmarks: enigmaJS.QBookmark[];
    isError: boolean;
    isLoading: boolean;
  };
  removeBookmark: (bookmarkId: string, bookmarkTitle: string) => Promise<void>;
}

export const BookmarksContext = createContext<BookmarksContextProps | null>(null);
