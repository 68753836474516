import { useEffect, useState } from 'react';
import { Namespace } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { Accordion, Button, ContentText, Heading, Link, spacing } from '@fortum/elemental-ui-fork';
import { hasSetCookieConsent, setCookieConsent } from '@utils/cookieConsent';
import { CookieCategoriesMapping } from '@config/oneTrustCookie';
import { ButtonContainer, CookieSettingsContainer, secondaryButtonStyles, StyledModal } from './styles';
import { ngColors } from '@utils/styleOverride';

export const CookieConsentModal = () => {
  const { t } = useTranslation<Namespace>('cookieConsent');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!hasSetCookieConsent()) {
      setOpen(true);
    }
  }, []);

  const handleAcceptAll = () => {
    setCookieConsent([CookieCategoriesMapping.NECESSARY_COOKIES, CookieCategoriesMapping.PERFORMANCE_COOKIES]);
    setOpen(false);
  };

  const handleOnlyNecessary = () => {
    setCookieConsent([CookieCategoriesMapping.NECESSARY_COOKIES]);
    setOpen(false);
  };

  return (
    <StyledModal opened={open} onClose={handleOnlyNecessary} data-testid="cookie-consent-modal" aria-labelledby="modal-heading">
      <Heading level={2} marginBottom={spacing.xs} id="modal-heading">
        {t('heading')}
      </Heading>
      <ContentText paragraph>
        <Trans
          i18nKey="cookieConsent:content"
          components={{
            Link: <Link href={t('cookiePolicyLink')} rel="noopener" target="_blank" />,
          }}
        />
      </ContentText>
      <CookieSettingsContainer marginTop={spacing.s}>
        <Accordion
          openText={t('necessaryCookiesHeading')}
          closeText={t('necessaryCookiesHeading')}
          textAlign="left"
          marginBottom={spacing.xxxs}
        >
          <ContentText paragraph size="xs">
            {t('necessaryCookiesContent')}
          </ContentText>
        </Accordion>
        <Accordion openText={t('performanceCookiesHeading')} closeText={t('performanceCookiesHeading')} textAlign="left">
          <ContentText paragraph size="xs">
            {t('performanceCookiesContent')}
          </ContentText>
        </Accordion>
      </CookieSettingsContainer>
      <ButtonContainer marginTop={spacing.s}>
        <Button textColor={ngColors.white} onClick={handleAcceptAll} data-testid="accept-all">
          {t('acceptAll')}
        </Button>
        <Button {...secondaryButtonStyles} onClick={handleOnlyNecessary} data-testid="only-necessary">
          {t('onlyNecessary')}
        </Button>
      </ButtonContainer>
    </StyledModal>
  );
};
