import { FC, ReactNode, useMemo } from 'react';
import { QlikApp } from './types';
import { QlikAppContext } from './QlikAppContext';

interface QlikAppContextProviderProps {
  app: QlikApp | null;
  children: ReactNode;
}
export const QlikAppContextProvider: FC<QlikAppContextProviderProps> = ({ app, children }) => {
  const providerValue = useMemo(() => ({ app }), [app]);
  return <QlikAppContext.Provider value={providerValue}>{children}</QlikAppContext.Provider>;
};
