import { Namespace } from '@config/i18n';
import { Button, CommonModalProps, ContentText, IconPowerOutage, Modal, spacing } from '@fortum/elemental-ui-fork';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ngColors } from '@utils/styleOverride';

type MissingFieldsModalProps = Omit<CommonModalProps, 'children'> & {
  missingRequiredFieldsCount: number;
};

export const MissingFieldsModal: FC<MissingFieldsModalProps> = ({ missingRequiredFieldsCount, ...props }) => {
  const { t } = useTranslation<Namespace[]>(['errors', 'common']);

  return (
    <Modal {...props} data-testid="missing-fields-error-modal">
      <IconPowerOutage pb={spacing.xxs} />
      <ContentText fontSize={'36px'} pb={spacing.xxs}>
        {t('errors:ordersCreation.missingInformation.header', {
          amount: missingRequiredFieldsCount,
        })}
      </ContentText>

      <ContentText pb={spacing.xs}>{t('errors:ordersCreation.missingInformation.message')}</ContentText>

      <Button status="primary" textColor={ngColors.white} onClick={props.onClose} size="m">
        {t('common:close')}
      </Button>
    </Modal>
  );
};
