import { createContext } from 'react';

import { useOrdersCreation } from './useOrdersCreation';

export type OrdersCreationContextProps = ReturnType<typeof useOrdersCreation>;

export const OrdersCreationContext = createContext<OrdersCreationContextProps>({
  creationForms: [],
  setValue: () => undefined,
  initializeNewOrderForm: () => undefined,
  createEmptyCreationForm: () => undefined,
  setInitialOrderFormWithContractDetails: () => undefined,
  countMissingRequiredFields: () => 0,
  clearForms: () => undefined,
  deleteOrder: () => undefined,
  discardService: () => undefined,
});
