import { UseNotification } from '@components/Notification';
import { createContext } from 'react';

type NotificationContextProps = UseNotification;

export const NotificationContext = createContext<NotificationContextProps>({
  displayNotification: null,
  notificationMessage: null,
  setDisplayErrorNotification: () => undefined,
  setDisplaySuccessfulNotification: () => undefined,
  closeNotification: () => undefined,
});
