import { Box, ContentText, IconArrowLeft } from '@fortum/elemental-ui-fork';
import { StyledHomeLink } from '@components/styles';
import { FC } from 'react';
import { containerProps, linkStyles, titleProps } from './styles';
import { Stylable } from '@components/types';
import { PAGE_HEADER_TEST_ID } from '@common/testIds';

interface LinkProps {
  id: string;
  description: string;
  onClick: () => void;
}

export interface PageNavigationHeaderProps extends Stylable {
  link: LinkProps;
  title: string;
}

export const PageNavigationHeader: FC<PageNavigationHeaderProps> = ({ link, title, className }) => (
  <Box {...containerProps} className={className} data-testid={PAGE_HEADER_TEST_ID}>
    <StyledHomeLink {...linkStyles} id={link.id} onClick={link.onClick}>
      <IconArrowLeft />
      {link.description}
    </StyledHomeLink>

    <ContentText {...titleProps}>{title}</ContentText>
  </Box>
);
