import { spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: ${spacing.l};
`;
