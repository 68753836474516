import styled from 'styled-components';
import { Box, ButtonProps, Modal } from '@fortum/elemental-ui-fork';
import { ngColors } from '@utils/styleOverride';

export const StyledModal = styled(Modal)`
  max-width: 60em;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

export const CookieSettingsContainer = styled(Box)`
  margin-bottom: 1rem;
`;

export const secondaryButtonStyles: ButtonProps = {
  status: 'secondary',
  backgroundColor: ngColors.white,
  textColor: ngColors.white,
};
