import {
  Modal,
  px2rem,
  lineHeights,
  spacing,
  HeadingProps,
  BoxProps,
  ContentTextProps,
  IconProps,
  ButtonProps,
} from '@fortum/elemental-ui-fork';
import styled from 'styled-components';
import { ngColors } from '@utils/styleOverride';

export const StyledModal = styled(Modal)`
  max-width: ${px2rem(880)};
  width: 100%;
  margin-right: min(152px, 20%);
  margin-left: min(152px, 20%);
  display: flex;
`;

export const headingTextStyles: HeadingProps = {
  level: 4,
  lineHeight: lineHeights.normal,
  marginTop: spacing.xxs,
  marginBottom: spacing.xxs,
};

export const contentTextStyles: ContentTextProps = {
  marginBottom: spacing.xs,
};

export const buttonsContainerStyles: BoxProps = {
  display: 'flex',
  justifyContent: 'center',
  gap: spacing.xs,
};

export const iconStyles: IconProps = {
  size: 48,
};

export const discardButtonStyles: ButtonProps = {
  status: 'secondary',
  backgroundColor: ngColors.white,
  textColor: ngColors.white,
};
