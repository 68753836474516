import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('VITE_API_URL');
const baseUrl = `${reactAppApiUrl}/rwportal-users`;
const baseUrlV1 = `${baseUrl}/v1`;
const baseUrlV3 = `${baseUrl}/v3`;
const baseUrlV4 = `${baseUrl}/v4`;

export const userV4 = `${baseUrlV4}/user`;

export const UserService = {
  customerContactsByBusinessPartner: `${baseUrlV3}/contact/by-business-partner`,
  customerServiceContact: `${baseUrlV3}/contact/customer-service`,
  feVersion: `${baseUrl}/info`,
  passwordChange: `${userV4}/change-password`,
  userAgreement: `${userV4}/agreement`,
  user: userV4,
  userInfo: `${userV4}/details`,
  userLogOut: `${userV4}/logout`,
  createUser: `${userV4}/create`,
  usersList: `${baseUrlV1}/users`,
  usersCompanies: `${baseUrlV1}/users/companies`,
  usersPermissions: `${baseUrlV1}/users/permissions`,
};
