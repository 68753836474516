import { Namespace } from '@config/i18n';
import { Box, Button, ContentText, IconDistrictHeatingOutage, IconReload, fontSizes, spacing } from '@fortum/elemental-ui-fork';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ngColors } from '@utils/styleOverride';

export const SessionTimedOutError = () => {
  const { t } = useTranslation<Namespace>('errors');

  const refresh = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Box display="flex" flex={1} flexDirection="column" alignItems="center" paddingTop="246px" backgroundColor={ngColors.white}>
      <Box display="flex" flexDirection="column" maxWidth="450px" alignItems="center" gap={spacing.xxxs}>
        <IconDistrictHeatingOutage paddingBottom={spacing.xxxs} color={ngColors.orange} />
        <ContentText fontSize="41px" color={ngColors.black} textAlign="center">
          {t('reports.sessionTimedOut.header')}
        </ContentText>
        <ContentText textAlign="center" fontSize={fontSizes.l} color={ngColors.black}>
          {t('reports.sessionTimedOut.message')}
        </ContentText>
        <Button leftIcon={<IconReload />} mt={spacing.xs} onClick={refresh} status="primary" textColor={ngColors.white}>
          {t('reports.sessionTimedOut.button')}
        </Button>
      </Box>
    </Box>
  );
};
