import { HorizontalContainer, VerticalContainer } from '@components/styles';
import { colors, ContentTextProps, fontSizes, px2rem, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const VerticalContainerWithSmallGap = styled(VerticalContainer)`
  gap: ${spacing.xxxs};
`;

export const VerticalContainerWithSmallGapAndPadding = styled(VerticalContainerWithSmallGap)`
  padding-left: ${spacing.s};
`;

export const ListItemContainer = styled(HorizontalContainer)<{ $displayBorder: boolean }>`
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom: ${({ $displayBorder }) => ($displayBorder ? `${px2rem(1)} solid ${colors.cloudGrey}` : 'unset')};
  box-sizing: border-box;
`;

export const labelStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};
