import { PAGE_HEADER_TEST_ID } from '@common/testIds';
import { SelectChangeEvent } from '@components/types';
import { logEvent } from '@config/azureInsights';
import { Namespace } from '@config/i18n';
import { Box, Button, ContentText, IconFilter, SelectItem } from '@fortum/elemental-ui-fork';
import { buttonStyles, ContentContainer, StyledSelect } from '@routes/orders/components/PageHeader/style';
import { ChangeEvent, forwardRef, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SortByValue, sortByValues } from '../config';
import { MainFiltersLayout } from '../Filters/MainFiltersLayout';
import { OrdersFiltersContext } from '../OrdersFiltersContext';
import { boxStyles, selectStyles, titleStyles } from './style';

interface PageHeaderProps {
  pageName: string;
  toggleFiltersPane: () => void;
}

const sortByItems: SelectItem<SortByValue>[] = sortByValues.map(value => ({
  name: `orders:table.${value}`,
  value,
}));

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(({ pageName, toggleFiltersPane }, ref) => {
  const { t, i18n } = useTranslation<Namespace[]>(['common', 'orders']);
  const filtersContext = useContext(OrdersFiltersContext);

  if (!filtersContext) throw Error('Missing orders filters context');

  const onSortByChange = useCallback((e: ChangeEvent<SelectChangeEvent<SortByValue>>) => {
    const value = e.target.value;

    filtersContext.setSortBy(value);
    logEvent('ordersTrackingSortingSelected', { selectedValue: value });
  }, []);

  const getTranslatedSelectItemName = useCallback((item: SelectItem<string>) => t(item.name), [i18n.language]);

  return (
    <ContentContainer ref={ref} data-testid={PAGE_HEADER_TEST_ID}>
      <Box {...boxStyles}>
        <ContentText {...titleStyles}>{pageName}</ContentText>
        <Button {...buttonStyles} leftIcon={<IconFilter />} onClick={toggleFiltersPane}>
          {t('common:moreFilters')}
        </Button>
      </Box>

      <MainFiltersLayout />

      <StyledSelect
        {...selectStyles}
        id="ordersTableSortBy"
        name="ordersTableSortBy"
        label={t('orders:sortBy')}
        onChange={onSortByChange}
        selected={filtersContext.sortBy}
        items={sortByItems}
        renderItem={getTranslatedSelectItemName}
      />
    </ContentContainer>
  );
});

PageHeader.displayName = 'PageHeader';
