import { InsightEventName, InsightEventProps, insightsEventToTitleMap } from '@common/statistics';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { User } from '@models/user';
import { hasAllowedPerformanceCookies } from '@utils/cookieConsent';

const USER_CONSENT_CHECK_INTERVAL_MS = 6000;

const insightsCookies = {
  user: 'ai_user',
  session: 'ai_session',
};

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_INSIGHTS_CONNECTION_STRING,
    enableUnhandledPromiseRejectionTracking: true,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    disableCookiesUsage: true,
  },
});

const clearAuthenticatedUser = () => appInsights.clearAuthenticatedUserContext();

const setAuthenticatedUser = (user: User) => appInsights.setAuthenticatedUserContext(user.email, user.uuid, false);

const logException = (error: Error) => appInsights.trackException({ exception: error });

const logEvent = <T extends InsightEventName>(name: T, eventProps: InsightEventProps<T>) => {
  if (window.location.hostname !== 'localhost') {
    appInsights.trackEvent({ name: insightsEventToTitleMap[name] }, eventProps || undefined);
  }
};

const initMonitoring = () => {
  const areCookiesAllowed = hasAllowedPerformanceCookies();

  appInsights.core.getCookieMgr().setEnabled(areCookiesAllowed);

  if (areCookiesAllowed) {
    return;
  }

  appInsights.getCookieMgr().purge(insightsCookies.user);
  appInsights.getCookieMgr().purge(insightsCookies.session);
};

const initAppInsights = () => {
  appInsights.loadAppInsights();

  initMonitoring();

  setInterval(() => initMonitoring(), USER_CONSENT_CHECK_INTERVAL_MS);
};

export { clearAuthenticatedUser, initAppInsights, logEvent, logException, reactPlugin, setAuthenticatedUser };
