import { FC } from 'react';
import { useRouteError } from 'react-router';

export const ErrorPropagator: FC = () => {
  const er = useRouteError();

  if (er) {
    throw er;
  }

  return null;
};
