import { QueryKeys } from '@common/query';
import { Namespace } from '@config/i18n';
import { queryClient } from '@config/queryClient';
import { paths } from '@config/routes';
import { NotificationContext } from '@contexts/notification';
import { useUserCreationMutation } from '@data/hooks/useUserCreationMutation';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserDetailsFormContext } from './UserDetailsFormContext';
import { CreateUserRequestBody } from '@models/usersManagement';
import { mapUserCreationFormToRequestBody } from '@utils/usersManagement';

export const useUserCreation = () => {
  const { t } = useTranslation<Namespace[]>(['usersManagement', 'errors']);

  const navigate = useNavigate();

  const { setDisplayErrorNotification, setDisplaySuccessfulNotification } = useContext(NotificationContext);
  const { permissionsPerCompaniesHandler, personalInformationFormHandler, validateForm, selectedInternalUserPermissionsHandler } =
    useContext(UserDetailsFormContext);

  const userCreationMutation = useUserCreationMutation({
    onMutate: () => navigate(paths.usersManagement),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.usersProfiles] });
      setDisplaySuccessfulNotification(t('usersManagement:userCreatedSuccessfully'));
    },
    onError: () => setDisplayErrorNotification(t('errors:userCreation.requestFailed.notification.message.text')),
  });

  const onCreateButtonClick = () => {
    if (!validateForm()) return;

    const creationRequestBody: CreateUserRequestBody = mapUserCreationFormToRequestBody(
      personalInformationFormHandler.personalInformation,
      permissionsPerCompaniesHandler.permissionsInCompanyHandler.permissionsInCompanies,
      selectedInternalUserPermissionsHandler.selectedPermissions,
    );

    userCreationMutation.mutate(creationRequestBody);
  };

  return {
    onCreateButtonClick,
  };
};
