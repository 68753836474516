import { Namespace } from '@config/i18n';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { sizeStyles, PersonalInformationContainer, PersonalInformationFormRow } from './styles';
import { colors, ContentText, fontSizes, IconProfileOutline, Select } from '@fortum/elemental-ui-fork';
import { HorizontalRadioButtonGroup, SectionHeader, InputField } from '@components/index';
import { UserDetailsFormContext } from './UserDetailsFormContext';

export type FormDisableVariant = 'none' | 'email' | 'all';

interface PersonalInformationFormProps {
  disableVariant: FormDisableVariant;
}

export const PersonalInformationForm: FC<PersonalInformationFormProps> = ({ disableVariant }) => {
  const { t } = useTranslation<Namespace[]>(['usersManagement', 'common']);
  const { personalInformationFormHandler } = useContext(UserDetailsFormContext);

  const {
    personalInformation,
    accountLanguageItems,
    userTypeRadioButtonItems,
    setEmail,
    setFirstName,
    setLastName,
    setLanguage,
    setUserType,
    setPhone,
    missingRequiredFields,
    incorrectFormatFields,
  } = personalInformationFormHandler;

  const allFieldsDisabled = disableVariant === 'all';

  return (
    <PersonalInformationContainer>
      <ContentText color={colors.inkGrey} fontSize={fontSizes.s}>
        {t('common:mandatoryFieldInfo')}
      </ContentText>
      <SectionHeader icon={<IconProfileOutline />} text={t('usersManagement:creationForm.personalInformation.header')} size="s" />
      <PersonalInformationFormRow>
        <InputField
          data-testid="user-first-name-input-field"
          label={t('usersManagement:creationForm.personalInformation.name.label')}
          placeholder={t('common:typeHere')}
          name="user-first-name"
          value={personalInformation.name}
          onChange={setFirstName}
          required
          disabled={allFieldsDisabled}
          errorMessage={t('errors:form.fieldRequired')}
          showError={missingRequiredFields.includes('name')}
          {...sizeStyles}
        />

        <InputField
          data-testid="user-last-name-input-field"
          name="user-last-name"
          label={t('usersManagement:creationForm.personalInformation.lastName.label')}
          placeholder={t('common:typeHere')}
          value={personalInformation.lastName}
          onChange={setLastName}
          required
          disabled={allFieldsDisabled}
          errorMessage={t('errors:form.fieldRequired')}
          showError={missingRequiredFields.includes('lastName')}
          {...sizeStyles}
        />
      </PersonalInformationFormRow>

      <InputField
        data-testid="user-email-input-field"
        label={t('usersManagement:creationForm.personalInformation.email.label')}
        name="user-email"
        placeholder={t('common:typeHere')}
        value={personalInformation.email}
        disabled={allFieldsDisabled || disableVariant === 'email'}
        onChange={setEmail}
        required
        type="email"
        errorMessage={
          missingRequiredFields.includes('email') ? t('errors:form.fieldRequired') : t('errors:form.formatting.email')
        }
        showError={missingRequiredFields.includes('email') || incorrectFormatFields.includes('email')}
        {...sizeStyles}
      />

      <PersonalInformationFormRow>
        <Select
          data-testid="user-language-select"
          name="user-language"
          label={t('usersManagement:creationForm.personalInformation.language.label')}
          items={accountLanguageItems}
          onChange={setLanguage}
          selected={personalInformation.language}
          required
          disabled={allFieldsDisabled}
          errorMessage={t('errors:form.fieldRequired')}
          error={missingRequiredFields.includes('language')}
          {...sizeStyles}
        />

        <InputField
          data-testid="user-phone-input-field"
          label={t('usersManagement:creationForm.personalInformation.phone.label')}
          name="user-phone"
          placeholder={t('common:typeHere')}
          value={personalInformation.phone}
          onChange={setPhone}
          disabled={allFieldsDisabled}
          errorMessage={t('errors:form.formatting.phone')}
          showError={incorrectFormatFields.includes('phone')}
          type="phone"
          {...sizeStyles}
        />
      </PersonalInformationFormRow>

      <HorizontalRadioButtonGroup
        disabled={allFieldsDisabled}
        data-testid="user-type-radio-button-group"
        label={`${t('usersManagement:usersList.userType.header')}:`}
        items={userTypeRadioButtonItems}
        value={personalInformation.userType}
        onChange={setUserType}
      />
    </PersonalInformationContainer>
  );
};
