import styled from 'styled-components';
import { Box, colors } from '@fortum/elemental-ui-fork';
import { verticalContainer } from '@components/styles';

export const Container = styled(Box)`
  ${verticalContainer};
  flex: 1;
  background-color: ${colors.lightGrey};
  position: relative;
`;
