import { HorizontalContainer, horizontalContainer } from '@components/styles';
import { spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const RadioButtonsContainer = styled.div`
  ${horizontalContainer};

  gap: 48px;
`;

export const RadioButtonWithTooltipContainer = styled(HorizontalContainer)`
  gap: ${spacing.xxxxs};
  align-items: start;
`;
