import { Placeholder, PlaceholderProps } from '@fortum/elemental-ui-fork';
import { fill } from 'lodash';
import { FC } from 'react';

interface PlaceholdersProps {
  lines: number;
}

const placeholderStyles: PlaceholderProps = {
  height: '24px',
  width: '90%',
  marginVertical: '8px',
};

export const Placeholders: FC<PlaceholdersProps> = ({ lines }) => (
  <>
    {fill(Array(lines), '').map((_empty, index) => (
      <Placeholder key={index} {...placeholderStyles} />
    ))}
  </>
);
