import { HorizontalContainer } from '@components/styles';
import {
  ButtonProps,
  ContentTextProps,
  fontSizes,
  fontWeights,
  IconProps,
  PlaceholderProps,
  spacing,
} from '@fortum/elemental-ui-fork';
import styled from 'styled-components';
import { ngColors } from '@utils/styleOverride';

export const SectionHeaderContainer = styled(HorizontalContainer)`
  gap: ${spacing.xxxs};
  align-items: center;
`;

export const sectionIconStyles: IconProps = {
  color: ngColors.orange,
  size: 24,
};

const headerTextBasicStyles: ContentTextProps = {
  fontWeight: fontWeights.medium,
};

export const smallHeaderTextStyles: ContentTextProps = {
  ...headerTextBasicStyles,
  fontSize: fontSizes.m,
};

export const mediumHeaderTextStyles: ContentTextProps = {
  ...headerTextBasicStyles,
  fontSize: fontSizes.l,
};

const headerPlaceholderStyles: PlaceholderProps = {
  flex: 0.91,
};

export const smallHeaderPlaceholderStyles: PlaceholderProps = {
  ...headerPlaceholderStyles,
  height: '27px',
};

export const mediumHeaderPlaceholderStyles: PlaceholderProps = {
  ...headerPlaceholderStyles,
  height: '30px',
};

export const buttonStyles: ButtonProps = {
  status: 'secondary',
  variant: 'condensed',
  ml: 'auto',
};
