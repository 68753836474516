import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { Button, IconTrash, useToggle } from '@fortum/elemental-ui-fork';
import { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SimplifiedEvent } from 'src/types/events';
import { FormContainer, buttonStyles, inputFieldStyles } from './styles';
import { InputField } from '@components/InputField';
import { useDeleteTemporaryOrderMutation } from '@data/hooks/useDeleteTemporaryOrderMutation';
import { OrdersManagementTileProps } from './types';
import { ConfirmationModal } from '@components/index';

export const DeleteTemporaryOrderTile: FC<OrdersManagementTileProps> = ({
  setDisplaySuccessfulNotification,
  setDisplayErrorNotification,
  setNotificationErrorMessage,
  setNotificationSuccessMessage,
}) => {
  const { t } = useTranslation<Namespace[]>(['orderCreation', 'errors', 'orderDetails', 'common']);

  const [temporaryOrderNo, setTemporaryOrderNo] = useState<string>('');

  const resetForm = useCallback(() => {
    setTemporaryOrderNo('');
  }, []);

  const [displayValidationError, setDisplayValidationError] = useState(false);

  const [modalOpen, toggleModalOpen] = useToggle(false);

  const temporaryOrderDeletionMutation = useDeleteTemporaryOrderMutation({
    onSuccess: () => {
      resetForm();
      setNotificationSuccessMessage(t('orderCreation:ordersManagement.successfulDeletion'));
      setDisplaySuccessfulNotification();
    },
    onError: error => {
      setNotificationErrorMessage(error.getStatusText() || t('errors:ordersManagement.orderDeletion'));
      setDisplayErrorNotification();
    },
  });

  const handleTemporaryOrderNoChange = useCallback((ev: SimplifiedEvent<string>) => setTemporaryOrderNo(ev.target.value), []);

  const onButtonClick = useCallback(() => {
    if (!temporaryOrderNo) {
      setDisplayValidationError(true);
      return;
    }

    toggleModalOpen();
  }, [temporaryOrderNo]);

  const deleteOrder = () => {
    toggleModalOpen();
    temporaryOrderDeletionMutation.mutate(temporaryOrderNo);
  };

  return (
    <>
      <Tile header={t('orderCreation:ordersManagement.orderDeletion.header')} data-testid="order-deletion-tile">
        <FormContainer>
          <InputField
            {...inputFieldStyles}
            required
            label={t('orderCreation:ordersManagement.temporaryOrderNo.label')}
            name="deletion-temporary-order-no"
            value={temporaryOrderNo}
            onChange={handleTemporaryOrderNoChange}
            errorMessage={t('errors:form.fieldRequired')}
            showError={displayValidationError && !temporaryOrderNo}
          />

          <Button
            data-testid="delete-order-button"
            leftIcon={<IconTrash />}
            {...buttonStyles}
            onClick={onButtonClick}
            disabled={temporaryOrderDeletionMutation.isPending}
          >
            {t('common:delete')}
          </Button>
        </FormContainer>
      </Tile>

      <ConfirmationModal
        headerText={t('common:delete')}
        messageText={t('orderCreation:ordersManagement.orderDeletion.confirmationModal.message')}
        closingButtonText={t('common:refuseDelete')}
        confirmingButtonText={t('common:confirmDelete')}
        opened={modalOpen}
        onClose={toggleModalOpen}
        onConfirmClick={deleteOrder}
      />
    </>
  );
};
