import styled from 'styled-components';
import { ContentText, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui-fork';
import { MenuItem } from '../menu';

export const StyledContentText = styled(ContentText)`
  word-break: break-word;
  font-weight: normal;
  font-size: 1rem;
`;

export const StyledContentTextBold = styled(StyledContentText)`
  font-weight: ${fontWeights.bold};
  font-size: 1.125rem;
`;

export const StyledSpan = styled.span`
  font-weight: ${fontWeights.regular};
  padding-left: ${spacing.xxxxs};
`;
export const StyledAnchor = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const MenuItemWithPaddingTop = styled(MenuItem)`
  padding-top: ${spacing.xxs};
`;
