import { createContext, Dispatch, SetStateAction } from 'react';
import { BusinessPartner } from '@models/user';

type BusinessPartnersContextProps = {
  userBps: BusinessPartner[];
  selectedBps: BusinessPartner[];
  setSelectedBps: Dispatch<SetStateAction<BusinessPartner[]>>;
};

export const BusinessPartnersContext = createContext<BusinessPartnersContextProps>({
  userBps: [],
  selectedBps: [],
  setSelectedBps: () => undefined,
});
