import { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react';
import { PermissionsSection } from '@components/index';
import { IconInspection } from '@fortum/elemental-ui-fork';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { getUserPermissionsPerUserRole, isUserPermission } from '@utils/user';
import { UserDetailsFormContext } from './UserDetailsFormContext';
import { PermissionsFormWithPaddingBottom, VerticalContainerWithBigGap } from './styles';
import { Divider } from '@components/styles';

interface InternalUserPermissionsFormProps {
  disabled?: boolean;
}

export const InternalUserPermissionsForm: FC<InternalUserPermissionsFormProps> = ({ disabled = false }) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  const { usersPermissionsQueryResult, selectedInternalUserPermissionsHandler } = useContext(UserDetailsFormContext);

  const { defaultPermissions, additionalPermissions } = useMemo(
    () => getUserPermissionsPerUserRole('INTERNAL', usersPermissionsQueryResult.data),
    [usersPermissionsQueryResult.data],
  );

  const onCheckboxClick = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const permission = e.target.name;

      if (isUserPermission(permission)) {
        selectedInternalUserPermissionsHandler.setSelectedPermissions(prev => {
          if (prev.includes(permission)) {
            return prev.filter(perm => perm !== permission);
          }
          return [...prev, permission];
        });
      }
    },
    [selectedInternalUserPermissionsHandler.setSelectedPermissions],
  );

  return (
    <VerticalContainerWithBigGap data-testid="internal-user-creation">
      <PermissionsSection
        headerIcon={<IconInspection />}
        headerText={t('detailsPanel.defaultPermissions.header')}
        permissions={defaultPermissions}
        disabledPermissions={[]}
        isLoading={usersPermissionsQueryResult.isLoading}
      />

      <Divider />

      <PermissionsFormWithPaddingBottom
        isLoading={usersPermissionsQueryResult.isLoading}
        disabled={disabled}
        permissions={additionalPermissions}
        selectedPermissions={selectedInternalUserPermissionsHandler.selectedPermissions}
        onCheckboxClick={onCheckboxClick}
      />
    </VerticalContainerWithBigGap>
  );
};
