import { staticBorderStyles, horizontalContainer, CONTAINER_BORDER_RADIUS } from '@components/styles';
import { ContentText, Grid, Row, colors, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const Container = styled.div`
  ${horizontalContainer};

  ${staticBorderStyles()};
  text-align: left;
`;

export const FullWidthGrid = styled(Grid)`
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
`;

export const RowWithoutMargins = styled(Row)`
  margin: 0;
  width: 100%;
`;

export const HeaderRow = styled(RowWithoutMargins)`
  padding: ${spacing.xxxs} ${spacing.xs};
  background-color: ${colors.lightGrey};
  border-radius: ${CONTAINER_BORDER_RADIUS};
`;

export const ValuesRow = styled(RowWithoutMargins)`
  padding: ${spacing.xxs} ${spacing.xs};
`;

export const WordBreakingContentText = styled(ContentText)`
  word-wrap: break-word;
`;
