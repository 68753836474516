import { Contact } from '@models/contact';
import { Contract } from '@models/contract';
import { OrderType } from '@models/orders';
import { UseQueryResult } from '@tanstack/react-query';
import { RefObject, createContext } from 'react';
import { CreationForm } from 'src/types/ordersCreation';

import { useSingleOrderCreationForm } from './useSingleOrderCreationForm';

type UseSingleOrderCreationFormReturnValue = ReturnType<typeof useSingleOrderCreationForm>;

export type SingleOrderCreationContextProps = Omit<UseSingleOrderCreationFormReturnValue, 'restoreValues'> & {
  availableOrderTypes: OrderType[];
  contract: Contract;
  form: CreationForm['forms'][number];
  contactsDataHandler: UseQueryResult<Contact[], Error>;
  contractIdentifier: string;
  orderTileRef: RefObject<HTMLDivElement>;
};

export const SingleOrderCreationContext = createContext<SingleOrderCreationContextProps>({
  form: {} as CreationForm['forms'][number],
  missingRequiredFields: [],
  availableOrderTypes: [],
  contract: {} as Contract,
  contactsDataHandler: {} as UseQueryResult<Contact[], Error>,
  contractIdentifier: '',
  setComments: () => undefined,
  setEquipmentQuantity: () => undefined,
  setEstimatedWasteQuantity: () => undefined,
  setLocationPointAdditionalInfo: () => undefined,
  setOrderLevelReference: () => undefined,
  setSelectedOrderType: () => undefined,
  setSpecifiedWasteDescription: () => undefined,
  setContactPerson: () => undefined,
  validate: () => true,
  isFieldRequired: () => true,
  orderTileRef: { current: null } as RefObject<HTMLDivElement>,
});
