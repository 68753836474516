import { Box, BoxProps, ContentText, fontSizes } from '@fortum/elemental-ui-fork';
import { GeneralInfo } from '@models/contract';
import { hasValue } from '@utils/dataOperations';
import { splitStreetAddressOnTheZipCode } from '@utils/dataOperations/address';
import { compact } from 'lodash';
import { FC } from 'react';

interface SiteAddressProps {
  location: GeneralInfo['location'];
  wrap?: boolean;
}

const verticalContainerProps: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const SiteAddress: FC<SiteAddressProps> = ({ wrap = false, location }) => {
  const addressElements = compact([
    hasValue(location.name) ? location.name : undefined,
    ...splitStreetAddressOnTheZipCode(location.address),
    hasValue(location.exact) ? location.exact : undefined,
  ]);

  if (!wrap) {
    return addressElements.join(', ');
  }
  return (
    <Box {...verticalContainerProps}>
      {addressElements.map((element, index) => (
        <ContentText key={index} fontSize={fontSizes.s}>
          {element}
        </ContentText>
      ))}
    </Box>
  );
};
