import { queryClient } from '@config/queryClient';
import {
  ORDERS_CREATION_ROUTE_PATH,
  ORDERS_CREATION_SUMMARY_ROUTE_PATH,
  ORDERS_TRACKING_ROUTE_PATH,
  paths,
  USER_CREATION_ROUTE_PATH,
} from '@config/routes';
import { ContractsFilterStateProvider } from '@contexts/ContractsFiltersProvider';
import { RestrictedRoute } from '@routes/RestrictedRoute';
import Authorization from '@routes/auth';
import ContactUs from '@routes/contact/contact';
import { SelectCustomer } from '@routes/customer';
import { GeneralError } from '@routes/errors/general/general-error';
import PageNotFound from '@routes/errors/not-found/pageNotFound';
import { Home } from '@routes/home';
import { OrderDetails } from '@routes/orderDetails';
import { OrdersFiltersContextProvider } from '@routes/orders/components';
import OrdersOverview from '@routes/orders/orders';
import { OrdersCreationLayout } from '@routes/ordersCreation';
import { OrdersCreationContextProvider } from '@routes/ordersCreation/components';
import { OrdersCreation } from '@routes/ordersCreation/creationPage';
import { SummaryPage } from '@routes/ordersCreation/summaryPage';
import { OrdersManagement } from '@routes/ordersManagement';
import ReportsOverview, { ErrorElement as ReportsErrorElement } from '@routes/reports';
import { Co2Report } from '@routes/reports/co2';
import { CostReport } from '@routes/reports/cost';
import DashboardReport from '@routes/reports/dashboard';
import { WasteQuantityReport } from '@routes/reports/wasteQty';
import ServiceDetails from '@routes/serviceDetails';
import TermsAndConditions from '@routes/terms';
import UserProfile from '@routes/user';
import { UsersManagement } from '@routes/usersManagement';
import {
  isCo2ReportToggleOn,
  isCostReportToggleOn,
  isDashboardReportToggleOn,
  isOrdersCreationToggleOn,
  isOrdersManagementToggleOn,
  isUsersManagementToggleOn,
  isWasteReportToggleOn,
  hasAtLeastOneReportPermission,
  isUserCreationToggleOn,
} from '@utils/featureToggles';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import './config/i18n/i18n';
import { ErrorPropagator } from './ErrorPropagator';
import Root from './routes';
import { loader as rootLoader } from './routes/loader';
import ServicesOverview from './routes/services';
import { UserCreation } from '@routes/userCreation';
import { NotificationContextProvider } from '@contexts/notification';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={paths.main} element={<Root />} loader={() => rootLoader(queryClient)} errorElement={<ErrorPropagator />}>
        <Route index element={<Home />}></Route>
        <Route path={paths.services} errorElement={<GeneralError />}>
          <Route
            path={paths.services}
            element={
              <ContractsFilterStateProvider>
                <ServicesOverview />
              </ContractsFilterStateProvider>
            }
          />
          <Route
            path={':contractIdentifier'}
            element={
              <ContractsFilterStateProvider>
                <ServiceDetails />
              </ContractsFilterStateProvider>
            }
          />
        </Route>

        <Route
          path={paths.reports}
          element={
            <RestrictedRoute validateAccess={hasAtLeastOneReportPermission}>
              <ReportsOverview />
            </RestrictedRoute>
          }
          errorElement={<ReportsErrorElement />}
        >
          <Route
            path={paths.dashboardReport}
            element={
              <RestrictedRoute validateAccess={isDashboardReportToggleOn}>
                <DashboardReport />
              </RestrictedRoute>
            }
          />
          <Route
            path={paths.costDetailsReport}
            element={
              <RestrictedRoute validateAccess={isCostReportToggleOn}>
                <CostReport />
              </RestrictedRoute>
            }
          />
          <Route
            path={paths.wasteDetailsReport}
            element={
              <RestrictedRoute validateAccess={isWasteReportToggleOn}>
                <WasteQuantityReport />
              </RestrictedRoute>
            }
          />
        </Route>

        <Route
          path={paths.co2Report}
          element={
            <RestrictedRoute validateAccess={isCo2ReportToggleOn}>
              <ReportsOverview />
            </RestrictedRoute>
          }
          errorElement={<ReportsErrorElement />}
        >
          <Route index element={<Co2Report />} />
        </Route>

        <Route path={paths.contactUs} element={<ContactUs />} errorElement={<GeneralError />} />

        <Route path={paths.orders} errorElement={<GeneralError />}>
          <Route
            path={ORDERS_TRACKING_ROUTE_PATH}
            element={
              <OrdersFiltersContextProvider>
                <OrdersOverview />
              </OrdersFiltersContextProvider>
            }
          />

          <Route
            path={ORDERS_CREATION_ROUTE_PATH}
            element={
              <RestrictedRoute validateAccess={isOrdersCreationToggleOn}>
                <OrdersCreationContextProvider>
                  <OrdersCreationLayout />
                </OrdersCreationContextProvider>
              </RestrictedRoute>
            }
          >
            <Route index element={<OrdersCreation />} />
            <Route path={ORDERS_CREATION_SUMMARY_ROUTE_PATH} element={<SummaryPage />} />
          </Route>

          <Route
            path={`${ORDERS_TRACKING_ROUTE_PATH}/:orderNo`}
            element={
              <OrdersFiltersContextProvider>
                <OrderDetails />
              </OrdersFiltersContextProvider>
            }
          />
        </Route>

        <Route
          path={paths.ordersManagement}
          element={
            <RestrictedRoute validateAccess={isOrdersManagementToggleOn}>
              <OrdersManagement />
            </RestrictedRoute>
          }
        />
        <Route path={paths.userProfile} element={<UserProfile />} errorElement={<GeneralError />} />

        <Route path={paths.usersManagement}>
          <Route
            index
            element={
              <RestrictedRoute validateAccess={isUsersManagementToggleOn}>
                <NotificationContextProvider>
                  <UsersManagement />
                </NotificationContextProvider>
              </RestrictedRoute>
            }
          />
          <Route
            path={USER_CREATION_ROUTE_PATH}
            element={
              <RestrictedRoute validateAccess={user => isUsersManagementToggleOn(user) && isUserCreationToggleOn()}>
                <NotificationContextProvider>
                  <UserCreation />
                </NotificationContextProvider>
              </RestrictedRoute>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
        <Route path={paths.selectCustomer} errorElement={<GeneralError />} element={<SelectCustomer />} />
      </Route>
      <Route path={paths.termsAndConditions} element={<TermsAndConditions />} errorElement={<ErrorPropagator />} />
      <Route path={paths.auth} element={<Authorization />} errorElement={<ErrorPropagator />} />
    </>,
  ),
);
