import {
  ButtonProps,
  ContentTextProps,
  IconProps,
  LinkProps,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const ContainerBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${spacing.xxl};
  gap: ${spacing.xxs};
  background-color: ${colors.snowWhite};
  @media only screen and ${`(max-width: ${breakpoints.s}${'px'})`} {
    flex-direction: column;
    align-items: center;
  }
`;

export const buttonStyles: ButtonProps = {
  width: '173px',
  size: 'm',
};

export const secondaryButtonStyles: ButtonProps = {
  ...buttonStyles,
  status: 'secondary',
};

export const linkStyles: LinkProps = {
  color: colors.inkGrey,
  fontSize: fontSizes.m,
  pl: spacing.xxxs,
  pr: spacing.xxxs,
};

export const titleStyles: ContentTextProps = {
  mb: spacing.xxs,
  color: colors.inkGrey,
  fontSize: '32px',
  fontWeight: fontWeights.regular,
};

export const textStyles: ContentTextProps = {
  color: colors.inkGrey,
  fontSize: fontSizes.m,
  fontWeight: fontWeights.regular,
  textAlign: 'center',
};

export const iconStyles: IconProps = {
  pb: spacing.xxs,
};
