import { Contract } from '@models/contract';
import { FC } from 'react';
import { Box, Col, Row } from '@fortum/elemental-ui-fork';
import { GRID_GAP } from '@routes/serviceDetails/style';
import { EquipmentDetailsTile, TransportDetailsTile, InvoicingDetailsTile } from '.';
import { CompanySiteDetailsTile } from '@components/CompanySiteDetailsTile';

interface ServiceDetailsTilesProps {
  contract: Contract;
  isEquipmentSales: boolean;
}

export const ServiceDetailsTiles: FC<ServiceDetailsTilesProps> = ({ contract, isEquipmentSales }) => {
  return (
    <Row>
      <Col xl={6} mb={{ xs: GRID_GAP, xl: 0 }}>
        <Box display="flex" flexDirection="column" gap={GRID_GAP}>
          <CompanySiteDetailsTile
            customer={contract.generalInfo.customer}
            location={contract.generalInfo.location}
            wrapSiteAddress={true}
          />

          <TransportDetailsTile
            isEquipmentSales={isEquipmentSales}
            transportDetails={contract.serviceDetails.transportDetails}
            contactDetails={contract.contractDetails.contactDetails}
          />
        </Box>
      </Col>

      <Col xl={6}>
        <Box display="flex" flexDirection="column" gap={GRID_GAP}>
          <EquipmentDetailsTile equipmentDetails={contract.serviceDetails.equipmentDetails} />

          <InvoicingDetailsTile invoicingDetails={contract.contractDetails.invoicingDetails} />
        </Box>
      </Col>
    </Row>
  );
};
