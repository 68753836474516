import { Box, BoxProps, ContentText, ContentTextProps, fontSizes } from '@fortum/elemental-ui-fork';
import { Code } from '@models/common';
import { hasValue } from '@utils/dataOperations';
import { displayedValue } from '@utils/dataOperations';
import { FC } from 'react';

interface WasteEwcCodeProps {
  ewcCode: Code;
}

const contentTextProps: ContentTextProps = {
  fontSize: fontSizes.s,
};

const containerProps: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const WasteEwcCode: FC<WasteEwcCodeProps> = ({ ewcCode }) => (
  <Box {...containerProps}>
    <ContentText {...contentTextProps} data-testid="ewc-code-entry">
      {displayedValue(ewcCode.code)}
    </ContentText>

    {hasValue(ewcCode.description) && (
      <ContentText {...contentTextProps} data-testid="ewc-description-entry">
        {ewcCode.description}
      </ContentText>
    )}
  </Box>
);
