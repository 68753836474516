import { EmptyResponseView, ErrorView } from '@components/layout';
import { Box, spacing } from '@fortum/elemental-ui-fork';
import styled from 'styled-components';

export const mapHeight = '432px';
export const Container = styled(Box)`
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  border-radius: ${spacing.xxxxs};
  box-shadow: 0px 0px 27px 8px rgba(0, 0, 0, 0.1);
`;

export const StyledErrorView = styled(ErrorView)`
  flex: unset;
  margin: auto;
`;

export const StyledEmptyResponseView = styled(EmptyResponseView)`
  flex: unset;
  margin: auto;
`;
